import React from 'react';
import { string, func, bool } from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import mixpanel from 'mixpanel-browser';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
// import { ensureListing, ensureUser } from '../../util/data';
// import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';

const { Money } = sdkTypes;
const MIN_LENGTH_FOR_LONG_WORDS = 10;

export const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    isCompany,
    currentUser,
    hit: listing,
    renderSizes,
    hasImgRadius,
    listingConfig,
    // showAuthorInfo,
    // setActiveListing,
  } = props;

  const classes = classNames(rootClassName || css.root, className, hasImgRadius && css.companycard);
  const {
    user,
    title,
    price,
    skills,
    objectID,
    addSelfie1,
    experiences,
    uploadVideoInPhotoes,
    roles,
    // location,
    // _geoloc,
    // description,
    // educations,
    listingType,
    // higherEducation,
    // availabilityPlan,
  } = listing || {};
  let companyimage = (listing && listing.user && listing.user.companyImage) || '';
  let minRateValue = Infinity;
  let maxRateValue = -Infinity;

  if (roles && roles.length && listingType === 'company') {
    roles.forEach(role => {
      if (role.perHourRate && role.perHourRate.label) {
        let rateLabel = role.perHourRate.label;
        let rates = rateLabel.match(/\d+/g).map(Number); // Extract numerical values

        rates.forEach(rate => {
          if (rate < minRateValue) {
            minRateValue = rate;
          }
          if (rate > maxRateValue) {
            maxRateValue = rate;
          }
        });
      }
    });
  }

  let minRateLabel = `CA$${minRateValue}`;
  let maxRateLabel = `$${maxRateValue}`;

  const {
    mainProfession,
    availableHoursPerWeek,
  } = user || {};
  const id = objectID;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
  } = config.layout.listingImage;
  function getInitials(name) {
    return name.split(' ').map(word => word.charAt(0).toUpperCase()).join('');
  }

  const initials = getInitials(title);
  const slug = createSlug(initials || "");

  const { formattedPrice, priceTitle } = price ? priceData(new Money(price.amount, price.currency), config.currency, intl) : {};
  const yearOfExperience = experiences && experiences.length && experiences[0].inputValues
    ? moment().diff(moment(parseInt(experiences[0].inputValues.yearOfExperience.key), 'YYYY'), 'year')
    : null;
  return (id && slug ?
    <div className={classes} onClick={() => {
      mixpanel.track((isCompany ? 'JobPage' : 'ProPage'), {
        distinct_id: currentUser?.id?.uuid,
        user_id: currentUser?.id?.uuid,
        userType: isCompany ? 'company' : 'fractionalPro',
        environment: (process.env && process.env.REACT_APP_ENV) || 'development',
      });
    }}>
      <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
        <div className={css.listingCardWrapper}>
          <div className={css.listingBox}>
            <AspectRatioWrapper
              className={css.aspectRatioWrapper}
              width={aspectWidth}
              height={aspectHeight}
            >
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                url={!isCompany ? companyimage ? companyimage : null : uploadVideoInPhotoes ? (uploadVideoInPhotoes.replace(/\.(mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg)$/i, '.png')) : addSelfie1}
                sizes={renderSizes}
              />
            </AspectRatioWrapper>
            <div className={css.cardData}>
              {initials
                ? <div className={css.mainTitle}>{initials.split(' ')[0]  }
                </div>
                : null}
                {experiences && experiences.length
                ? <h5>
                  {experiences[0].label}
                </h5>
                : null}
              
              {listingConfig && listingConfig.companyIndustry
                ? <div className={css.designation}>{listingConfig.companyIndustry.find(ind => ind.option === mainProfession)?.label}</div>
                : null}
              <div className={css.listingList}>
                {yearOfExperience ? <div className={css.listItem}>{yearOfExperience}{yearOfExperience > 1 ? ' years' : ' year'} of experience</div> : null}
                {availableHoursPerWeek ? <div className={css.listItem}>{availableHoursPerWeek}h per week available</div> : null}
                <div className={css.listItem}>{moment().tz(moment.tz.guess()).format('z')} Timezone</div>
              </div>
            </div>
          </div>
          {/* {skills && skills.length
            ? <div className={css.listingBottomBox}>
              {skills.map(({ key, label }) => (
                <span key={key}>
                  {label}
                </span>))}
            </div>
            : null} */}
        </div>
        <div className={css.info}>
          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              {(listingType && listingType == 'company') ? `${minRateLabel}-${maxRateLabel} ` : `${formattedPrice} `}
              {/* {formattedPrice} */}
              per hour
            </div>
          </div>
          {/* <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div> */}
        </div>
      </NamedLink>
    </div>
    : null
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  // listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
