import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Slider from 'react-slick';
import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import { TalentCard } from '../../../../components';
import SectionContainer from '../SectionContainer';
import css from './SectionArticle.module.css';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (<div
    className={className}
    style={{ ...style, display: "block" }}
    onClick={onClick}
  >
    <svg width="21" height="39" viewBox="0 0 21 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 36.7707L19 19.8553L2 2.93994" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  </div>);
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (<div
    className={className}
    style={{ ...style, display: "block" }}
    onClick={onClick}
  >
    <svg style={{ transform: 'rotate(180deg)' }} width="21" height="39" viewBox="0 0 21 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 36.7707L19 19.8553L2 2.93994" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>


  </div >);
}


// Section component that's able to show article content
// The article content is mainly supposed to be inside a block
const SectionArticle = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
  } = props;
  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;
  const {accountType='company'}= (options && options.currentUser && options.currentUser.attributes && options.currentUser.attributes.profile  && options.currentUser.attributes.profile.publicData) || {}
   
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrow: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: false,
    infinite: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const approvedListing = (( options && options.listings && options.listings.length) ? options.listings.filter((val) => {
    return val.attributes.publicData.approvedByAdmin

  }) : [])

  return ((sectionId != "daily-talent-spotlight" || (sectionId == "daily-talent-spotlight" && options.listings && options.listings.length))
    ? <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
      {sectionId == "daily-talent-spotlight" ? <h1 className={css.spotlight}>{accountType == 'company' ? 'Daily Talent Spotlight'  : 'Daily Role Spotlight'} </h1> : null}
      {sectionId == "daily-talent-spotlight" && options.listings && options.listings.length
        ? <div className={css.talentSlider}>
          <Slider {...settings}>
            {approvedListing.map((listing, i) => (
              <TalentCard
                key={'listing_' + i}
                listing={listing}
                listingConfig={options.listingConfig}
              />
            ))}
          </Slider>
        </div>
        : null}
      {hasBlocks ? (
        <div
          className={classNames(defaultClasses.blockContainer, css.articleMain, {
            [css.noSidePaddings]: isInsideContainer,
          })}
        >
          <BlockBuilder
            blocks={blocks}
            ctaButtonClass={defaultClasses.ctaButton}
            options={options}
          />
        </div>
      ) : null}
    </SectionContainer>
    : null
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionArticle.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionArticle.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionArticle;
