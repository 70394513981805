/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */

import moment from "moment";

export const favouriteToolsData = [
  { key: 'crm', label: "CRM", value: "CRM" },
  { key: 'analytics', label: "Analytics", value: "Analytics" },
  { key: 'e-commerce', label: "e-Commerce", value: "e-Commerce" },
  { key: 'design', label: "Design", value: "Design" },
  { key: 'software', label: "Software", value: "Software" },
  { key: 'project-management', label: "Project Management", value: "Project Management" },
  { key: 'quality-assurance', label: "Quality Assurance", value: "Quality Assurance" },
  { key: 'software-design', label: "Software Design", value: "Software Design" },
  { key: 'accounting', label: "Accounting", value: "Accounting" },
  { key: 'marketing', label: "Marketing", value: "Marketing" },
  { key: 'sales', label: "Sales", value: "Sales" },
  { key: 'business-intelligence', label: "Business Intelligence", value: "Business Intelligence" },
  { key: 'content-management-system', label: "Content Management System", value: "Content Management System" },
  { key: 'customer-service-support', label: "Customer Service / Support", value: "Customer Service / Support" },
];

export const skillsTools = [
  { key: "hubspot", parentId: "CRM", value: "HubSpot", label: "HubSpot" },
  { key: "pipedrive", parentId: "CRM", value: "Pipedrive", label: "Pipedrive" },
  { key: "less-annoying-(?)", parentId: "CRM", value: "Less Annoying (?)", label: "Less Annoying (?)" },
  { key: "salesforce", parentId: "CRM", value: "Salesforce", label: "Salesforce" },
  { key: "momentive", parentId: "CRM", value: "Momentive", label: "Momentive" },
  { key: "clickup", parentId: "CRM", value: "ClickUp", label: "ClickUp" },
  { key: "monday", parentId: "CRM", value: "Monday", label: "Monday" },
  { key: "activecampaign", parentId: "CRM", value: "ActiveCampaign", label: "ActiveCampaign" },
  { key: "close", parentId: "CRM", value: "Close", label: "Close" },
  { key: "nextiva", parentId: "CRM", value: "Nextiva", label: "Nextiva" },
  { key: "sap", parentId: "CRM", value: "SAP", label: "SAP" },
  { key: "copper", parentId: "CRM", value: "Copper", label: "Copper" },
  { key: "zoho", parentId: "CRM", value: "Zoho", label: "Zoho" },
  { key: "zendesk", parentId: "CRM", value: "Zendesk", label: "Zendesk" },
  { key: "leadsquared", parentId: "CRM", value: "LeadSquared", label: "LeadSquared" },
  { key: "thryv", parentId: "CRM", value: "Thryv", label: "Thryv" },
  { key: "netsuite", parentId: "CRM", value: "NetSuite", label: "NetSuite" },
  { key: "freshsales", parentId: "CRM", value: "Freshsales", label: "Freshsales" },
  { key: "keap", parentId: "CRM", value: "Keap", label: "Keap" },
  { key: "salesflare", parentId: "CRM", value: "Salesflare", label: "Salesflare" },
  { key: "insightly", parentId: "CRM", value: "Insightly", label: "Insightly" },
  { key: "outlook", parentId: "CRM", value: "Outlook", label: "Outlook" },
  { key: "kylas", parentId: "CRM", value: "Kylas", label: "Kylas" },
  { key: "nutshell", parentId: "CRM", value: "Nutshell", label: "Nutshell" },
  { key: "constant-contact", parentId: "CRM", value: "Constant Contact", label: "Constant Contact" },
  { key: "vendasta", parentId: "CRM", value: "Vendasta", label: "Vendasta" },
  { key: "edrone", parentId: "CRM", value: "edrone", label: "edrone" },
  { key: "wrike", parentId: "CRM", value: "Wrike", label: "Wrike" },
  { key: "pipeline", parentId: "CRM", value: "Pipeline", label: "Pipeline" },
  { key: "google-analytics", parentId: "Analytics", value: "Google Analytics", label: "Google Analytics" },
  { key: "google-tag-manager", parentId: "Analytics", value: "Google Tag Manager", label: "Google Tag Manager" },
  { key: "google-seo-tools", parentId: "Analytics", value: "Google SEO Tools", label: "Google SEO Tools" },
  { key: "firebase", parentId: "Analytics", value: "Firebase", label: "Firebase" },
  { key: "mixpanel", parentId: "Analytics", value: "Mixpanel", label: "Mixpanel" },
  { key: "amplitude", parentId: "Analytics", value: "Amplitude", label: "Amplitude" },
  { key: "pendo", parentId: "Analytics", value: "Pendo", label: "Pendo" },
  { key: "crazy-egg", parentId: "Analytics", value: "Crazy Egg", label: "Crazy Egg" },
  { key: "excel", parentId: "Analytics", value: "Excel", label: "Excel" },
  { key: "microsoft-power-bi", parentId: "Analytics", value: "Microsoft Power BI", label: "Microsoft Power BI" },
  { key: "screaming-frog", parentId: "Analytics", value: "Screaming Frog", label: "Screaming Frog" },

  { key: "shopify", parentId: "e-Commerce", value: "Shopify", label: "Shopify" },
  { key: "big-commerce", parentId: "e-Commerce", value: "Big Commerce", label: "Big Commerce" },
  { key: "magento", parentId: "e-Commerce", value: "Magento", label: "Magento" },
  { key: "amazon", parentId: "e-Commerce", value: "Amazon", label: "Amazon" },
  { key: "squarespace", parentId: "e-Commerce", value: "Squarespace", label: "Squarespace" },
  { key: "nautical", parentId: "e-Commerce", value: "Nautical", label: "Nautical" },
  { key: "photoshop", parentId: "Design", value: "Photoshop", label: "Photoshop" },
  { key: "canva", parentId: "Design", value: "Canva", label: "Canva" },
  { key: "abobe-xd", parentId: "Design", value: "Abobe XD", label: "Abobe XD" },
  { key: "figma", parentId: "Design", value: "Figma", label: "Figma" },
  { key: "github", parentId: "Software", value: "GitHub", label: "GitHub" },
  { key: "gitbucket", parentId: "Software", value: "GitBucket", label: "GitBucket" },
  { key: "aws", parentId: "Software", value: "AWS", label: "AWS" },
  { key: "azure", parentId: "Software", value: "Azure", label: "Azure" },
  { key: "heroku", parentId: "Software", value: "Heroku", label: "Heroku" },
  { key: "cloudinary", parentId: "Software", value: "Cloudinary", label: "Cloudinary" },
  { key: "mongo", parentId: "Software", value: "Mongo", label: "Mongo" },
  { key: "proofhub", parentId: "Software", value: "ProofHub", label: "ProofHub" },
  { key: "dreamweaver-cc", parentId: "Software", value: "Dreamweaver CC", label: "Dreamweaver CC" },
  { key: "leankit", parentId: "Software", value: "LeanKit", label: "LeanKit" },
  { key: "codenvy", parentId: "Software", value: "Codenvy", label: "Codenvy" },
  { key: "crimson-editor", parentId: "Software", value: "Crimson Editor", label: "Crimson Editor" },
  { key: "code-climate", parentId: "Software", value: "Code Climate", label: "Code Climate" },
  { key: "telerik-teampulse", parentId: "Software", value: "Telerik TeamPulse", label: "Telerik TeamPulse" },
  { key: "axosoft", parentId: "Software", value: "Axosoft", label: "Axosoft" },
  { key: "eclipse", parentId: "Software", value: "Eclipse", label: "Eclipse" },
  { key: "ant", parentId: "Software", value: "Ant", label: "Ant" },
  { key: "junit", parentId: "Software", value: "Junit", label: "Junit" },
  { key: "docker", parentId: "Software", value: "Docker", label: "Docker" },
  { key: "kubernetes", parentId: "Software", value: "Kubernetes", label: "Kubernetes" },
  { key: "vs-code", parentId: "Software", value: "VS Code", label: "VS Code" },
  { key: "basecamp", parentId: "Project Management", value: "Basecamp", label: "Basecamp" },
  { key: "trello", parentId: "Project Management", value: "Trello", label: "Trello" },
  { key: "atlassian-/-jira-/-confluence", parentId: "Project Management", value: "Atlassian / Jira / Confluence", label: "Atlassian / Jira / Confluence" },
  { key: "monday", parentId: "Project Management", value: "Monday", label: "Monday" },
  { key: "product-board", parentId: "Project Management", value: "Product Board", label: "Product Board" },
  { key: "asana", parentId: "Project Management", value: "Asana", label: "Asana" },
  { key: "redmine", parentId: "Project Management", value: "Redmine", label: "Redmine" },
  { key: "wrike", parentId: "Project Management", value: "Wrike", label: "Wrike" },
  { key: "case-tools", parentId: "Project Management", value: "CASE Tools", label: "CASE Tools" },
  { key: "excel", parentId: "Project Management", value: "Excel", label: "Excel" },
  { key: "proofhub", parentId: "Project Management", value: "ProofHub", label: "ProofHub" },
  { key: "todoist", parentId: "Project Management", value: "Todoist", label: "Todoist" },
  { key: "salesmate", parentId: "Project Management", value: "Salesmate", label: "Salesmate" },
  { key: "testrail", parentId: "Quality Assurance", value: "TestRail", label: "TestRail" },
  { key: "zephyr-scale", parentId: "Quality Assurance", value: "Zephyr Scale", label: "Zephyr Scale" },
  { key: "practitest", parentId: "Quality Assurance", value: "PractiTest", label: "PractiTest" },
  { key: "testpad", parentId: "Quality Assurance", value: "Testpad", label: "Testpad" },
  { key: "spiratest", parentId: "Quality Assurance", value: "Spiratest", label: "Spiratest" },
  { key: "avo-assure", parentId: "Quality Assurance", value: "Avo Assure", label: "Avo Assure" },
  { key: "kobiton", parentId: "Quality Assurance", value: "Kobiton", label: "Kobiton" },
  { key: "accelq", parentId: "Quality Assurance", value: "ACCELQ", label: "ACCELQ" },
  { key: "lambdatest", parentId: "Quality Assurance", value: "LambdaTest", label: "LambdaTest" },
  { key: "selenium", parentId: "Quality Assurance", value: "Selenium", label: "Selenium" },
  { key: "qtp", parentId: "Quality Assurance", value: "QTP", label: "QTP" },
  { key: "testim", parentId: "Quality Assurance", value: "Testim", label: "Testim" },
  { key: "applitools", parentId: "Quality Assurance", value: "AppliTools", label: "AppliTools" },
  { key: "matlab", parentId: "Software Design", value: "MATLAB", label: "MATLAB" },
  { key: "catia", parentId: "Software Design", value: "CATIA", label: "CATIA" },
  { key: "zw3d", parentId: "Software Design", value: "ZW3D", label: "ZW3D" },
  { key: "mechdesigner", parentId: "Software Design", value: "MechDesigner", label: "MechDesigner" },
  { key: "ptc-creo", parentId: "Software Design", value: "PTC Creo", label: "PTC Creo" },
  { key: "bricscad", parentId: "Software Design", value: "BricsCAD", label: "BricsCAD" },
  { key: "solidedge", parentId: "Software Design", value: "SolidEdge", label: "SolidEdge" },
  { key: "rhino", parentId: "Software Design", value: "Rhino", label: "Rhino" },
  { key: "vistacreate", parentId: "Software Design", value: "VistaCreate", label: "VistaCreate" },
  { key: "quickbooks", parentId: "Accounting", value: "QuickBooks", label: "QuickBooks" },
  { key: "sage", parentId: "Accounting", value: "Sage", label: "Sage" },
  { key: "adp", parentId: "Accounting", value: "ADP", label: "ADP" },
  { key: "accountedge", parentId: "Accounting", value: "AccountEdge", label: "AccountEdge" },
  { key: "freshbooks", parentId: "Accounting", value: "FreshBooks", label: "FreshBooks" },
  { key: "oneup", parentId: "Accounting", value: "OneUp", label: "OneUp" },
  { key: "kashoo", parentId: "Accounting", value: "Kashoo", label: "Kashoo" },
  { key: "zipbooks", parentId: "Accounting", value: "ZipBooks", label: "ZipBooks" },
  { key: "wave", parentId: "Accounting", value: "Wave", label: "Wave" },
  { key: "xero", parentId: "Accounting", value: "Xero", label: "Xero" },
  { key: "netsuite", parentId: "Accounting", value: "NetSuite", label: "NetSuite" },
  { key: "avidxchange", parentId: "Accounting", value: "AvidXchange", label: "AvidXchange" },
  { key: "rossum", parentId: "Accounting", value: "Rossum", label: "Rossum" },
  { key: "docuphase", parentId: "Accounting", value: "DocuPhase", label: "DocuPhase" },
  { key: "payem", parentId: "Accounting", value: "PayEm", label: "PayEm" },
  { key: "trolley", parentId: "Accounting", value: "Trolley", label: "Trolley" },
  { key: "sighted", parentId: "Accounting", value: "Sighted", label: "Sighted" },
  { key: "wagepoint", parentId: "Accounting", value: "WagePoint", label: "WagePoint" },
  { key: "gusto", parentId: "Accounting", value: "Gusto", label: "Gusto" },
  { key: "surepayroll", parentId: "Accounting", value: "SurePayroll", label: "SurePayroll" },
  { key: "expensify", parentId: "Accounting", value: "Expensify", label: "Expensify" },
  { key: "neat", parentId: "Accounting", value: "Neat", label: "Neat" },
  { key: "fusebill", parentId: "Accounting", value: "Fusebill", label: "Fusebill" },
  { key: "hootsuite", parentId: "Marketing", value: "Hootsuite", label: "Hootsuite" },
  { key: "brandwatch", parentId: "Marketing", value: "Brandwatch", label: "Brandwatch" },
  { key: "ahrefs", parentId: "Marketing", value: "Ahrefs", label: "Ahrefs" },
  { key: "semrush", parentId: "Marketing", value: "SEMRush", label: "SEMRush" },
  { key: "google-seo-tools", parentId: "Marketing", value: "Google SEO Tools", label: "Google SEO Tools" },
  { key: "canva", parentId: "Marketing", value: "Canva", label: "Canva" },
  { key: "visme", parentId: "Marketing", value: "Visme", label: "Visme" },
  { key: "hotjar", parentId: "Marketing", value: "Hotjar", label: "Hotjar" },
  { key: "mailchimp-/-momentive", parentId: "Marketing", value: "MailChimp / Momentive", label: "MailChimp / Momentive" },
  { key: "marketo", parentId: "Marketing", value: "Marketo", label: "Marketo" },
  { key: "linktree", parentId: "Marketing", value: "Linktree", label: "Linktree" },
  { key: "unbounce", parentId: "Marketing", value: "Unbounce", label: "Unbounce" },
  { key: "airtable", parentId: "Marketing", value: "Airtable", label: "Airtable" },
  { key: "sprout-social", parentId: "Marketing", value: "Sprout Social", label: "Sprout Social" },
  { key: "loomly", parentId: "Marketing", value: "Loomly", label: "Loomly" },
  { key: "audiense", parentId: "Marketing", value: "Audiense", label: "Audiense" },
  { key: "hubspot", parentId: "Marketing", value: "HubSpot", label: "HubSpot" },
  { key: "sendgrid", parentId: "Marketing", value: "SendGrid", label: "SendGrid" },
  { key: "lemlist", parentId: "Marketing", value: "Lemlist", label: "Lemlist" },
  { key: "moosend", parentId: "Marketing", value: "Moosend", label: "Moosend" },
  { key: "clearscope", parentId: "Marketing", value: "Clearscope", label: "Clearscope" },
  { key: "optimizely", parentId: "Marketing", value: "Optimizely", label: "Optimizely" },
  { key: "clearbit", parentId: "Marketing", value: "Clearbit", label: "Clearbit" },
  { key: "datanyze", parentId: "Marketing", value: "Datanyze", label: "Datanyze" },
  { key: "optimonk", parentId: "Marketing", value: "OptiMonk", label: "OptiMonk" },
  { key: "typeform", parentId: "Marketing", value: "Typeform", label: "Typeform" },
  { key: "mailmunch", parentId: "Marketing", value: "MailMunch", label: "MailMunch" },
  { key: "creatopy", parentId: "Marketing", value: "Creatopy", label: "Creatopy" },
  { key: "venngage", parentId: "Marketing", value: "Venngage", label: "Venngage" },
  { key: "flipsnack", parentId: "Marketing", value: "Flipsnack", label: "Flipsnack" },
  { key: "whatagraph", parentId: "Marketing", value: "Whatagraph", label: "Whatagraph" },
  { key: "pixpa", parentId: "Marketing", value: "Pixpa", label: "Pixpa" },
  { key: "napoleoncat", parentId: "Marketing", value: "NapoleonCat", label: "NapoleonCat" },
  { key: "socialbee", parentId: "Marketing", value: "SocialBee", label: "SocialBee" },
  { key: "crowdfire", parentId: "Marketing", value: "Crowdfire", label: "Crowdfire" },
  { key: "buffer", parentId: "Marketing", value: "Buffer", label: "Buffer" },
  { key: "socialpilot", parentId: "Marketing", value: "SocialPilot", label: "SocialPilot" },
  { key: "8b.io", parentId: "Marketing", value: "8b.io", label: "8b.io" },
  { key: "saleshandy", parentId: "Marketing", value: "SalesHandy", label: "SalesHandy" },
  { key: "systeme", parentId: "Marketing", value: "Systeme", label: "Systeme" },
  { key: "getresponse", parentId: "Marketing", value: "GetResponse", label: "GetResponse" },
  { key: "elink", parentId: "Marketing", value: "elink", label: "elink" },
  { key: "hunter", parentId: "Marketing", value: "Hunter", label: "Hunter" },
  { key: "viewbix", parentId: "Marketing", value: "Viewbix", label: "Viewbix" },
  { key: "vidiq", parentId: "Marketing", value: "VidIQ", label: "VidIQ" },
  { key: "adroll", parentId: "Marketing", value: "AdRoll", label: "AdRoll" },
  { key: "social-media", parentId: "Marketing", value: "Social Media", label: "Social Media" },
  { key: "zoominfo", parentId: "Sales", value: "ZoomInfo", label: "ZoomInfo" },
  { key: "linkedin-sales-navigator", parentId: "Sales", value: "LinkedIn Sales Navigator", label: "LinkedIn Sales Navigator" },
  { key: "apollo", parentId: "Sales", value: "Apollo", label: "Apollo" },
  { key: "getemail", parentId: "Sales", value: "GetEmail", label: "GetEmail" },
  { key: "cognism", parentId: "Sales", value: "Cognism", label: "Cognism" },
  { key: "uplead", parentId: "Sales", value: "UpLead", label: "UpLead" },
  { key: "bookyouurdata", parentId: "Sales", value: "BookYouurData", label: "BookYouurData" },
  { key: "crunchbase", parentId: "Sales", value: "Crunchbase", label: "Crunchbase" },
  { key: "owler", parentId: "Sales", value: "Owler", label: "Owler" },
  { key: "rocketreach", parentId: "Sales", value: "RocketReach", label: "RocketReach" },
  { key: "kaspr", parentId: "Sales", value: "Kaspr", label: "Kaspr" },
  { key: "collective", parentId: "Sales", value: "Collective", label: "Collective" },
  { key: "hunter", parentId: "Sales", value: "Hunter", label: "Hunter" },
  { key: "seamless", parentId: "Sales", value: "Seamless", label: "Seamless" },
  { key: "hg-insights", parentId: "Sales", value: "HG Insights", label: "HG Insights" },
  { key: "easyleadz", parentId: "Sales", value: "EasyLeadz", label: "EasyLeadz" },
  { key: "6sense", parentId: "Sales", value: "6sense", label: "6sense" },
  { key: "foundry-intent", parentId: "Sales", value: "Foundry Intent", label: "Foundry Intent" },
  { key: "crossbeam", parentId: "Sales", value: "Crossbeam", label: "Crossbeam" },
  { key: "adapt", parentId: "Sales", value: "Adapt", label: "Adapt" },
  { key: "d&b-hoovers", parentId: "Sales", value: "D&B Hoovers", label: "D&B Hoovers" },
  { key: "salesforce", parentId: "Sales", value: "Salesforce", label: "Salesforce" },
  { key: "buzzboard", parentId: "Sales", value: "BuzzBoard", label: "BuzzBoard" },
  { key: "priority-engine", parentId: "Sales", value: "Priority Engine", label: "Priority Engine" },
  { key: "cufinder", parentId: "Sales", value: "CUFinder", label: "CUFinder" },
  { key: "similarweb-sales", parentId: "Sales", value: "Similarweb Sales", label: "Similarweb Sales" },
  { key: "usergems", parentId: "Sales", value: "UserGems", label: "UserGems" },
  { key: "wappalyzer", parentId: "Sales", value: "Wappalyzer", label: "Wappalyzer" },
  { key: "reveal", parentId: "Sales", value: "Reveal", label: "Reveal" },
  { key: "dealfront", parentId: "Sales", value: "Dealfront", label: "Dealfront" },
  { key: "looker", parentId: "Business Intelligence", value: "Looker", label: "Looker" },
  { key: "sisense", parentId: "Business Intelligence", value: "Sisense", label: "Sisense" },
  { key: "logi", parentId: "Business Intelligence", value: "Logi", label: "Logi" },
  { key: "qlik-sense", parentId: "Business Intelligence", value: "Qlik Sense", label: "Qlik Sense" },
  { key: "gooddata", parentId: "Business Intelligence", value: "GoodData", label: "GoodData" },
  { key: "esri-arcgis", parentId: "Business Intelligence", value: "Esri ArcGIS", label: "Esri ArcGIS" },
  { key: "alteryx", parentId: "Business Intelligence", value: "Alteryx", label: "Alteryx" },
  { key: "scribble-maps", parentId: "Business Intelligence", value: "Scribble Maps", label: "Scribble Maps" },
  { key: "connectbase", parentId: "Business Intelligence", value: "Connectbase", label: "Connectbase" },
  { key: "carto", parentId: "Business Intelligence", value: "CARTO", label: "CARTO" },
  { key: "segment", parentId: "Business Intelligence", value: "Segment", label: "Segment" },
  { key: "power-bi", parentId: "Business Intelligence", value: "Power BI", label: "Power BI" },
  { key: "tableau", parentId: "Business Intelligence", value: "Tableau", label: "Tableau" },
  { key: "amazon-quicksight", parentId: "Business Intelligence", value: "Amazon QuickSight", label: "Amazon QuickSight" },
  { key: "thoughtspot", parentId: "Business Intelligence", value: "ThoughtSpot", label: "ThoughtSpot" },
  { key: "sigma", parentId: "Business Intelligence", value: "Sigma", label: "Sigma" },
  { key: "google-chart-tools", parentId: "Business Intelligence", value: "Google Chart Tools", label: "Google Chart Tools" },
  { key: "ibm", parentId: "Business Intelligence", value: "IBM", label: "IBM" },
  { key: "wyn", parentId: "Business Intelligence", value: "Wyn", label: "Wyn" },
  { key: "domo", parentId: "Business Intelligence", value: "Domo", label: "Domo" },
  { key: "mode", parentId: "Business Intelligence", value: "Mode", label: "Mode" },
  { key: "microstrategy", parentId: "Business Intelligence", value: "MicroStrategy", label: "MicroStrategy" },
  { key: "oracle", parentId: "Business Intelligence", value: "Oracle", label: "Oracle" },
  { key: "explo", parentId: "Business Intelligence", value: "Explo", label: "Explo" },
  { key: "logi-symphony", parentId: "Business Intelligence", value: "Logi Symphony", label: "Logi Symphony" },
  { key: "intellicus", parentId: "Business Intelligence", value: "Intellicus", label: "Intellicus" },
  { key: "metabase", parentId: "Business Intelligence", value: "Metabase", label: "Metabase" },
  { key: "tibco-jaspersoft", parentId: "Business Intelligence", value: "TIBCO Jaspersoft", label: "TIBCO Jaspersoft" },
  { key: "reveal", parentId: "Business Intelligence", value: "Reveal", label: "Reveal" },
  { key: "datapine", parentId: "Business Intelligence", value: "datapine", label: "datapine" },
  { key: "cube", parentId: "Business Intelligence", value: "Cube", label: "Cube" },
  { key: "bokeh", parentId: "Business Intelligence", value: "Bokeh", label: "Bokeh" },
  { key: "toucan", parentId: "Business Intelligence", value: "Toucan", label: "Toucan" },
  { key: "qrvey", parentId: "Business Intelligence", value: "Qrvey", label: "Qrvey" },
  { key: "dash", parentId: "Business Intelligence", value: "Dash", label: "Dash" },
  { key: "strapi", parentId: "Content Management System", value: "Strapi", label: "Strapi" },
  { key: "brandfolder", parentId: "Content Management System", value: "Brandfolder", label: "Brandfolder" },
  { key: "wordpress", parentId: "Content Management System", value: "WordPress", label: "WordPress" },
  { key: "bynder", parentId: "Content Management System", value: "Bynder", label: "Bynder" },
  { key: "canto", parentId: "Content Management System", value: "Canto", label: "Canto" },
  { key: "acquia", parentId: "Content Management System", value: "Acquia", label: "Acquia" },
  { key: "photoshelter", parentId: "Content Management System", value: "PhotoShelter", label: "PhotoShelter" },
  { key: "aprimo", parentId: "Content Management System", value: "Aprimo", label: "Aprimo" },
  { key: "frontify", parentId: "Content Management System", value: "Frontify", label: "Frontify" },
  { key: "dash", parentId: "Content Management System", value: "Dash", label: "Dash" },
  { key: "adobe", parentId: "Content Management System", value: "Adobe", label: "Adobe" },
  { key: "cloudinary", parentId: "Content Management System", value: "Cloudinary", label: "Cloudinary" },
  { key: "aws", parentId: "Content Management System", value: "AWS", label: "AWS" },
  { key: "image-relay", parentId: "Content Management System", value: "Image Relay", label: "Image Relay" },
  { key: "mediavalet", parentId: "Content Management System", value: "MediaValet", label: "MediaValet" },
  { key: "air", parentId: "Content Management System", value: "Air", label: "Air" },
  { key: "veeva", parentId: "Content Management System", value: "Veeva", label: "Veeva" },
  { key: "salsify", parentId: "Content Management System", value: "Salsify", label: "Salsify" },
  { key: "pickit", parentId: "Content Management System", value: "Pickit", label: "Pickit" },
  { key: "imagekit", parentId: "Content Management System", value: "ImageKit", label: "ImageKit" },
  { key: "catsy", parentId: "Content Management System", value: "Catsy", label: "Catsy" },
  { key: "kontainer", parentId: "Content Management System", value: "Kontainer", label: "Kontainer" },
  { key: "screendragon", parentId: "Content Management System", value: "Screendragon", label: "Screendragon" },
  { key: "amplifi", parentId: "Content Management System", value: "Amplifi", label: "Amplifi" },
  { key: "stockpress", parentId: "Content Management System", value: "Stockpress", label: "Stockpress" },
  { key: "openasset", parentId: "Content Management System", value: "OpenAsset", label: "OpenAsset" },
  { key: "zendesk", parentId: "Customer Service / Support", value: "ZenDesk", label: "ZenDesk" },
  { key: "intercom", parentId: "Customer Service / Support", value: "Intercom", label: "Intercom" },
  { key: "hubspot", parentId: "Customer Service / Support", value: "HubSpot", label: "HubSpot" },
  { key: "freshdesk", parentId: "Customer Service / Support", value: "FreshDesk", label: "FreshDesk" },
  { key: "salesforce", parentId: "Customer Service / Support", value: "Salesforce", label: "Salesforce" },
  { key: "gorgia", parentId: "Customer Service / Support", value: "Gorgia", label: "Gorgia" },
  { key: "front", parentId: "Customer Service / Support", value: "Front", label: "Front" },
  { key: "gladly", parentId: "Customer Service / Support", value: "Gladly", label: "Gladly" },
  { key: "sap", parentId: "Customer Service / Support", value: "SAP", label: "SAP" },
  { key: "help-scout", parentId: "Customer Service / Support", value: "Help Scout", label: "Help Scout" },
  { key: "re:amaze", parentId: "Customer Service / Support", value: "Re:Amaze", label: "Re:Amaze" },
  { key: "servicenow", parentId: "Customer Service / Support", value: "ServiceNow", label: "ServiceNow" },
  { key: "hiver", parentId: "Customer Service / Support", value: "Hiver", label: "Hiver" },
  { key: "livechat", parentId: "Customer Service / Support", value: "LiveChat", label: "LiveChat" },
  { key: "capacity", parentId: "Customer Service / Support", value: "Capacity", label: "Capacity" },
  { key: "rescue", parentId: "Customer Service / Support", value: "Rescue", label: "Rescue" },
  { key: "commbox", parentId: "Customer Service / Support", value: "CommBox", label: "CommBox" },
  { key: "crisp", parentId: "Customer Service / Support", value: "Crisp", label: "Crisp" },
  { key: "incident-iq", parentId: "Customer Service / Support", value: "Incident IQ", label: "Incident IQ" }
];

export const skills = [
  { parentKeys: ['Analytics', 'CRM', 'Project Management', 'Business Intelligence'], key: 'analytics', value: "Analytics", label: "Analytics" },
  { parentKeys: ['Analytics', 'CRM', 'Project Management', 'Business Intelligence'], key: 'data', value: "Data", label: "Data" },
  { parentKeys: ['Accounting', 'Project Management', 'Business Intelligence'], key: 'financials', value: "Financials", label: "Financials" },
  { parentKeys: ['CRM', 'Project Management'], key: 'user-testing', value: "User Testing", label: "User Testing" },
  { parentKeys: ['Design', 'Software Design'], key: 'design', value: "Design", label: "Design" },
  { parentKeys: ['Design', 'Software Design', 'Project Management'], key: 'design-thinking', value: "Design Thinking", label: "Design Thinking" },
  { parentKeys: ['Design', 'CRM', 'Project Management', 'Software Design', 'Marketing', 'Business Intelligence'], key: 'product-management', value: "Product Management", label: "Product Management" },
  { parentKeys: ['Marketing', 'Business Intelligence', 'Content Management System'], key: 'seo', value: "SEO", label: "SEO" },
  { parentKeys: ['Design	Marketing', 'Content Management System'], key: 'copy-writing', value: "Copy Writing", label: "Copy Writing" },
  { parentKeys: ['Design', 'CRM	Marketing', 'Content Management System'], key: 'content-writing', value: "Content Writing", label: "Content Writing" },
  { parentKeys: ['Accounting', 'Business Intelligence', 'Project Management', 'Marketing'], key: 'business-plan', value: "Business Plan", label: "Business Plan" },
  { parentKeys: ['Analytics', 'CRM	Marketing'], key: 'research', value: "Research", label: "Research" },
  { parentKeys: ['Design', 'Software'], key: 'web-development', value: "Web Development", label: "Web Development" },
  { parentKeys: ['Design', 'Software'], key: 'mobile-app-development', value: "Mobile App Development", label: "Mobile App Development" },
  { parentKeys: ['Design', 'Software'], key: 'gaming-development', value: "Gaming Development", label: "Gaming Development" },
  { parentKeys: ['Design', 'Software'], key: 'VR-development', value: "VR Development", label: "VR Development" },
  { parentKeys: ['Software'], key: 'AI-development', value: "AI Development", label: "AI Development" },
  { parentKeys: ['Software'], key: 'ML-development', value: "ML Development", label: "ML Development" },
  { parentKeys: ['CRM'], key: 'people-management', value: "People Management", label: "People Management" },
  { parentKeys: ['CRM', 'Project Management'], key: 'leadership', value: "Leadership", label: "Leadership" },
  { parentKeys: ['Quality Assurance', 'Software'], key: 'quality-assurance', value: "Quality Assurance", label: "Quality Assurance" },
  { parentKeys: ['Software'], key: 'devOps', value: "DevOps", label: "DevOps" },
  { parentKeys: ['Design', 'Software'], key: 'frontend-development', value: "Frontend Development", label: "Frontend Development" },
  { parentKeys: ['Design', 'Software'], key: 'backend-development', value: "Backend Development", label: "Backend Development" },
  { parentKeys: ['Software'], key: 'database-development', value: "Database Development", label: "Database Development" },
  { parentKeys: ['Design', 'CRM', 'Project Management', 'Marketing'], key: 'marketing-strategy', value: "Marketing Strategy", label: "Marketing Strategy" },
  { parentKeys: ['Design', 'CRM', 'Project Management', 'Marketing', 'Business Intelligence'], key: 'email-marketing', value: "Email Marketing", label: "Email Marketing" },
  { parentKeys: ['Design', 'CRM', 'Project Management', 'Marketing'], key: 'customer-lifecycles', value: "Customer Lifecycles", label: "Customer Lifecycles" },
  { parentKeys: ['Design', 'CRM', 'Marketing', 'Business Intelligence'], key: 'customer-adoption', value: "Customer Adoption", label: "Customer Adoption" },
  { parentKeys: ['Sales', 'CRM', 'Project Management', 'Marketing', 'Business Intelligence'], key: 'sales', value: "Sales", label: "Sales" },
  { parentKeys: ['Sales', 'CRM', 'Project Management', 'Business Intelligence'], key: 'account-ownership', value: "Account Ownership", label: "Account Ownership" },
  { parentKeys: ['Sales', 'CRM', 'Marketing'], key: 'lead-generation', value: "Lead Generation", label: "Lead Generation" },
  { parentKeys: ['Design', 'Project Management', 'Marketing'], key: 'paid-ads', value: "Paid Ads", label: "Paid Ads" },
  { parentKeys: ['Design', 'CRM', 'Marketing'], key: 'social-media', value: "Social Media", label: "Social Media" },
  { parentKeys: ['Analytics', 'CRM', 'Project Management', 'Business Intelligence'], key: 'analyst', value: "Analyst", label: "Analyst" },
  { parentKeys: ['Analytics', 'CRM', 'Project Management', 'Business Intelligence', 'Sales	Marketing'], key: 'project-management', value: "Project Management", label: "Project Management" },
];

export const availableHours = new Array(24)
  .fill('1')
  .map((v, i) => ({
    key: ((i + 1) + '-hours'),
    label: ((i + 1) + ' hours'),
    value: ((i + 1) + '-hours'),
  }));

export const higherEducation = [
  { key: 'secondary', value: "Secondary", label: "Secondary" },
  { key: 'post-secondary', value: "Post-Secondary", label: "Post-Secondary" },
  { key: 'higher-education', value: "Higher Education", label: "Higher Education" },
  { key: 'bootcamp', value: "Bootcamp", label: "Bootcamp" },
  { key: 'certification', value: "Certification", label: "Certification" },
  { key: 'learned on my own', value: "Learned on my own", label: "Learned on my own" },
];

export const completedDegrees = [
  { key: 'secondary-school-diploma', value: "Secondary School Diploma", label: "Secondary School Diploma" },
  { key: 'post-secondary-certificate', value: "Post Secondary Certificate", label: "Post Secondary Certificate" },
  { key: 'post-secondary-diploma', value: "Post Secondary Diploma", label: "Post Secondary Diploma" },
  { key: 'bachelor-degree', value: "Bachelor Degree", label: "Bachelor Degree" },
  { key: 'post-graduate-degree', value: "Post Graduate Degree", label: "Post Graduate Degree" },
  { key: 'masters-degree', value: "Masters Degree", label: "Masters Degree" },
  { key: 'phd', value: "PHD", label: "PHD" },
]

export const FieldOfstudy = [
  { key: 'a.i', value: "A.I.", label: "A.I." },
  { key: 'accounting', value: "Accounting", label: "Accounting" },
  { key: 'aerospace-engineering', value: "Aerospace Engineering", label: "Aerospace Engineering" },
  { key: 'agricultural-sciences', value: "Agricultural Sciences", label: "Agricultural Sciences" },
  { key: 'agriculture', value: "Agriculture", label: "Agriculture" },
  { key: 'agronomy', value: "Agronomy", label: "Agronomy" },
  { key: 'andragogy', value: "Andragogy", label: "Andragogy" },
  { key: 'animal-husbandry', value: "Animal Husbandry", label: "Animal Husbandry" },
  { key: 'anthropology', value: "Anthropology", label: "Anthropology" },
  { key: 'applied-mathematics', value: "Applied Mathematics", label: "Applied Mathematics" },
  { key: 'archaeology', value: "Archaeology", label: "Archaeology" },
  { key: 'architecture', value: "Architecture", label: "Architecture" },
  { key: 'area-studie', value: "Area Studie", label: "Area Studie" },
  { key: 'arts', value: "Arts", label: "Arts" },
  { key: 'artworks-restoration', value: "Artworks Restoration", label: "Artworks Restoration" },
  { key: 'biochemistry', value: "Biochemistry", label: "Biochemistry" },
  { key: 'bioengineering', value: "Bioengineering", label: "Bioengineering" },
  { key: 'biology', value: "Biology", label: "Biology" },
  { key: 'biophysics', value: "Biophysics", label: "Biophysics" },
  { key: 'biotechnology', value: "Biotechnology", label: "Biotechnology" },
  { key: 'business-and-public-administration', value: "Business and Public Administration", label: "Business and Public Administration" },
  { key: 'business-studies', value: "Business Studies", label: "Business Studies" },
  { key: 'chemical-engineering', value: "Chemical Engineering", label: "Chemical Engineering" },
  { key: 'chemistry', value: "Chemistry", label: "Chemistry" },
  { key: 'civil-engineering', value: "Civil Engineering", label: "Civil Engineering" },
  { key: 'classical-studies', value: "Classical Studies", label: "Classical Studies" },
  { key: 'coach', value: "Coach", label: "Coach" },
  { key: 'communication', value: "Communication", label: "Communication" },
  { key: 'competitive-sport', value: "Competitive Sport", label: "Competitive Sport" },
  { key: 'computing', value: "Computing", label: "Computing" },
  { key: 'cosmetology', value: "Cosmetology", label: "Cosmetology" },
  { key: 'cultural-studies', value: "Cultural Studies", label: "Cultural Studies" },
  { key: 'dance', value: "Dance", label: "Dance" },
  { key: 'dental-hygiene', value: "Dental Hygiene", label: "Dental Hygiene" },
  { key: 'dentistry', value: "Dentistry", label: "Dentistry" },
  { key: 'design', value: "Design", label: "Design" },
  { key: 'ecology', value: "Ecology", label: "Ecology" },
  { key: 'economics', value: "Economics", label: "Economics" },
  { key: 'education', value: "Education", label: "Education" },
  { key: 'education-sciences', value: "Education Sciences", label: "Education Sciences" },
  { key: 'electrical-engineering', value: "Electrical Engineering", label: "Electrical Engineering" },
  { key: 'electronics-engineering', value: "Electronics Engineering", label: "Electronics Engineering" },
  { key: 'engineering-sciences', value: "Engineering Sciences", label: "Engineering Sciences" },
  { key: 'environmental-engineering', value: "Environmental Engineering", label: "Environmental Engineering" },
  { key: 'environmental-sciences', value: "Environmental Sciences", label: "Environmental Sciences" },
  { key: 'film', value: "Film", label: "Film" },
  { key: 'finance', value: "Finance", label: "Finance" },
  { key: 'fine-art', value: "Fine Art", label: "Fine Art" },
  { key: 'fishery', value: "Fishery", label: "Fishery" },
  { key: 'fitness', value: "Fitness", label: "Fitness" },
  { key: 'food-Studies', value: "Food Studies", label: "Food Studies" },
  { key: 'food-technology', value: "Food Technology", label: "Food Technology" },
  { key: 'forestry', value: "Forestry", label: "Forestry" },
  { key: 'genetics', value: "Genetics", label: "Genetics" },
  { key: 'geology', value: "Geology", label: "Geology" },
  { key: 'health-sciences', value: "Health Sciences", label: "Health Sciences" },
  { key: 'heritage-studies', value: "Heritage Studies", label: "Heritage Studies" },
  { key: 'history', value: "History", label: "History" },
  { key: 'history-and-theory-of-art', value: "History and Theory of Art", label: "History and Theory of Art" },
  { key: 'human-geography', value: "Human Geography", label: "Human Geography" },
  { key: 'human-resource-management', value: "Human Resource Management", label: "Human Resource Management" },
  { key: 'humanities', value: "Humanities", label: "Humanities" },
  { key: 'informatics', value: "Informatics", label: "Informatics" },
  { key: 'informatics-engineering', value: "Informatics Engineering", label: "Informatics Engineering" },
  { key: 'information-services', value: "Information Services", label: "Information Services" },
  { key: 'information-systems', value: "Information Systems", label: "Information Systems" },
  { key: 'instructor', value: "Instructor", label: "Instructor" },
  { key: 'journalism', value: "Journalism", label: "Journalism" },
  { key: 'landscape-architecture', value: "Landscape Architecture", label: "Landscape Architecture" },
  { key: 'law', value: "Law", label: "Law" },
  { key: 'life-sciences', value: "Life Sciences", label: "Life Sciences" },
  { key: 'lingual-studies', value: "Lingual Studies", label: "Lingual Studies" },
  { key: 'linguistics', value: "Linguistics", label: "Linguistics" },
  { key: 'literary-studies', value: "Literary Studies", label: "Literary Studies" },
  { key: 'management', value: "Management", label: "Management" },
  { key: 'maritime-engineering', value: "Maritime Engineering", label: "Maritime Engineering" },
  { key: 'maritime-technology', value: "Maritime Technology", label: "Maritime Technology" },
  { key: 'marketing', value: "Marketing", label: "Marketing" },
  { key: 'materials-technology', value: "Materials Technology", label: "Materials Technology" },
  { key: 'mathematical-sciences', value: "Mathematical Sciences", label: "Mathematical Sciences" },
  { key: 'mathematics', value: "Mathematics", label: "Mathematics" },
  { key: 'measurement-engineering', value: "Measurement Engineering", label: "Measurement Engineering" },
  { key: 'mechanical-engineering', value: "Mechanical Engineering", label: "Mechanical Engineering" },
  { key: 'media-art', value: "Media Art", label: "Media Art" },
  { key: 'medical-technology', value: "Medical Technology", label: "Medical Technology" },
  { key: 'medicine', value: "Medicine", label: "Medicine" },
  { key: 'microbiology', value: "Microbiology", label: "Microbiology" },
  { key: 'molecular-biology', value: "Molecular Biology", label: "Molecular Biology" },
  { key: 'music', value: "Music", label: "Music" },
  { key: 'natural-resource-technology', value: "Natural Resource Technology", label: "Natural Resource Technology" },
  { key: 'nursing-and-midwifery', value: "Nursing and Midwifery", label: "Nursing and Midwifery" },
  { key: 'nutrition', value: "Nutrition", label: "Nutrition" },
  { key: 'occupational-therapy', value: "Occupational Therapy", label: "Occupational Therapy" },
  { key: 'pedagogy', value: "Pedagogy", label: "Pedagogy" },
  { key: 'personal Trainer', value: "Personal Trainer", label: "Personal Trainer" },
  { key: 'pharmacy', value: "Pharmacy", label: "Pharmacy" },
  { key: 'philology', value: "Philology", label: "Philology" },
  { key: 'philosophy', value: "Philosophy", label: "Philosophy" },
  { key: 'photography', value: "Photography", label: "Photography" },
  { key: 'physical-geography', value: "Physical Geography", label: "Physical Geography" },
  { key: 'physical-sciences', value: "Physical Sciences", label: "Physical Sciences" },
  { key: 'physics', value: "Physics", label: "Physics" },
  { key: 'political-sciences', value: "Political Sciences", label: "Political Sciences" },
  { key: 'polymer-and-textile-technology', value: "Polymer and Textile Technology", label: "Polymer and Textile Technology" },
  { key: 'power-engineering', value: "Power Engineering", label: "Power Engineering" },
  { key: 'production-and-manufacturing-engineering', value: "Production and Manufacturing Engineering", label: "Production and Manufacturing Engineering" },
  { key: 'psychology', value: "Psychology", label: "Psychology" },
  { key: 'public-administration', value: "Public Administration", label: "Public Administration" },
  { key: 'public-catering', value: "Public Catering", label: "Public Catering" },
  { key: 'public-health', value: "Public Health", label: "Public Health" },
  { key: 'public-security', value: "Public Security", label: "Public Security" },
  { key: 'publishing', value: "Publishing", label: "Publishing" },
  { key: 'recreational-sport', value: "Recreational Sport", label: "Recreational Sport" },
  { key: 'rehabilitation', value: "Rehabilitation", label: "Rehabilitation" },
  { key: 'religious-studies', value: "Religious Studies", label: "Religious Studies" },
  { key: 'robotics', value: "Robotics", label: "Robotics" },
  { key: 'safety-engineering', value: "Safety Engineering", label: "Safety Engineering" },
  { key: 'social-sciences', value: "Social Sciences", label: "Social Sciences" },
  { key: 'social-work', value: "Social Work", label: "Social Work" },
  { key: 'sociology', value: "Sociology", label: "Sociology" },
  { key: 'software-engineering', value: "Software Engineering", label: "Software Engineering" },
  { key: 'sport', value: "Sport", label: "Sport" },
  { key: 'statistics', value: "Statistics", label: "Statistics" },
  { key: 'technological-sciences', value: "Technological Sciences", label: "Technological Sciences" },
  { key: 'theatre', value: "Theatre", label: "Theatre" },
  { key: 'theology', value: "Theology", label: "Theology" },
  { key: 'tourism-and-leisure', value: "Tourism and Leisure", label: "Tourism and Leisure" },
  { key: 'translation-studies', value: "Translation Studies", label: "Translation Studies" },
  { key: 'transport-engineering', value: "Transport Engineering", label: "Transport Engineering" },
  { key: 'undecided', value: "Undecided", label: "Undecided" },
  { key: 'veterinary', value: "Veterinary", label: "Veterinary" },
  { key: 'veterinary-sciences', value: "Veterinary Sciences", label: "Veterinary Sciences" },
  { key: 'wellness', value: "Wellness", label: "Wellness" },
];

export const yearOfExperience = new Array(moment().subtract(1, 'year').diff(moment('1949', 'YYYY'), 'years'))
  .fill('1')
  .map((v, i) => ({
    key: moment().subtract((moment().diff(moment('1949', 'YYYY'), 'years') - (i + 1)), 'years').format('YYYY'),
    value: moment().subtract((moment().diff(moment('1949', 'YYYY'), 'years') - (i + 1)), 'years').format('YYYY'),
    label: (moment().subtract((moment().diff(moment('1949', 'YYYY'), 'years') - (i + 1)), 'years').format('YYYY'))
  }));

export const companyIndustry = [
  // { key: "accounting-finance-corporate", option: "accounting-finance-(Corporate)", label: "Accounting / Financial Services = Financial Services", value: "Accounting / Finance - Corporate" },
  // { key: "consulting-general", option: "consulting-(General)", label: "Consulting (General) = Consulting", value: "Consulting - General" },
  // { key: "e-commerce", option: "e-commerce", label: "E-commerce (take out for now)", value: "E-Commerce" },
  // { key: "government-municipal", option: "government-(Municipal)", label: "Government – Municipal = Government", value: "Government - Municipal" },
  // { key: "health-care", option: "health-care", label: "Health Care", value: "Health Care" },
  // { key: "legal-general", option: "legal-(General)", label: "Legal – General = Legal", value: "Legal - General" },
  // { key: "marketing-agencies", option: "marketing-(Agencies)", label: "Marketing - Agencies", value: "Marketing - Agencies" },
  // { key: "oil-gas", option: "oil-gas", label: "Oil & Gas", value: "Oil & Gas" },
  // { key: "retail-corporate", option: "retail-(Corporate)", label: "Retail - Corporate", value: "Retail - Corporate" },
  // { key: "tech-general", option: "tech-(General)", label: "Tech - General", value: "Tech - General" },
  { "key": "construction-corporate", "option": "construction-(Corporate)", "label": "Construction - Corporate", "value": "Construction - Corporate" },
  { "key": "consulting", "option": "consulting", "label": "Consulting", "value": "Consulting" },
  { "key": "energy", "option": "energy", "label": "Energy", "value": "Energy" },
  { "key": "engineering", "option": "engineering", "label": "Engineering", "value": "Engineering" },
  { "key": "finance", "option": "finance", "label": "Finance", "value": "Finance" },
  { "key": "government", "option": "government", "label": "Government", "value": "Government" },
  { "key": "healthcare", "option": "healthcare", "label": "HealthCare", "value": "HealthCare" },
  { "key": "investment", "option": "investment", "label": "Investment", "value": "Investment" },
  { "key": "legal", "option": "legal", "label": "Legal", "value": "Legal" },
  { "key": "marketing-agencies", "option": "marketing-agencies", "label": "Marketing Agencies", "value": "Marketing Agencies" },
  { "key": "real-estate-corporate", "option": "real-estate-(Corporate)", "label": "Real Estate - Corporate", "value": "Real Estate - Corporate" },
  { "key": "retail-corporate", "option": "retail-(Corporate)", "label": "Retail - Corporate", "value": "Retail - Corporate" },
  { "key": "startup", "option": "startup", "label": "Startup", "value": "Startup" },
  { "key": "technology", "option": "technology", "label": "Technology", "value": "Technology" }
];

export const ceoIndustry = [
  { "key": "business-development", "option": "business-development", "label": "Business Development", "value": "Business Development" },
  { "key": "change-management", "option": "change-management", "label": "Change Management", "value": "Change Management" },
  { "key": "corporate-governance", "option": "corporate-governance", "label": "Corporate Governance", "value": "Corporate Governance" },
  { "key": "financial-acumen", "option": "financial-acumen", "label": "Financial Acumen", "value": "Financial Acumen" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation" },
  { "key": "market-analysis", "option": "market-analysis", "label": "Market Analysis", "value": "Market Analysis" },
  { "key": "operational-oversight", "option": "operational-oversight", "label": "Operational Oversight", "value": "Operational Oversight" },
  { "key": "risk-management", "option": "risk-management", "label": "Risk Management", "value": "Risk Management" },
  { "key": "stakeholder-management", "option": "stakeholder-management", "label": "Stakeholder Management", "value": "Stakeholder Management" },
  { "key": "strategic-planning", "option": "strategic-planning", "label": "Strategic Planning", "value": "Strategic Planning" },
  { "key": "visionary-leadership", "option": "visionary-leadership", "label": "Visionary Leadership", "value": "Visionary Leadership" }
]
export const cfoIndustry = [
  { "key": "auditing", "option": "auditing", "label": "Auditing", "value": "Auditing" },
  { "key": "budgeting-and-forecasting", "option": "budgeting-and-forecasting", "label": "Budgeting and Forecasting", "value": "Budgeting and Forecasting" },
  { "key": "capital-structure-and-financing", "option": "capital-structure-and-financing", "label": "Capital Structure and Financing", "value": "Capital Structure and Financing" },
  { "key": "cash-flow-management", "option": "cash-flow-management", "label": "Cash Flow Management", "value": "Cash Flow Management" },
  { "key": "cost-management", "option": "cost-management", "label": "Cost Management", "value": "Cost Management" },
  { "key": "financial-modeling", "option": "financial-modeling", "label": "Financial Modeling", "value": "Financial Modeling" },
  { "key": "financial-planning-and-analysis", "option": "financial-planning-and-analysis", "label": "Financial Planning and Analysis", "value": "Financial Planning and Analysis" },
  { "key": "financial-reporting", "option": "financial-reporting", "label": "Financial Reporting", "value": "Financial Reporting" },
  { "key": "internal-controls-and-compliance", "option": "internal-controls-and-compliance", "label": "Internal Controls and Compliance", "value": "Internal Controls and Compliance" },
  { "key": "investment-strategy", "option": "investment-strategy", "label": "Investment Strategy", "value": "Investment Strategy" },
  { "key": "regulatory-compliance", "option": "regulatory-compliance", "label": "Regulatory Compliance", "value": "Regulatory Compliance" },
  { "key": "risk-management", "option": "risk-management", "label": "Risk Management", "value": "Risk Management" },
  { "key": "strategic-planning", "option": "strategic-planning", "label": "Strategic Planning", "value": "Strategic Planning" }
]


export const pricings = [
  { key: "25", value: "25", label: "CA$25-50", },
  { key: "50", value: "50", label: "CA$50-100", },
  { key: "100", value: "100", label: "CA$100-150", },
  { key: "150", value: "150", label: "CA$150-200", },
  { key: "200", value: "200", label: "CA$200-250", },
  { key: "250", value: "250", label: "CA$250-300" }
];

export const needTools = [
  { key: "quickbooks", option: "quickbooks", label: "QuickBooks", value: "QuickBooks" },
  { key: "tiplati", option: "tiplati", label: "Tiplati", value: "Tiplati" },
  { key: "stripe", option: "stripe", label: "Stripe", value: "Stripe" },
  { key: "netsuite", parentId: "netsuite", value: "NetSuite", label: "NetSuite" },
];

export const companyIndustries = [
  { key: "accessibility-services", option: "accessibility-services", label: "Accessibility Services", value: "Accessibility Services" },
  { key: "accounting", option: "accounting", label: "Accounting", value: "Accounting" },
  { key: "advertising-services", option: "advertising-services", label: "Advertising Services", value: "Advertising Services" },
  { key: "aerospace-aviation", option: "aerospace-aviation", label: "Aerospace & Aviation", value: "Aerospace & Aviation" },
  { key: "airline-services", option: "airline-services", label: "Airline Services", value: "Airline Services" },
  { key: "alcohol-tabacco-services", option: "alcohol-tabacco-services", label: "Alcohol & Tabacco Services", value: "Alcohol & Tabacco Services" },
  { key: "alternative-holistic-healing", option: "alternative-holistic-healing", label: "Alternative & Holistic Healing", value: "Alternative & Holistic Healing" },
  { key: "alternative-fuel", option: "alternative-fuel", label: "Alternative Fuel", value: "Alternative Fuel" },
  { key: "animation-serices", option: "animation-serices", label: "Animation Serices", value: "Animation Serices" },
  { key: "architecture-services", option: "architecture-services", label: "Architecture Services", value: "Architecture Services" },
  { key: "art-music-writers-creatives", option: "art-music-writers-creatives", label: "Art, Music, Writers & Creatives", value: "Art, Music, Writers & Creatives" },
  { key: "automotive-software", option: "automotive-software", label: "Automotive Software", value: "Automotive Software" },
  { key: "banking-services", option: "banking-services", label: "Banking Services", value: "Banking Services" },
  { key: "bars-taverns-nightclub-services", option: "bars-taverns-nightclub-services", label: "Bars, Taverns & Nightclub Services", value: "Bars, Taverns & Nightclub Services" },
  { key: "biotechnology", option: "biotechnology", label: "Biotechnology", value: "Biotechnology" },
  { key: "blockchain-services", option: "blockchain-services", label: "Blockchain Services", value: "Blockchain Services" },
  { key: "broadcast-media", option: "broadcast-media", label: "Broadcast Media", value: "Broadcast Media" },
  { key: "business-intelligence", option: "business-intelligence", label: "Business Intelligence", value: "Business Intelligence" },
  { key: "capital-markets", option: "capital-markets", label: "Capital Markets", value: "Capital Markets" },
  { key: "child-care-services", option: "child-care-services", label: "Child Care Services", value: "Child Care Services" },
  { key: "chiropractors", option: "chiropractors", label: "Chiropractors", value: "Chiropractors" },
  { key: "civic-social-organizations", option: "civic-social-organizations", label: "Civic & Social Organizations", value: "Civic & Social Organizations" },
  { key: "civil-engineering", option: "civil-engineering", label: "Civil Engineering", value: "Civil Engineering" },
  { key: "climate-technology-services", option: "climate-technology-services", label: "Climate Technology Services", value: "Climate Technology Services" },
  { key: "commercial-furniture", option: "commercial-furniture", label: "Commercial Furniture", value: "Commercial Furniture" },
  { key: "commercial-real-estate", option: "commercial-real-estate", label: "Commercial Real-estate", value: "Commercial Real-estate" },
  { key: "computer-hardware", option: "computer-hardware", label: "Computer Hardware", value: "Computer Hardware" },
  { key: "computer-networking", option: "computer-networking", label: "Computer Networking", value: "Computer Networking" },
  { key: "computing-software", option: "computing-software", label: "Computing Software", value: "Computing Software" },
  { key: "construction", option: "construction", label: "Construction", value: "Construction" },
  { key: "consulting", option: "consulting", label: "Consulting", value: "Consulting" },
  { key: "consumer-services", option: "consumer-services", label: "Consumer Services", value: "Consumer Services" },
  { key: "data-entry-services", option: "data-entry-services", label: "Data Entry Services", value: "Data Entry Services" },
  { key: "data-science", option: "data-science", label: "Data Science", value: "Data Science" },
  { key: "data-security-infrastructure", option: "data-security-infrastructure", label: "Data Security & Infrastructure", value: "Data Security & Infrastructure" },
  { key: "defense-space", option: "defense-space", label: "Defense & Space", value: "Defense & Space" },
  { key: "diagnostic-equipment", option: "diagnostic-equipment", label: "Diagnostic Equipment", value: "Diagnostic Equipment" },
  { key: "e-Commerce", option: "e-Commerce", label: "e-Commerce", value: "e-Commerce" },
  { key: "e-Learning-services", option: "e-Learning-services", label: "e-Learning Services", value: "e-Learning Services" },
  { key: "education-services", option: "education-services", label: "Education Services", value: "Education Services" },
  { key: "entertainment-providers", option: "entertainment-providers", label: "Entertainment Providers", value: "Entertainment Providers" },
  { key: "equipment-rental", option: "equipment-rental", label: "Equipment Rental", value: "Equipment Rental" },
  { key: "event-dervices", option: "event-dervices", label: "Event Services", value: "Event Services" },
  { key: "facility-management", option: "facility-management", label: "Facility Management", value: "Facility Management" },
  { key: "family-planning", option: "family-planning", label: "Family Planning", value: "Family Planning" },
  { key: "farming-agricultural-services", option: "farming-agricultural-services", label: "Farming & Agricultural Services", value: "Farming & Agricultural Services" },
  { key: "financial-services", option: "financial-services", label: "Financial Services", value: "Financial Services" },
  { key: "food-beverage", option: "food-beverage", label: "Food & Beverage", value: "Food & Beverage" },
  { key: "fundraising-services", option: "fundraising-services", label: "Fundraising Services", value: "Fundraising Services" },
  { key: "gambling-software", option: "gambling-software", label: "Gambling Software", value: "Gambling Software" },
  { key: "gaming", option: "gaming", label: "Gaming", value: "Gaming" },
  { key: "geothermal-services", option: "geothermal-services", label: "Geothermal Services", value: "Geothermal Services" },
  { key: "government-administration", option: "government-administration", label: "Government Administration", value: "Government Administration" },
  { key: "groceries", option: "groceries", label: "Groceries", value: "Groceries" },
  { key: "home-furniture", option: "home-furniture", label: "Home Furniture", value: "Home Furniture" },
  { key: "horticulture-sServices", option: "horticulture-sServices", label: "Horticulture Services", value: "Horticulture Services" },
  { key: "hosiptals-healthcare", option: "hosiptals-healthcare", label: "Hosiptals & Healthcare", value: "Hosiptals & Healthcare" },
  { key: "hospitality-services", option: "hospitality-services", label: "Hospitality Services", value: "Hospitality Services" },
  { key: "household-services", option: "household-services", label: "Household Services", value: "Household Services" },
  { key: "housing-programs", option: "housing-programs", label: "Housing Programs", value: "Housing Programs" },
  { key: "human-resources", option: "human-resources", label: "Human Resources", value: "Human Resources" },
  { key: "individual-family-services", option: "individual-family-services", label: "Individual & Family Services", value: "Individual & Family Services" },
  { key: "insurance", option: "insurance", label: "Insurance", value: "Insurance" },
  { key: "interior-design", option: "interior-design", label: "Interior Design", value: "Interior Design" },
  { key: "international-business-services", option: "international-business-services", label: "International Business Services", value: "International Business Services" },
  { key: "investment-management", option: "investment-management", label: "Investment Management", value: "Investment Management" },
  { key: "hournalism-reporting-services", option: "hournalism-reporting-services", label: "Journalism & Reporting Services", value: "Journalism & Reporting Services" },
  { key: "law-legal", option: "law-legal", label: "Law & Legal", value: "Law & Legal" },
  { key: "law-enforcement", option: "law-enforcement", label: "Law Enforcement", value: "Law Enforcement" },
  { key: "legislative-services", option: "legislative-services", label: "Legislative Services", value: "Legislative Services" },
  { key: "manufacturing", option: "manufacturing", label: "Manufacturing", value: "Manufacturing" },
  { key: "market-research", option: "market-research", label: "Market Research", value: "Market Research" },
  { key: "media-production", option: "media-production", label: "Media Production", value: "Media Production" },
  { key: "medical-equipment", option: "medical-equipment", label: "Medical Equipment", value: "Medical Equipment" },
  { key: "mental-health-care", option: "mental-health-care", label: "Mental Health Care", value: "Mental Health Care" },
  { key: "merchandising", option: "merchandising", label: "Merchandising", value: "Merchandising" },
  { key: "military", option: "military", label: "Military", value: "Military" },
  { key: "mining-oil-gas", option: "mining-oil-gas", label: "Mining, Oil, & Gas", value: "Mining, Oil, & Gas" },
  { key: "mobile-computing-software-hardware", option: "mobile-computing-software-hardware", label: "Mobile Computing Software / Hardware", value: "Mobile Computing Software / Hardware" },
  { key: "motor-vehicle", option: "motor-vehicle", label: "Motor Vehicle", value: "Motor Vehicle" },
  { key: "movies-sound-TV-services", option: "movies-sound-TV-services", label: "Movies, Sound & TV Services", value: "Movies, Sound & TV Services" },
  { key: "nanotechnology-services", option: "nanotechnology-services", label: "Nanotechnology Services", value: "Nanotechnology Services" },
  { key: "natural-gas", option: "natural-gas", label: "Natural Gas", value: "Natural Gas" },
  { key: "non-Profit-services", option: "non-Profit-services", label: "Non-Profit Services", value: "Non-Profit Services" },
  { key: "nursing-services", option: "nursing-services", label: "Nursing Services", value: "Nursing Services" },
  { key: "optometrists", option: "optometrists", label: "Optometrists", value: "Optometrists" },
  { key: "outpatientcare", option: "outpatientcare", label: "Outpatient Care", value: "Outpatient Care" },
  { key: "package-design", option: "package-design", label: "Package Design", value: "Package Design" },
  { key: "performing-arts", option: "performing-arts", label: "Performing Arts", value: "Performing Arts" },
  { key: "personal-care", option: "personal-care", label: "Personal Care", value: "Personal Care" },
  { key: "pet-services", option: "pet-services", label: "Pet Services", value: "Pet Services" },
  { key: "pharmaceutical", option: "pharmaceutical", label: "Pharmaceutical", value: "Pharmaceutical" },
  { key: "philanthropy-services", option: "philanthropy-services", label: "Philanthropy Services", value: "Philanthropy Services" },
  { key: "photography-videography", option: "photography-videography", label: "Photography & Videography", value: "Photography & Videography" },
  { key: "physical-occupational-health", option: "physical-occupational-health", label: "Physical & Occupational Health", value: "Physical & Occupational Health" },
  { key: "political-organizations", option: "political-organizations", label: "Political Organizations", value: "Political Organizations" },
  { key: "printing-services", option: "printing-services", label: "Printing Services", value: "Printing Services" },
  { key: "product-design-UX", option: "product-design-UX", label: "Product Design & UX", value: "Product Design & UX" },
  { key: "PSW-services", option: "PSW-services", label: "PSW Services", value: "PSW Services" },
  { key: "public-assistance-services", option: "public-assistance-services", label: "Public Assistance Services", value: "Public Assistance Services" },
  { key: "public-safety-policies", option: "public-safety-policies", label: "Public Safety & Policies", value: "Public Safety & Policies" },
  { key: "publishing-services", option: "publishing-services", label: "Publishing Services", value: "Publishing Services" },
  { key: "recycling-services", option: "recycling-services", label: "Recycling Services", value: "Recycling Services" },
  { key: "renewable-energy-services", option: "renewable-energy-services", label: "Renewable Energy Services", value: "Renewable Energy Services" },
  { key: "residential-real-estate", option: "residential-real-estate", label: "Residential Real-estate", value: "Residential Real-estate" },
  { key: "restaurant-services", option: "restaurant-services", label: "Restaurant Services", value: "Restaurant Services" },
  { key: "restoration-services", option: "restoration-services", label: "Restoration Services", value: "Restoration Services" },
  { key: "retail-apparel-fashion", option: "retail-apparel-fashion", label: "Retail, Apparel & Fashion", value: "Retail, Apparel & Fashion" },
  { key: "robotics-engineering-services", option: "robotics-engineering-services", label: "Robotics Engineering & Services", value: "Robotics Engineering & Services" },
  { key: "science-shemistry-services", option: "science-shemistry-services", label: "Science & Chemistry Services", value: "Science & Chemistry Services" },
  { key: "social-networking", option: "social-networking", label: "Social Networking", value: "Social Networking" },
  { key: "software-development", option: "software-development", label: "Software Development", value: "Software Development" },
  { key: "software-as-a-Service(SaaS)", option: "software-as-a-Service(SaaS)", label: "Software-as-a-Service (SaaS)", value: "Software-as-a-Service (SaaS)" },
  { key: "solar-electric-sServices", option: "solar-electric-sServices", label: "Solar Electric Services", value: "Solar Electric Services" },
  { key: "space-research", option: "space-research", label: "Space Research", value: "Space Research" },
  { key: "sports-athletics", option: "sports-athletics", label: "Sports & Athletics", value: "Sports & Athletics" },
  { key: "strategic-management", option: "strategic-management", label: "Strategic Management", value: "Strategic Management" },
  { key: "technology-information", option: "technology-information", label: "Technology & Information", value: "Technology & Information" },
  { key: "telecommunications", option: "telecommunications", label: "Telecommunications", value: "Telecommunications" },
  { key: "translation-services", option: "translation-services", label: "Translation Services", value: "Translation Services" },
  { key: "transportation-requipment", option: "transportation-requipment", label: "Transportation Requipment", value: "Transportation Requipment" },
  { key: "transportation-services", option: "transportation-services", label: "Transportation Services", value: "Transportation Services" },
  { key: "travel-services", option: "travel-services", label: "Travel Services", value: "Travel Services" },
  { key: "utilities-administration", option: "utilities-administration", label: "Utilities Administration", value: "Utilities Administration" },
  { key: "venture-capital-services", option: "venture-capital-services", label: "Venture Capital (VC) Services", value: "Venture Capital (VC) Services" },
  { key: "veterinary", option: "veterinary", label: "Veterinary", value: "Veterinary" },
  { key: "warehousing-storage", option: "warehousing-storage", label: "Warehousing & Storage", value: "Warehousing & Storage" },
  { key: "web3-services", option: "web3-services", label: "Web3 Services", value: "Web3 Services" },
  { key: "wellness-fitness-services", option: "wellness-fitness-services", label: "Wellness & Fitness Services", value: "Wellness & Fitness Services" },
  { key: "wholesale-services", option: "wholesale-services", label: "Wholesale Services", value: "Wholesale Services" },
  { key: "wrangling-hunting-fisheries-outdoors", option: "wrangling-hunting-fisheries-outdoors", label: "Wrangling, Hunting, Fisheries & Outdoors", value: "Wrangling, Hunting, Fisheries & Outdoors" },
  { key: "writing-editing", option: "writing-editing", label: "Writing & Editing", value: "Writing & Editing" },
  { key: "zoos-sherlters-animal-services", option: "zoos-sherlters-animal-services", label: "Zoos, Sherlters & Animal Services", value: "Zoos, Sherlters & Animal Services" },
];

export const highestExperience = [
  { tokens: 15, key: "c-level", option: "C-Level", value: "C-Level", label: "C-Level" },
  { tokens: 10, key: "director", option: "Director", value: "Director", label: "Director" },
  { tokens: 5, key: "manager-specialist", option: "Manager & Specialist", value: "Manager & Specialist", label: "Manager & Specialist" }
];

export const parentJobRoles = [
  // { "tokens": 5, "key": "CFO", "option": "CFO", "value": "CFO", "label": "CFO", "child": "cfoIndustry", "skillArray": "ChiefFinancialOfficerSkills", "toolArray": "ChiefFinancialOfficerTools" },
  { "tokens": 5, "key": "chief-human-resources-officer", "option": "chief-human-resources-officer", "value": "Chief Human Resources Officer", "label": "Chief Human Resources Officer", "child": "chroIndustry", "skillArray": "chroSkills", "toolArray": "chroTools" },
  // { "tokens": 5, "key": "CIO", "option": "CIO", "value": "CIO", "label": "CIO", "child": "cioIndustry", "skillArray": "CIOSkills", "toolArray": "CIOTools" },
  // { "tokens": 5, "key": "CMO", "option": "CMO", "value": "CMO", "label": "CMO", "child": "cmoIndustry", "skillArray": "CMOSkills", "toolArray": "CMOTools" },
  { "tokens": 5, "key": "communication-specialist", "option": "communication-specialist", "value": "Communication Specialist", "label": "Communication Specialist", "child": "communicationSpecialistIndustry", "skillArray": "CommunicationSpecialistSkills", "toolArray": "CommunicationSpecialistTools" },
  // { "tokens": 5, "key": "CTO", "option": "CTO", "value": "CTO", "label": "CTO", "child": "ctoIndustry", "skillArray": "CTOSkills", "toolArray": "CTOTools" },
  { "tokens": 5, "key": "marketing-specialist", "option": "marketing-specialist", "value": "Marketing Specialist", "label": "Marketing Specialist", "child": "marketingSpecialistIndustry", "skillArray": "MarketingSpecialistSkills", "toolArray": "MarketingSpecialistTools" },
  { "tokens": 5, "key": "project-manager-(IT)", "option": "project-manager-(IT)", "value": "Project Manager (IT)", "label": "Project Manager (IT)", "child": "", "skillArray": "ProjectManagerITSkills", "toolArray": "ProjectManagerITTools" },
  { "tokens": 5, "key": "sales", "option": "sales", "value": "Sales", "label": "Sales", "child": "salesIndustry", "skillArray": "SalesSkills", "toolArray": "SalesTools" },
  { "tokens": 5, "key": "software-development", "option": "software-development", "value": "Software Development", "label": "Software Development", "child": "softwareDevelopmentIndustry", "skillArray": "softwaredevelopmentskills", "toolArray": "softwaredevelopmenttools" },
  { "tokens": 5, "key": "board-of-directors-corporate", "option": "board-of-directors-(corporate)", "value": "Board of Directors (Corporate)", "label": "Board of Directors (Corporate)", "child": "","skillArray": "CFOSkills", "toolArray": "ChiefFinancialOfficerTools" },
  { "tokens": 5, "key": "co-Founder-advisor", "option": "co-Founder-advisor", "value": "Co-Founder / Advisor", "label": "Co-Founder / Advisor", "child": "cfoIndustry", "skillArray": "COFOUNDERSkills", "toolArray": "COFOUNDERTools" },
  { "tokens": 5, "key": "finance-accounting", "option": "finance-(accounting)", "value": "Finance (Accounting)", "label": "Finance (Accounting)", "child": "financeAccountingIndustry", "skillArray": "FINANCEACCOUNTINGSkills", "toolArray": "FINANCEACCOUNTINGTools" },
  { "tokens": 5, "key": "finance-Analyst", "option": "finance-(Analyst)", "value": "Finance (Analyst)", "label": "Finance (Analyst)", "child": "financialAnalystIndustry", "skillArray": "FINANCIALANALYSTSkills", "toolArray": "FINANCIALANALYSTTools" },
  { "tokens": 5, "key": "finance-bookkeeper", "option": "finance-(bookkeeper)", "value": "Finance (Bookkeeper)", "label": "Finance (Bookkeeper)", "child": "financialBookkeeperIndustry", "skillArray": "FinancialBookkeeperSkills", "toolArray": "FinancialBookkeeperTools" },
  { "tokens": 5, "key": "finance-general", "option": "finance-(general)", "value": "Finance (General)", "label": "Finance (General)", "child": "financialGeneralIndustry", "skillArray": "FinanceGeneralSkills", "toolArray": "FinanceGeneralTools" },
  { "tokens": 5, "key": "finance-controller", "option": "finance-(controller)", "value": "Finance (Controller)", "label": "Finance (Controller)", "child": "", "skillArray": "FinancialControllerSkills", "toolArray": "FinancialControllerTools" },
  { "tokens": 5, "key": "sales-account-executive", "option": "sales-(account-executive)", "value": "Sales (Account Executive)", "label": "Sales (Account Executive)", "child": "salesAEIndustry", "skillArray": "salesaeskills", "toolArray": "salesaetools" },
  { "tokens": 5, "key": "sales-business-development", "option": "sales-(business-development)", "value": "Sales (Business Development)", "label": "Sales (Business Development)", "child": "salesBDIndustry", "skillArray": "salesbdskills", "toolArray": "salesbdtools" },
  { "tokens": 5, "key": "sales-sales-engineer", "option": "sales-(sales-engineer)", "value": "Sales (Sales Engineer)", "label": "Sales (Sales Engineer)", "child": "salesEngineerIndustry", "skillArray": "salesengineerskills", "toolArray": "salesengineertools" },
  { "tokens": 5, "key": "software-design-ux", "option": "software-(design-ux)", "value": "Software (Design / UX)", "label": "Software (Design / UX)", "child": "softwareDesignIndustry", "skillArray": "softwaredesignuiuxskills", "toolArray": "softwaredesignuiuxtools" },
  { "tokens": 5, "key": "software-engineering", "option": "software-(engineering)", "value": "Software (Engineering)", "label": "Software (Engineering)", "child": "softwareEngineeringIndustry", "skillArray": "softwareengineeringskills", "toolArray": "softwareengineeringtools" },
  { "tokens": 5, "key": "software-product", "option": "software-(product)", "value": "Software (Product)", "label": "Software (Product)", "child": "softwareProductIndustry", "skillArray": "softwareproductskills", "toolArray": "softwareproducttools" },
  { "tokens": 5, "key": "software-qa", "option": "software-(qa)", "value": "Software (QA)", "label": "Software (QA)", "child": "softwareQAIndustry", "skillArray": "softwareqaskills", "toolArray": "softwareqatools" },
  { "tokens": 5, "key": "Finance-( Director)", "option": "Finance-( Director)", "value": "Finance ( Director)", "label": "Finance ( Director)", "child": "", "skillArray": "FINANCEDIRECTORSkills", "toolArray": "FINANCEDIRECTORTools" },
  { "tokens": 5, "key": "Social-Media-Specialist", "option": "Social-Media-Specialist", "value": "Social Media Specialist", "label": "Social Media Specialist", "child": "socialMediaSpecialistIndustry", "skillArray": "socialmediaspecialistskills", "toolArray": "socialmediaspecialisttools" },
  { "tokens": 5, "key": "Chief-Executive-Officer", "option": "Chief-Executive-Officer", "value": "Chief Executive Officer", "label": "Chief Executive Officer", "child": "ceoIndustry", "skillArray": "ceoskills", "toolArray": "ceotools" },
  { "tokens": 5, "key": "Chief-Operating-Officer", "option": "Chief-Operating-Officer", "value": "Chief Operating Officer", "label": "Chief Operating Officer", "child": "cooIndustry", "skillArray": "COOSkills", "toolArray": "COOTools" },
  { "tokens": 5, "key": "Chief-Financial-Officer", "option": "Chief-Financial-Officer", "value": "Chief Financial Officer", "label": "Chief Financial Officer", "child": "cfoIndustry", "skillArray": "CFOSkills", "toolArray": "ChiefFinancialOfficerTools" },
  { "tokens": 5, "key": "Chief-Marketing-Officer", "option": "Chief-Marketing-Officer", "value": " Chief Marketing Officer", "label": " Chief Marketing Officer", "child": "cmoIndustry", "skillArray": "CMOSkills", "toolArray": "CMOTools" },
  { "tokens": 5, "key": "Chief-Technology-Officer", "option": "Chief-Technology-Officer", "value": "Chief Technology Officer", "label": "Chief Technology Officer", "child": "ctoIndustry", "skillArray": "CTOSkills", "toolArray": "CTOTools" },
  { "tokens": 5, "key": "Chief-information-Officer", "option": "Chief-information-Officer", "value": "Chief Information Officer", "label": "Chief Information Officer", "child": "cioIndustry", "skillArray": "CIOSkills", "toolArray": "CIOTools" },
  { "tokens": 5, "key": "Chief-Legal-Officer", "option": "Chief-Legal-Officer", "value": "Chief Legal Officer", "label": "Chief Legal Officer", "child": "cloIndustry", "skillArray": "CLOSkills", "toolArray": "CLOTools" },
  { "tokens": 5, "key": "Chief-Strategy-Officer", "option": "Chief-Strategy-Officer", "value": "Chief Strategy Officer", "label": "Chief Strategy Officer", "child": "csoIndustry", "skillArray": "CSTOSkills", "toolArray": "CSTOTools" },
  { "tokens": 5, "key": "Chief-Sales-Officer", "option": "Chief-Sales-Officer", "value": "Chief Sales Officer", "label": "Chief Sales Officer", "child": "csoIndustry", "skillArray": "CSOSkills", "toolArray": "CSOTools" },
  { "tokens": 5, "key": "VP-of-Finance", "option": "VP-of-Finance", "value": "VP of Finance", "label": "VP of Finance", "child": "vicePresidentFinanceIndustry", "skillArray": "vpoffinanceskills", "toolArray": "vpoffinancetools" },
  { "tokens": 5, "key": "Marketing", "option": "Marketing", "value": "Marketing", "label": "Marketing", "child": "marketingIndustry", "skillArray": "MarketingSkills", "toolArray": "MarketingTools" },
  { "tokens": 5, "key": "VP-of-Technology", "option": "VP-of-Technology", "value": "VP of Technology", "label": "VP of Technology", "child": "vicePresidentTechnologyIndustry", "skillArray": "vpoftechnologyskills", "toolArray": "vpoftechnologytools" },
  { "tokens": 5, "key": "VP-of-Legal", "option": "VP-of-Legal", "value": "VP of Legal", "label": "VP of Legal", "child": "vicePresidentLegalIndustry", "skillArray": "vpoflegalskills", "toolArray": "vpoflegaltools" },
  { "tokens": 5, "key": "VP-of-Sales", "option": "VP-of-Sales", "value": "VP of Sales", "label": "VP of Sales", "child": "vicePresidentSalesIndustry", "skillArray": "vpofsalesskills", "toolArray": "vpofsalestools" },
  { "tokens": 5, "key": "VP-of-Operations", "option": "VP-of-Operations", "value": "VP of Operations", "label": "VP of Operations", "child": "vicePresidentOperationsIndustry", "skillArray": "vpofoperationsskills", "toolArray": "vpofoperationstools" },
  { "tokens": 5, "key": "Director-Operations", "option": "Director-Operations", "value": "Director Operations", "label": "Director Operations", "child": "DirectorOfOperationsIndustry", "skillArray": "DIRECTOROFOPERATIONSSkills", "toolArray": "DIRECTOROFOPERATIONSTools" },
  { "tokens": 5, "key": "Operations-Manager", "option": "Operations-Manager", "value": "Operations Manager", "label": "Operations Manager", "child": "", "skillArray": "CFOSkills", "toolArray": "ChiefFinancialOfficerTools" },
  { "tokens": 5, "key": "Procurement-Specialist", "option": "Procurement-Specialist", "value": "Procurement Specialist", "label": "Procurement Specialist", "child": "procurementSpecialistIndustry", "skillArray": "ProcurementSpecialistSkills", "toolArray": "ProcurementSpecialistTools" }
];


export const cioIndustry = [
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen" },
  { "key": "cybersecurity-and-risk-management", "option": "cybersecurity-and-risk-management", "label": "Cybersecurity/Risk Management", "value": "Cybersecurity/Risk Management" },
  { "key": "data-governance", "option": "data-governance", "label": "Data Governance", "value": "Data Governance" },
  { "key": "digital-transformation", "option": "digital-transformation", "label": "Digital Transformation", "value": "Digital Transformation" },
  { "key": "enterprise-architecture", "option": "enterprise-architecture", "label": "Enterprise Architecture", "value": "Enterprise Architecture" },
  { "key": "infrastructure-management", "option": "infrastructure-management", "label": "Infrastructure Management", "value": "Infrastructure Management" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation" },
  { "key": "it-governance", "option": "it-governance", "label": "IT Governance", "value": "IT Governance" },
  { "key": "it-strategy-and-planning", "option": "it-strategy-and-planning", "label": "IT Strategy and Planning", "value": "IT Strategy and Planning" },
  { "key": "software-development-lifecycle", "option": "software-development-lifecycle", "label": "Software Development Lifecycle", "value": "Software Development Lifecycle" },
  { "key": "technology-innovation", "option": "technology-innovation", "label": "Technology Innovation", "value": "Technology Innovation" },
  { "key": "vendor-management", "option": "vendor-management", "label": "Vendor Management", "value": "Vendor Management" }
];
export const cloIndustry = [{ "key": "contract-management", "option": "contract-management", "label": "Contract Management", "value": "Contract Management" }, { "key": "risk-assessment", "option": "risk-assessment", "label": "Risk Assessment", "value": "Risk Assessment" }, { "key": "legal-advisory", "option": "legal-advisory", "label": "Legal Advisory", "value": "Legal Advisory" }, { "key": "corporate-law", "option": "corporate-law", "label": "Corporate Law", "value": "Corporate Law" }, { "key": "regulatory-compliance", "option": "regulatory-compliance", "label": "Regulatory Compliance", "value": "Regulatory Compliance" }, { "key": "corporate-governance", "option": "corporate-governance", "label": "Corporate Governance", "value": "Corporate Governance" }, { "key": "intellectual-property", "option": "intellectual-property", "label": "Intellectual Property", "value": "Intellectual Property" }, { "key": "mergers-and-acquisitions-policy-development", "option": "mergers-and-acquisitions-policy-development", "label": "Mergers and Acquisitions Policy Development", "value": "Mergers and Acquisitions Policy Development" }, { "key": "litigation-management", "option": "litigation-management", "label": "Litigation Management", "value": "Litigation Management" }];
export const cmoIndustry = [
  { "key": "brand-strategy", "option": "brand-strategy", "label": "Brand Strategy", "value": "Brand Strategy" },
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen" },
  { "key": "digital-marketing-strategy", "option": "digital-marketing-strategy", "label": "Digital Marketing Strategy", "value": "Digital Marketing Strategy" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation" },
  { "key": "marketing-strategy", "option": "marketing-strategy", "label": "Marketing Strategy", "value": "Marketing Strategy" },
  { "key": "technical-proficiency", "option": "technical-proficiency", "label": "Technical Proficiency", "value": "Technical Proficiency" },
  { "key": "team-leadership", "option": "team-leadership", "label": "Team Leadership", "value": "Team Leadership" }
];

export const cooIndustry = [
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen" },
  { "key": "communication-skills", "option": "communication-skills", "label": "Communication Skills", "value": "Communication Skills" },
  { "key": "financial-acumen", "option": "financial-acumen", "label": "Financial Acumen", "value": "Financial Acumen" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation" },
  { "key": "operational-excellence", "option": "operational-excellence", "label": "Operational Excellence", "value": "Operational Excellence" },
  { "key": "operations-strategy", "option": "operations-strategy", "label": "Operations Strategy", "value": "Operations Strategy" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
  { "key": "team-leadership", "option": "team-leadership", "label": "Team Leadership", "value": "Team Leadership" },
  { "key": "technological-proficiency", "option": "technological-proficiency", "label": "Technological Proficiency", "value": "Technological Proficiency" }
];

export const csoIndustry = [
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen" },
  { "key": "communication-skills", "option": "communication-skills", "label": "Communication Skills", "value": "Communication Skills" },
  { "key": "customer-experience", "option": "customer-experience", "label": "Customer Experience", "value": "Customer Experience" },
  { "key": "data-driven-decision-making", "option": "data-driven-decision-making", "label": "Data-Driven Decision Making", "value": "Data-Driven Decision Making" },
  { "key": "digital-sales-strategy", "option": "digital-sales-strategy", "label": "Digital Sales Strategy", "value": "Digital Sales Strategy" },
  { "key": "financial-acumen", "option": "financial-acumen", "label": "Financial Acumen", "value": "Financial Acumen" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation" },
  { "key": "sales-strategy", "option": "sales-strategy", "label": "Sales Strategy", "value": "Sales Strategy" },
  { "key": "team-leadership", "option": "team-leadership", "label": "Team Leadership", "value": "Team Leadership" }
];

export const chroIndustry = [
  { "key": "governance", "option": "governance", "label": "Governance", "value": "Governance" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership" }
]

export const cstoIndustry = [
  { "key": "business-strategy-development", "option": "business-strategy-development", "label": "Business Strategy Development", "value": "Business Strategy Development" },
  { "key": "financial-acumen", "option": "financial-acumen", "label": "Financial Acumen", "value": "Financial Acumen" },
  { "key": "governance", "option": "governance", "label": "Governance", "value": "Governance" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation" },
  { "key": "risk-management", "option": "risk-management", "label": "Risk Management", "value": "Risk Management" },
  { "key": "team-leadership", "option": "team-leadership", "label": "Team Leadership", "value": "Team Leadership" }
];

export const ctoIndustry = [
  { "key": "business-acumen-cto", "option": "business-acumen-cto", "label": "Business Acumen", "value": "Business Acumen" },
  { "key": "communication-cto", "option": "communication-cto", "label": "Communication", "value": "Communication" },
  { "key": "customer-focus-cto", "option": "customer-focus-cto", "label": "Customer Focus", "value": "Customer Focus" },
  { "key": "financial-acumen-cto", "option": "financial-acumen-cto", "label": "Financial Acumen", "value": "Financial Acumen" },
  { "key": "innovation-cto", "option": "innovation-cto", "label": "Innovation", "value": "Innovation" },
  { "key": "it-operations-cto", "option": "it-operations-cto", "label": "IT Operations", "value": "IT Operations" },
  { "key": "project-management-cto", "option": "project-management-cto", "label": "Project Management", "value": "Project Management" },
  { "key": "technical-expertise-cto", "option": "technical-expertise-cto", "label": "Technical Expertise", "value": "Technical Expertise" },
  { "key": "technology-strategy-cto", "option": "technology-strategy-cto", "label": "Technology Strategy", "value": "Technology Strategy" }
];

export const coFounderIndustry = [
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen" },
  { "key": "financial-acumen", "option": "financial-acumen", "label": "Financial Acumen", "value": "Financial Acumen" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation" },
  { "key": "operational-excellence", "option": "operational-excellence", "label": "Operational Excellence", "value": "Operational Excellence" },
  { "key": "operational-oversight", "option": "operational-oversight", "label": "Operational Oversight", "value": "Operational Oversight" },
  { "key": "product-strategy", "option": "product-strategy", "label": "Product Strategy", "value": "Product Strategy" },
  { "key": "regulatory-compliance", "option": "regulatory-compliance", "label": "Regulatory & Compliance", "value": "Regulatory & Compliance" },
  { "key": "sales-and-marketing", "option": "sales-and-marketing", "label": "Sales and Marketing", "value": "Sales and Marketing" },
  { "key": "team-leadership", "option": "team-leadership", "label": "Team Leadership", "value": "Team Leadership" },
  { "key": "vision", "option": "vision", "label": "Vision", "value": "Vision" }
]


export const communicationSpecialistIndustry = [
  { "key": "communication-strategy", "option": "communication-strategy", "label": "Communication Strategy", "value": "Communication Strategy" },
  { "key": "crisis-communication", "option": "crisis-communication", "label": "Crisis Communication", "value": "Crisis Communication" },
  { "key": "digital-and-social-media", "option": "digital-and-social-media", "label": "Digital and Social Media", "value": "Digital and Social Media" },
  { "key": "market-research", "option": "market-research", "label": "Market Research", "value": "Market Research" },
  { "key": "media-relations", "option": "media-relations", "label": "Media Relations", "value": "Media Relations" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
  { "key": "relationship-building", "option": "relationship-building", "label": "Relationship Building", "value": "Relationship Building" },
  { "key": "team-coordination", "option": "team-coordination", "label": "Team Coordination", "value": "Team Coordination" }
]
export const DirectorOfOperationsIndustry = [
  { "key": "budget-management", "option": "budget-management", "label": "Budget Management", "value": "Budget Management" },
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen" },
  { "key": "customer-focus", "option": "customer-focus", "label": "Customer Focus", "value": "Customer Focus" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation" },
  { "key": "operational-technologies", "option": "operational-technologies", "label": "Operational Technologies", "value": "Operational Technologies" },
  { "key": "process-improvement", "option": "process-improvement", "label": "Process Improvement", "value": "Process Improvement" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
  { "key": "quality-control", "option": "quality-control", "label": "Quality Control", "value": "Quality Control" },
  { "key": "safety-standards", "option": "safety-standards", "label": "Safety Standards", "value": "Safety Standards" },
  { "key": "team-leadership", "option": "team-leadership", "label": "Team Leadership", "value": "Team Leadership" }
]

export const financeAccountingIndustry = [
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen" },
  { "key": "change-management", "option": "change-management", "label": "Change Management", "value": "Change Management" },
  { "key": "communication", "option": "communication", "label": "Communication", "value": "Communication" },
  { "key": "corporate-governance", "option": "corporate-governance", "label": "Corporate Governance", "value": "Corporate Governance" },
  { "key": "financial-reporting-and-compliance", "option": "financial-reporting-and-compliance", "label": "Financial Reporting and Compliance", "value": "Financial Reporting and Compliance" },
  { "key": "financial-management", "option": "financial-management", "label": "Financial Management", "value": "Financial Management" },
  { "key": "financial-planning-and-analysis", "option": "financial-planning-and-analysis", "label": "Financial Planning and Analysis", "value": "Financial Planning and Analysis" },
  { "key": "financial-systems", "option": "financial-systems", "label": "Financial Systems", "value": "Financial Systems" },
  { "key": "risk-management", "option": "risk-management", "label": "Risk Management", "value": "Risk Management" },
  { "key": "team-leadership", "option": "team-leadership", "label": "Team Leadership", "value": "Team Leadership" }
]

export const financialAnalystIndustry = [
  { "key": "financial-modeling", "option": "financial-modeling", "label": "Financial Modeling", "value": "Financial Modeling" },
  { "key": "financial-software", "option": "financial-software", "label": "Financial Software", "value": "Financial Software" },
  { "key": "gaap", "option": "gaap", "label": "GAAP", "value": "GAAP" },
  { "key": "ifrs", "option": "ifrs", "label": "IFRS", "value": "IFRS" },
  { "key": "internal-controls", "option": "internal-controls", "label": "Internal Controls", "value": "Internal Controls" },
  { "key": "reporting", "option": "reporting", "label": "Reporting", "value": "Reporting" },
  { "key": "risk-management", "option": "risk-management", "label": "Risk Management", "value": "Risk Management" },
  { "key": "variance-analysis", "option": "variance-analysis", "label": "Variance Analysis", "value": "Variance Analysis" }
]

export const financialBookkeeperIndustry = [
  { "key": "accounting-principles", "option": "accounting-principles", "label": "Accounting Principles", "value": "Accounting Principles" },
  { "key": "accounting-software", "option": "accounting-software", "label": "Accounting Software", "value": "Accounting Software" },
  { "key": "audit-support", "option": "audit-support", "label": "Audit Support", "value": "Audit Support" },
  { "key": "customer-focus", "option": "customer-focus", "label": "Customer Focus", "value": "Customer Focus" },
  { "key": "financial-record-keeping", "option": "financial-record-keeping", "label": "Financial Record-Keeping", "value": "Financial Record-Keeping" },
  { "key": "financial-statements", "option": "financial-statements", "label": "Financial Statements", "value": "Financial Statements" },
  { "key": "reporting", "option": "reporting", "label": "Reporting", "value": "Reporting" }
]
export const financialContollerIndustry = [
  { "key": "accounting-principles", "option": "accounting-principles", "label": "Accounting Principles", "value": "Accounting Principles" },
  { "key": "accounting-software", "option": "accounting-software", "label": "Accounting Software", "value": "Accounting Software" },
  { "key": "budgeting-and-forecasting", "option": "budgeting-and-forecasting", "label": "Budgeting and Forecasting", "value": "Budgeting and Forecasting" },
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen" },
  { "key": "cash-flow-management", "option": "cash-flow-management", "label": "Cash Flow Management", "value": "Cash Flow Management" },
  { "key": "financial-analysis", "option": "financial-analysis", "label": "Financial Analysis", "value": "Financial Analysis" },
  { "key": "financial-reporting", "option": "financial-reporting", "label": "Financial Reporting", "value": "Financial Reporting" },
  { "key": "internal-controls", "option": "internal-controls", "label": "Internal Controls", "value": "Internal Controls" },
  { "key": "team-leadership", "option": "team-leadership", "label": "Team Leadership", "value": "Team Leadership" }
]
export const financialGeneralIndustry = [
  { "key": "accounting-principles", "option": "accounting-principles", "label": "Accounting Principles", "value": "Accounting Principles" },
  { "key": "budgeting", "option": "budgeting", "label": "Budgeting", "value": "Budgeting" },
  { "key": "cost-management", "option": "cost-management", "label": "Cost Management", "value": "Cost Management" },
  { "key": "data-analytics", "option": "data-analytics", "label": "Data Analytics", "value": "Data Analytics" },
  { "key": "financial-modeling", "option": "financial-modeling", "label": "Financial Modeling", "value": "Financial Modeling" },
  { "key": "financial-reporting", "option": "financial-reporting", "label": "Financial Reporting", "value": "Financial Reporting" },
  { "key": "financial-software", "option": "financial-software", "label": "Financial Software", "value": "Financial Software" },
  { "key": "forecasting", "option": "forecasting", "label": "Forecasting", "value": "Forecasting" },
  { "key": "internal-controls", "option": "internal-controls", "label": "Internal Controls", "value": "Internal Controls" },
  { "key": "risk-assessment", "option": "risk-assessment", "label": "Risk Assessment", "value": "Risk Assessment" },
  { "key": "tax", "option": "tax", "label": "Tax", "value": "Tax" }
]
export const marketingIndustry = [
  { "key": "branding", "option": "branding", "label": "Branding", "value": "Branding" },
  { "key": "campaign-management", "option": "campaign-management", "label": "Campaign Management", "value": "Campaign Management" },
  { "key": "content-strategy", "option": "content-strategy", "label": "Content Strategy", "value": "Content Strategy" },
  { "key": "crm-systems", "option": "crm-systems", "label": "CRM Systems", "value": "CRM Systems" },
  { "key": "data-analysis", "option": "data-analysis", "label": "Data Analysis", "value": "Data Analysis" },
  { "key": "digital-marketing", "option": "digital-marketing", "label": "Digital Marketing", "value": "Digital Marketing" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation" },
  { "key": "market-research", "option": "market-research", "label": "Market Research", "value": "Market Research" },
  { "key": "marketing-automation", "option": "marketing-automation", "label": "Marketing Automation", "value": "Marketing Automation" },
  { "key": "marketing-strategy", "option": "marketing-strategy", "label": "Marketing Strategy", "value": "Marketing Strategy" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
  { "key": "stakeholder-engagement", "option": "stakeholder-engagement", "label": "Stakeholder Engagement", "value": "Stakeholder Engagement" }
]
export const marketingSpecialistIndustry = [
  { "key": "brand-positioning", "option": "brand-positioning", "label": "Brand Positioning", "value": "Brand Positioning" },
  { "key": "budget-management", "option": "budget-management", "label": "Budget Management", "value": "Budget Management" },
  { "key": "campaign-management", "option": "campaign-management", "label": "Campaign Management", "value": "Campaign Management" },
  { "key": "content-creation", "option": "content-creation", "label": "Content Creation", "value": "Content Creation" },
  { "key": "crm-systems", "option": "crm-systems", "label": "CRM Systems", "value": "CRM Systems" },
  { "key": "digital-marketing", "option": "digital-marketing", "label": "Digital Marketing", "value": "Digital Marketing" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation" },
  { "key": "market-research", "option": "market-research", "label": "Market Research", "value": "Market Research" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" }
]
export const procurementSpecialistIndustry = [
  { "key": "budget-management", "option": "budget-management", "label": "Budget Management", "value": "Budget Management" },
  { "key": "category-management", "option": "category-management", "label": "Category Management", "value": "Category Management" },
  { "key": "cost-management", "option": "cost-management", "label": "Cost Management", "value": "Cost Management" },
  { "key": "financial-acumen", "option": "financial-acumen", "label": "Financial Acumen", "value": "Financial Acumen" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation" },
  { "key": "inventory-management", "option": "inventory-management", "label": "Inventory Management", "value": "Inventory Management" },
  { "key": "negotiation", "option": "negotiation", "label": "Negotiation", "value": "Negotiation" },
  { "key": "problem-solving", "option": "problem-solving", "label": "Problem Solving", "value": "Problem Solving" },
  { "key": "process-optimization", "option": "process-optimization", "label": "Process Optimization", "value": "Process Optimization" },
  { "key": "procurement-software", "option": "procurement-software", "label": "Procurement Software", "value": "Procurement Software" },
  { "key": "strategic-sourcing-and-procurement", "option": "strategic-sourcing-and-procurement", "label": "Strategic Sourcing and Procurement", "value": "Strategic Sourcing and Procurement" },
  { "key": "supplier-coordination", "option": "supplier-coordination", "label": "Supplier Coordination", "value": "Supplier Coordination" }
]
export const projectManagemntIndustry = [
  { "key": "agile-methodologies", "option": "agile-methodologies", "label": "Agile Methodologies", "value": "Agile Methodologies" },
  { "key": "budget-management", "option": "budget-management", "label": "Budget Management", "value": "Budget Management" },
  { "key": "change-management", "option": "change-management", "label": "Change Management", "value": "Change Management" },
  { "key": "critical-thinking", "option": "critical-thinking", "label": "Critical Thinking", "value": "Critical Thinking" },
  { "key": "it-knowledge", "option": "it-knowledge", "label": "IT Knowledge", "value": "IT Knowledge" },
  { "key": "negotiation", "option": "negotiation", "label": "Negotiation", "value": "Negotiation" },
  { "key": "project-planning", "option": "project-planning", "label": "Project Planning", "value": "Project Planning" },
  { "key": "software-development-life-cycle", "option": "software-development-life-cycle", "label": "Software Development Life Cycle", "value": "Software Development Life Cycle" },
  { "key": "stakeholder-communication", "option": "stakeholder-communication", "label": "Stakeholder Communication", "value": "Stakeholder Communication" },
  { "key": "team-leadership", "option": "team-leadership", "label": "Team Leadership", "value": "Team Leadership" },
  { "key": "technical-problem-solving", "option": "technical-problem-solving", "label": "Technical Problem Solving", "value": "Technical Problem Solving" }
];

export const salesIndustry = [
  { "key": "budget-management", "option": "budget-management", "label": "Budget Management", "value": "Budget Management" },
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
  { "key": "customer-engagement", "option": "customer-engagement", "label": "Customer Engagement", "value": "Customer Engagement" },
  { "key": "digital-sales", "option": "digital-sales", "label": "Digital Sales", "value": "Digital Sales" },
  { "key": "market-analysis", "option": "market-analysis", "label": "Market Analysis", "value": "Market Analysis" },
  { "key": "negotiation", "option": "negotiation", "label": "Negotiation", "value": "Negotiation" },
  { "key": "pricing-strategy", "option": "pricing-strategy", "label": "Pricing Strategy", "value": "Pricing Strategy" },
  { "key": "product-knowledge", "option": "product-knowledge", "label": "Product Knowledge", "value": "Product Knowledge" },
  { "key": "prospecting", "option": "prospecting", "label": "Prospecting", "value": "Prospecting" },
  { "key": "sales-planning", "option": "sales-planning", "label": "Sales Planning", "value": "Sales Planning" },
  { "key": "sales-technology", "option": "sales-technology", "label": "Sales Technology", "value": "Sales Technology" },
  { "key": "target-setting", "option": "target-setting", "label": "Target Setting", "value": "Target Setting" }
];

export const salesAEIndustry = [
  { "key": "account-management", "option": "account-management", "label": "Account Management", "value": "Account Management" },
  { "key": "budget-management", "option": "budget-management", "label": "Budget Management", "value": "Budget Management" },
  { "key": "closing-skills", "option": "closing-skills", "label": "Closing Skills", "value": "Closing Skills" },
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
  { "key": "customer-engagement", "option": "customer-engagement", "label": "Customer Engagement", "value": "Customer Engagement" },
  { "key": "market-analysis", "option": "market-analysis", "label": "Market Analysis", "value": "Market Analysis" },
  { "key": "negotiation", "option": "negotiation", "label": "Negotiation", "value": "Negotiation" },
  { "key": "pricing-strategy", "option": "pricing-strategy", "label": "Pricing Strategy", "value": "Pricing Strategy" },
  { "key": "product-knowledge", "option": "product-knowledge", "label": "Product Knowledge", "value": "Product Knowledge" },
  { "key": "prospecting", "option": "prospecting", "label": "Prospecting", "value": "Prospecting" },
  { "key": "sales-technology", "option": "sales-technology", "label": "Sales Technology", "value": "Sales Technology" }
];
export const salesBDIndustry = [
  { "key": "account-management", "option": "account-management", "label": "Account Management", "value": "Account Management" },
  { "key": "budget-management", "option": "budget-management", "label": "Budget Management", "value": "Budget Management" },
  { "key": "closing-skills", "option": "closing-skills", "label": "Closing Skills", "value": "Closing Skills" },
  { "key": "deal-structuring", "option": "deal-structuring", "label": "Deal Structuring", "value": "Deal Structuring" },
  { "key": "digital-sales", "option": "digital-sales", "label": "Digital Sales", "value": "Digital Sales" },
  { "key": "lead-generation", "option": "lead-generation", "label": "Lead Generation", "value": "Lead Generation" },
  { "key": "market-analysis", "option": "market-analysis", "label": "Market Analysis", "value": "Market Analysis" },
  { "key": "market-research", "option": "market-research", "label": "Market Research", "value": "Market Research" },
  { "key": "negotiation", "option": "negotiation", "label": "Negotiation", "value": "Negotiation" },
  { "key": "presentation-skills", "option": "presentation-skills", "label": "Presentation Skills", "value": "Presentation Skills" },
  { "key": "relationship-management", "option": "relationship-management", "label": "Relationship Management", "value": "Relationship Management" },
  { "key": "sales-technology", "option": "sales-technology", "label": "Sales Technology", "value": "Sales Technology" }
];
export const salesEngineerIndustry = [
  { "key": "customer-engagement", "option": "customer-engagement", "label": "Customer Engagement", "value": "Customer Engagement" },
  { "key": "customer-retention", "option": "customer-retention", "label": "Customer Retention", "value": "Customer Retention" },
  { "key": "needs-analysis", "option": "needs-analysis", "label": "Needs Analysis", "value": "Needs Analysis" },
  { "key": "presentation-skills", "option": "presentation-skills", "label": "Presentation Skills", "value": "Presentation Skills" },
  { "key": "product-demonstrations", "option": "product-demonstrations", "label": "Product Demonstrations", "value": "Product Demonstrations" },
  { "key": "product-knowledge", "option": "product-knowledge", "label": "Product Knowledge", "value": "Product Knowledge" },
  { "key": "prospecting", "option": "prospecting", "label": "Prospecting", "value": "Prospecting" },
  { "key": "resource-allocation", "option": "resource-allocation", "label": "Resource Allocation", "value": "Resource Allocation" },
  { "key": "sales-technology", "option": "sales-technology", "label": "Sales Technology", "value": "Sales Technology" },
  { "key": "technical-problem-solving", "option": "technical-problem-solving", "label": "Technical Problem Solving", "value": "Technical Problem Solving" }
];

export const socialMediaSpecialistIndustry = [
  { "key": "advertising", "option": "advertising", "label": "Advertising", "value": "Advertising" },
  { "key": "audience-engagement", "option": "audience-engagement", "label": "Audience Engagement", "value": "Audience Engagement" },
  { "key": "campaign-management", "option": "campaign-management", "label": "Campaign Management", "value": "Campaign Management" },
  { "key": "content-calendar-management", "option": "content-calendar-management", "label": "Content Calendar Management", "value": "Content Calendar Management" },
  { "key": "copywriting", "option": "copywriting", "label": "Copywriting", "value": "Copywriting" },
  { "key": "creativity", "option": "creativity", "label": "Creativity", "value": "Creativity" },
  { "key": "emerging-platforms", "option": "emerging-platforms", "label": "Emerging Platforms", "value": "Emerging Platforms" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation" },
  { "key": "platform-knowledge", "option": "platform-knowledge", "label": "Platform Knowledge", "value": "Platform Knowledge" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
  { "key": "social-media-analytics", "option": "social-media-analytics", "label": "Social Media Analytics", "value": "Social Media Analytics" },
  { "key": "social-media-strategy", "option": "social-media-strategy", "label": "Social Media Strategy", "value": "Social Media Strategy" }
];

export const softwareDesignIndustry = [
  { "key": "design-handoff", "option": "design-handoff", "label": "Design Handoff", "value": "Design Handoff" },
  { "key": "front-end-development", "option": "front-end-development", "label": "Front End Development", "value": "Front End Development" },
  { "key": "information-architecture", "option": "information-architecture", "label": "Information Architecture", "value": "Information Architecture" },
  { "key": "interaction-design", "option": "interaction-design", "label": "Interaction Design", "value": "Interaction Design" },
  { "key": "prototyping-tools", "option": "prototyping-tools", "label": "Prototyping tools", "value": "Prototyping tools" },
  { "key": "stakeholder-communication", "option": "stakeholder-communication", "label": "Stakeholder Communication", "value": "Stakeholder Communication" },
  { "key": "usability-testing", "option": "usability-testing", "label": "Usability Testing", "value": "Usability Testing" },
  { "key": "user-research-and-analysis", "option": "user-research-and-analysis", "label": "User Research and Analysis", "value": "User Research and Analysis" }
];

export const softwareDevelopmentIndustry = [
  { "key": "code-reviews", "option": "code-reviews", "label": "Code Reviews", "value": "Code Reviews" },
  { "key": "debugging", "option": "debugging", "label": "Debugging", "value": "Debugging" },
  { "key": "interaction-design", "option": "interaction-design", "label": "Interaction Design", "value": "Interaction Design" },
  { "key": "performance-optimization", "option": "performance-optimization", "label": "Performance Optimization", "value": "Performance Optimization" },
  { "key": "prototyping-tools", "option": "prototyping-tools", "label": "Prototyping Tools", "value": "Prototyping Tools" },
  { "key": "stakeholder-communication", "option": "stakeholder-communication", "label": "Stakeholder Communication", "value": "Stakeholder Communication" },
  { "key": "usability-testing", "option": "usability-testing", "label": "Usability Testing", "value": "Usability Testing" },
  { "key": "user-research-and-analysis", "option": "user-research-and-analysis", "label": "User Research and Analysis", "value": "User Research and Analysis" },
  { "key": "visual-design", "option": "visual-design", "label": "Visual Design", "value": "Visual Design" }
];
export const softwareEngineeringIndustry = [
  { "key": "agile-methodologies", "option": "agile-methodologies", "label": "Agile Methodologies", "value": "Agile Methodologies" },
  { "key": "api-design", "option": "api-design", "label": "API Design", "value": "API Design" },
  { "key": "database-management", "option": "database-management", "label": "Database Management", "value": "Database Management" },
  { "key": "debugging", "option": "debugging", "label": "Debugging", "value": "Debugging" },
  { "key": "frameworks", "option": "frameworks", "label": "Frameworks", "value": "Frameworks" },
  { "key": "programming-languages", "option": "programming-languages", "label": "Programming Languages", "value": "Programming Languages" },
  { "key": "quality-assurance", "option": "quality-assurance", "label": "Quality Assurance", "value": "Quality Assurance" },
  { "key": "system-design", "option": "system-design", "label": "System Design", "value": "System Design" },
  { "key": "testing", "option": "testing", "label": "Testing", "value": "Testing" },
  { "key": "version-controls", "option": "version-controls", "label": "Version Controls", "value": "Version Controls" }
];
export const softwareProductIndustry = [
  { "key": "a-b-testing", "option": "a-b-testing", "label": "A/B Testing", "value": "A/B Testing" },
  { "key": "api-knowledge", "option": "api-knowledge", "label": "API Knowledge", "value": "API Knowledge" },
  { "key": "budget-management", "option": "budget-management", "label": "Budget Management", "value": "Budget Management" },
  { "key": "go-to-market-planning", "option": "go-to-market-planning", "label": "Go-to-Market Planning", "value": "Go-to-Market Planning" },
  { "key": "market-research", "option": "market-research", "label": "Market Research", "value": "Market Research" },
  { "key": "persona-development", "option": "persona-development", "label": "Persona Development", "value": "Persona Development" },
  { "key": "product-design-and-development", "option": "product-design-and-development", "label": "Product Design & Development", "value": "Product Design & Development" },
  { "key": "project-planning", "option": "project-planning", "label": "Project Planning", "value": "Project Planning" },
  { "key": "prototyping-tools", "option": "prototyping-tools", "label": "Prototyping Tools", "value": "Prototyping Tools" },
  { "key": "roadmap-development", "option": "roadmap-development", "label": "Roadmap Development", "value": "Roadmap Development" },
  { "key": "stakeholder-management", "option": "stakeholder-management", "label": "Stakeholder Management", "value": "Stakeholder Management" },
  { "key": "user-research", "option": "user-research", "label": "User Research", "value": "User Research" }
];
export const softwareQAIndustry = [
  { "key": "agile-methodologies", "option": "agile-methodologies", "label": "Agile Methodologies", "value": "Agile Methodologies" },
  { "key": "automation-tools", "option": "automation-tools", "label": "Automation Tools", "value": "Automation Tools" },
  { "key": "bug-tracking", "option": "bug-tracking", "label": "Bug Tracking", "value": "Bug Tracking" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "quality-metrics", "option": "quality-metrics", "label": "Quality Metrics", "value": "Quality Metrics" },
  { "key": "reporting", "option": "reporting", "label": "Reporting", "value": "Reporting" },
  { "key": "root-cause-analysis", "option": "root-cause-analysis", "label": "Root Cause Analysis", "value": "Root Cause Analysis" },
  { "key": "security-testing", "option": "security-testing", "label": "Security Testing", "value": "Security Testing" },
  { "key": "software-testing", "option": "software-testing", "label": "Software Testing", "value": "Software Testing" },
  { "key": "stakeholder-management", "option": "stakeholder-management", "label": "Stakeholder Management", "value": "Stakeholder Management" },
  { "key": "test-documentation", "option": "test-documentation", "label": "Test Documentation", "value": "Test Documentation" },
  { "key": "test-planning-and-execution", "option": "test-planning-and-execution", "label": "Test Planning and Execution", "value": "Test Planning and Execution" }
];
export const vicePresidentFinanceIndustry = [
  { "key": "auditing", "option": "auditing", "label": "Auditing", "value": "Auditing" },
  { "key": "budgeting-and-forecasting", "option": "budgeting-and-forecasting", "label": "Budgeting and Forecasting", "value": "Budgeting and Forecasting" },
  { "key": "capital-structure-and-financing", "option": "capital-structure-and-financing", "label": "Capital Structure and Financing", "value": "Capital Structure and Financing" },
  { "key": "cash-flow-management", "option": "cash-flow-management", "label": "Cash Flow Management", "value": "Cash Flow Management" },
  { "key": "cost-management", "option": "cost-management", "label": "Cost Management", "value": "Cost Management" },
  { "key": "financial-modeling", "option": "financial-modeling", "label": "Financial Modeling", "value": "Financial Modeling" },
  { "key": "financial-planning-and-analysis", "option": "financial-planning-and-analysis", "label": "Financial Planning and Analysis", "value": "Financial Planning and Analysis" },
  { "key": "internal-controls-and-compliance", "option": "internal-controls-and-compliance", "label": "Internal Controls and Compliance", "value": "Internal Controls and Compliance" },
  { "key": "investment-strategy", "option": "investment-strategy", "label": "Investment Strategy", "value": "Investment Strategy" },
  { "key": "regulatory-compliance", "option": "regulatory-compliance", "label": "Regulatory Compliance", "value": "Regulatory Compliance" },
  { "key": "risk-management", "option": "risk-management", "label": "Risk Management", "value": "Risk Management" },
  { "key": "strategic-planning", "option": "strategic-planning", "label": "Strategic Planning", "value": "Strategic Planning" }
];
export const vicePresidentLegalIndustry = [
  { "key": "contract-management", "option": "contract-management", "label": "Contract Management", "value": "Contract Management" },
  { "key": "corporate-governance", "option": "corporate-governance", "label": "Corporate Governance", "value": "Corporate Governance" },
  { "key": "corporate-law", "option": "corporate-law", "label": "Corporate Law", "value": "Corporate Law" },
  { "key": "intellectual-property", "option": "intellectual-property", "label": "Intellectual Property", "value": "Intellectual Property" },
  { "key": "legal-advisory", "option": "legal-advisory", "label": "Legal Advisory", "value": "Legal Advisory" },
  { "key": "litigation-management", "option": "litigation-management", "label": "Litigation Management", "value": "Litigation Management" },
  { "key": "mergers-and-acquisitions", "option": "mergers-and-acquisitions", "label": "Mergers and Acquisitions", "value": "Mergers and Acquisitions" },
  { "key": "policy-development", "option": "policy-development", "label": "Policy Development", "value": "Policy Development" },
  { "key": "regulatory-compliance", "option": "regulatory-compliance", "label": "Regulatory Compliance", "value": "Regulatory Compliance" },
  { "key": "risk-assessment", "option": "risk-assessment", "label": "Risk Assessment", "value": "Risk Assessment" }
];
export const vicePresidentOperationsIndustry = [
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen" },
  { "key": "communication-skills", "option": "communication-skills", "label": "Communication Skills", "value": "Communication Skills" },
  { "key": "financial-acumen", "option": "financial-acumen", "label": "Financial Acumen", "value": "Financial Acumen" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation" },
  { "key": "operations-strategy", "option": "operations-strategy", "label": "Operations Strategy", "value": "Operations Strategy" },
  { "key": "operational-excellence", "option": "operational-excellence", "label": "Operational Excellence", "value": "Operational Excellence" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
  { "key": "team-leadership", "option": "team-leadership", "label": "Team Leadership", "value": "Team Leadership" },
  { "key": "technological-proficiency", "option": "technological-proficiency", "label": "Technological Proficiency", "value": "Technological Proficiency" }
];
export const vicePresidentSalesIndustry = [
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen" },
  { "key": "communication-skills", "option": "communication-skills", "label": "Communication Skills", "value": "Communication Skills" },
  { "key": "customer-experience", "option": "customer-experience", "label": "Customer Experience", "value": "Customer Experience" },
  { "key": "data-driven-decision-making", "option": "data-driven-decision-making", "label": "Data-Driven Decision Making", "value": "Data-Driven Decision Making" },
  { "key": "digital-skills", "option": "digital-skills", "label": "Digital Skills", "value": "Digital Skills" },
  { "key": "financial-acumen", "option": "financial-acumen", "label": "Financial Acumen", "value": "Financial Acumen" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation" },
  { "key": "sales-execution", "option": "sales-execution", "label": "Sales Execution", "value": "Sales Execution" },
  { "key": "sales-strategy", "option": "sales-strategy", "label": "Sales Strategy", "value": "Sales Strategy" },
  { "key": "team-leadership", "option": "team-leadership", "label": "Team Leadership", "value": "Team Leadership" }
];
export const vicePresidentTechnologyIndustry = [
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen" },
  { "key": "communication", "option": "communication", "label": "Communication", "value": "Communication" },
  { "key": "customer-focus", "option": "customer-focus", "label": "Customer Focus", "value": "Customer Focus" },
  { "key": "financial-acumen", "option": "financial-acumen", "label": "Financial Acumen", "value": "Financial Acumen" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation" },
  { "key": "it-operations", "option": "it-operations", "label": "IT Operations", "value": "IT Operations" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
  { "key": "technical-expertise", "option": "technical-expertise", "label": "Technical Expertise", "value": "Technical Expertise" },
  { "key": "technology-strategy", "option": "technology-strategy", "label": "Technology Strategy", "value": "Technology Strategy" }
];


export const companySize = [
  { option: "10", label: "1 to 10" },
  { option: "20", label: "11 to 20" },
  { option: "30", label: "21 to 30" },
  { option: "40", label: "31 to 40" },
  { option: "50", label: "41 to 50" },
  { option: "51+", label: "51+" },
];

export const availableWorkingHours = [
  { key: 5, value: 5, option: '5', label: "1 to 5 hours" },
  { key: 10, value: 10, option: '10', label: "6 to 10 hours" },
  { key: 15, value: 15, option: '15', label: "11 to 15 hours" },
  { key: 20, value: 20, option: '20', label: "16 to 20 hours" },
  { key: 30, value: 30, option: '30', label: "21 to 30 hours" },
  // { key: 40, value: 40, option: '40', label: "31 hours +" },
];



export const listingFields = [
  {
    key: 'category',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'city-bikes', label: 'City bikes' },
      { option: 'electric-bikes', label: 'Electric bikes' },
      { option: 'mountain-bikes', label: 'Mountain bikes' },
      { option: 'childrens-bikes', label: "Children's bikes" },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: true,
    },
    saveConfig: {
      label: 'Category',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: 'tire',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '29', label: '29' },
      { option: '28', label: '28' },
      { option: '27', label: '27' },
      { option: '26', label: '26' },
      { option: '24', label: '24' },
      { option: '20', label: '20' },
      { option: '18', label: '18' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Tire size',
      group: 'secondary',
    },
    showConfig: {
      label: 'Tire size',
      isDetail: true,
    },
    saveConfig: {
      label: 'Tire size',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a tire size.',
    },
  },
  {
    key: 'brand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'cube', label: 'Cube' },
      { option: 'diamant', label: 'Diamant' },
      { option: 'ghost', label: 'GHOST' },
      { option: 'giant', label: 'Giant' },
      { option: 'kalkhoff', label: 'Kalkhoff' },
      { option: 'kona', label: 'Kona' },
      { option: 'otler', label: 'Otler' },
      { option: 'vermont', label: 'Vermont' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Brand',
      group: 'secondary',
    },
    showConfig: {
      label: 'Brand',
      isDetail: true,
    },
    saveConfig: {
      label: 'Brand',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a brand.',
    },
  },
  {
    key: 'accessories',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'bell', label: 'Bell' },
      { option: 'lights', label: 'Lights' },
      { option: 'lock', label: 'Lock' },
      { option: 'mudguard', label: 'Mudguard' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Accessories',
      searchMode: 'has_all',
      group: 'secondary',
    },
    showConfig: {
      label: 'Accessories',
    },
    saveConfig: {
      label: 'Accessories',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },

  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:     Unique string. This will be saved to listing's public data on
 *                    EditListingWizard.
 * - label            Label for the listing type. Used as microcopy for options to select
 *                    listing type in EditListingWizard.
 * - transactionType  Set of configurations how this listing type will behave when transaction is
 *                    created.
 *   - process          Transaction process.
 *                      The process must match one of the processes that this client app can handle
 *                      (check src/util/transaction.js) and the process must also exists in correct
 *                      marketplace environment.
 *   - alias            Valid alias for the aforementioned process. This will be saved to listing's
 *                      public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType         Unit type is mainly used as pricing unit. This will be saved to
 *                      transaction's protected data.
 *                      Recommendation: don't use same unit types in completely different processes
 *                      ('item' sold should not be priced the same as 'item' booked).
 * - stockType        This is relevant only to listings with product-selling listing type.
 *                    If set to 'oneItem', stock management is not showed and the listing is
 *                    considered unique (stock = 1).
 *                    Possible values: 'oneItem' and 'multipleItems'.
 *                    Default: 'multipleItems'.
 */

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas

export const enforceValidListingType = false;
