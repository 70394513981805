export const ceoskills = [
  { "key": "operational-oversight", "option": "operational-oversight", "label": "Operational Oversight", "value": "Operational Oversight", "tools": "operationalOversightTool" },
  { "key": "stakeholder-management", "option": "stakeholder-management", "label": "Stakeholder Management", "value": "Stakeholder Management", "tools": "stakeholderManagementTool" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation", "tools": "innovationTool" },
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership", "tools": "leadershipTool" },
  { "key": "change-management", "option": "change-management", "label": "Change Management", "value": "Change Management", "tools": "changeManagementTool" },
  { "key": "business-development", "option": "business-development", "label": "Business Development", "value": "Business Development", "tools": "businessDevelopmentTool" },
  { "key": "financial-acumen", "option": "financial-acumen", "label": "Financial Acumen", "value": "Financial Acumen", "tools": "financialAcumenTool" },
  { "key": "corporate-governance", "option": "corporate-governance", "label": "Corporate Governance", "value": "Corporate Governance", "tools": "corporateGovernanceTool" },
  { "key": "risk-management", "option": "risk-management", "label": "Risk Management", "value": "Risk Management", "tools": "riskManagementTool" },
  { "key": "market-analysis", "option": "market-analysis", "label": "Market Analysis", "value": "Market Analysis", "tools": "marketAnalysisTool" },
  { "key": "strategic-planning", "option": "strategic-planning", "label": "Strategic Planning", "value": "Strategic Planning", "tools": "strategicPlanningTool" }
]

export const ChiefExecutiveOfficerSkills = [
  ...ceoskills
]

export const CFOSkills = [
  { "key": "regulatory-compliance", "option": "regulatory-compliance", "label": "Regulatory Compliance", "value": "Regulatory Compliance", "tools": "regulatoryComplianceTool" },
  { "key": "auditing", "option": "auditing", "label": "Auditing", "value": "Auditing", "tools": "auditingTool" },
  { "key": "investment-strategy", "option": "investment-strategy", "label": "Investment Strategy", "value": "Investment Strategy", "tools": "investmentStrategyTool" },
  { "key": "strategic-planning", "option": "strategic-planning", "label": "Strategic Planning", "value": "Strategic Planning", "tools": "strategicPlanningToolCFO" },
  { "key": "budgeting-and-forecasting", "option": "budgeting-and-forecasting", "label": "Budgeting and Forecasting", "value": "Budgeting and Forecasting", "tools": "budgetingAndForecastingTool" },
  { "key": "internal-controls-and-compliance", "option": "internal-controls-and-compliance", "label": "Internal Controls and Compliance", "value": "Internal Controls and Compliance", "tools": "internalControlsAndComplianceTool" },
  { "key": "financial-planning-and-analysis", "option": "financial-planning-and-analysis", "label": "Financial Planning and Analysis", "value": "Financial Planning and Analysis", "tools": "financialPlanningAndAnalysisTool" },
  { "key": "capital-structure-and-financing", "option": "capital-structure-and-financing", "label": "Capital Structure and Financing", "value": "Capital Structure and Financing", "tools": "capitalStructureAndFinancingTool" },
  { "key": "cash-flow-management", "option": "cash-flow-management", "label": "Cash Flow Management", "value": "Cash Flow Management", "tools": "cashFlowManagementTool" },
  { "key": "cost-management", "option": "cost-management", "label": "Cost Management", "value": "Cost Management", "tools": "costManagementTool" },
  { "key": "risk-management", "option": "risk-management", "label": "Risk Management", "value": "Risk Management", "tools": "riskManagementToolCFO" },
  { "key": "financial-modeling", "option": "financial-modeling", "label": "Financial Modeling", "value": "Financial Modeling", "tools": "financialModelingTool" },
  { "key": "financial-reporting", "option": "financial-reporting", "label": "Financial Reporting", "value": "Financial Reporting", "tools": "financialReportingTool" },
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership", "tools": "leadershipToolCFO" }
]
export const ChiefFinancialOfficerSkills = [
  ...CFOSkills
]

export const chroSkills = [
  { "key": "governance", "option": "governance", "label": "Governance", "value": "Governance" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership" }
]




export const CIOSkills = [
  { "key": "enterprise-architecture", "option": "enterprise-architecture", "label": "Enterprise Architecture", "value": "Enterprise Architecture", "tools": "enterpriseArchitectureToolCIO" },
  { "key": "it-strategy-and-planning", "option": "it-strategy-and-planning", "label": "IT Strategy and Planning", "value": "IT Strategy and Planning", "tools": "itStrategyAndPlanningToolCIO" },
  { "key": "digital-transformation", "option": "digital-transformation", "label": "Digital Transformation", "value": "Digital Transformation", "tools": "digitalTransformationToolCIO" },
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen", "tools": "businessAcumenToolCIO" },
  { "key": "cybersecurity-risk-management", "option": "cybersecurity-risk-management", "label": "Cybersecurity/Risk Management", "value": "Cybersecurity/Risk Management", "tools": "cybersecurityRiskManagementToolCIO" },
  { "key": "vendor-management", "option": "vendor-management", "label": "Vendor Management", "value": "Vendor Management", "tools": "vendorManagementToolCIO" },
  { "key": "technology-innovation", "option": "technology-innovation", "label": "Technology Innovation", "value": "Technology Innovation", "tools": "technologyInnovationToolCIO" },
  { "key": "infrastructure-management", "option": "infrastructure-management", "label": "Infrastructure Management", "value": "Infrastructure Management", "tools": "infrastructureManagementToolCIO" },
  { "key": "software-development-lifecycle", "option": "software-development-lifecycle", "label": "Software Development Lifecycle", "value": "Software Development Lifecycle", "tools": "softwareDevelopmentLifecycleToolCIO" },
  { "key": "data-management", "option": "data-management", "label": "Data Management", "value": "Data Management", "tools": "dataManagementToolCIO" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation", "tools": "innovationToolCIO" },
  { "key": "it-governance", "option": "it-governance", "label": "IT Governance", "value": "IT Governance", "tools": "itGovernanceToolCIO" },
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership", "tools": "leadershipToolCIO" }
]

export const ChiefInformationOfficerSkills = [
  ...CIOSkills
]


export const CLOSkills = [
  { "key": "contract-management", "option": "contract-management", "label": "Contract Management", "value": "Contract Management", "tools": "contractManagementToolCLO" },
  { "key": "risk-assessment", "option": "risk-assessment", "label": "Risk Assessment", "value": "Risk Assessment", "tools": "riskAssessmentToolCLO" },
  { "key": "legal-advisory", "option": "legal-advisory", "label": "Legal Advisory", "value": "Legal Advisory", "tools": "legalAvisoryToolCLO" },
  { "key": "corporate-law", "option": "corporate-law", "label": "Corporate Law", "value": "Corporate Law", "tools": "corporateLawToolCLO" },
  { "key": "regulatory-compliance", "option": "regulatory-compliance", "label": "Regulatory Compliance", "value": "Regulatory Compliance", "tools": "regulatoryComplianceToolCLO" },
  { "key": "corporate-governance", "option": "corporate-governance", "label": "Corporate Governance", "value": "Corporate Governance", "tools": "corporateGovernanceToolCLO" },
  { "key": "intellectual-property", "option": "intellectual-property", "label": "Intellectual Property", "value": "Intellectual Property", "tools": "intellectualPropertyToolCLO" },
  { "key": "mergers-and-acquisitions", "option": "mergers-and-acquisitions", "label": "Mergers and Acquisitions", "value": "Mergers and Acquisitions", "tools": "mergersAndAcquisitionsToolCLO" },
  { "key": "policy-development", "option": "policy-development", "label": "Policy Development", "value": "Policy Development", "tools": "policyDevelopmentToolCLO" },
  { "key": "litigation-management", "option": "litigation-management", "label": "Litigation Management", "value": "Litigation Management", "tools": "litigationManagementToolCLO" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy", "tools": "strategyToolCLO" },
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen", "tools": "businessAcumenToolCLO" }
]

export const ChiefLegalOfficerSkills = [
  ...CLOSkills
]



export const CMOSkills = [
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership", "tools": "leadershipToolCMO" },
  { "key": "digital-marketing-strategy", "option": "digital-marketing-strategy", "label": "Digital Marketing Strategy", "value": "Digital Marketing Strategy", "tools": "digitalMarketingStrategyToolCMO" },
  { "key": "brand-strategy", "option": "brand-strategy", "label": "Brand Strategy", "value": "Brand Strategy", "tools": "brandStrategyToolCMO" },
  { "key": "marketing-strategy", "option": "marketing-strategy", "label": "Marketing Strategy", "value": "Marketing Strategy", "tools": "marketingStrategyToolCMO" },
  { "key": "technical-proficiency", "option": "technical-proficiency", "label": "Technical Proficiency", "value": "Technical Proficiency", "tools": "technicalProficiencyToolCMO" },
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen", "tools": "businessAcumenToolCMO" },
  { "key": "budgeting", "option": "budgeting", "label": "Budgeting", "value": "Budgeting", "tools": "budgetingToolCMO" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation", "tools": "innovationToolCMO" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy", "tools": "strategyToolCMO" }
]
export const ChiefMarketingOfficerSkills = [
  ...CMOSkills
]



export const COOSkills = [
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen", "tools": "businessAcumenToolCOO" },
  { "key": "financial-acumen", "option": "financial-acumen", "label": "Financial Acumen", "value": "Financial Acumen", "tools": "financialAcumenToolCOO" },
  { "key": "technological-proficiency", "option": "technological-proficiency", "label": "Technological Proficiency", "value": "Technological Proficiency", "tools": "technologicalProficiencyToolCOO" },
  { "key": "operations-strategy", "option": "operations-strategy", "label": "Operations Strategy", "value": "Operations Strategy", "tools": "operationsStrategyToolCOO" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation", "tools": "innovationToolCOO" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management", "tools": "projectManagementToolCOO" },
  { "key": "operational-excellence", "option": "operational-excellence", "label": "Operational Excellence", "value": "Operational Excellence", "tools": "operationalExcellenceToolCOO" },
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership", "tools": "leadershipToolCOO" },
  { "key": "risk-management", "option": "risk-management", "label": "Risk Management", "value": "Risk Management", "tools": "riskManagementToolCOO" }
]
export const ChiefOperatingOfficerSkills = [
  ...COOSkills
]


export const CSOSkills = [
  { "key": "sales-strategy", "option": "sales-strategy", "label": "Sales Strategy", "value": "Sales Strategy", "tools": "salesStrategyToolCSO" },
  { "key": "budgeting", "option": "budgeting", "label": "Budgeting", "value": "Budgeting", "tools": "budgetingToolCSO" },
  { "key": "sales-execution", "option": "sales-execution", "label": "Sales Execution", "value": "Sales Execution", "tools": "salesExecutionToolCSO" },
  { "key": "digital-sales-strategy", "option": "digital-sales-strategy", "label": "Digital Sales Strategy", "value": "Digital Sales Strategy", "tools": "digitalSalesStrategyToolCSO" },
  { "key": "customer-experience", "option": "customer-experience", "label": "Customer Experience", "value": "Customer Experience", "tools": "customerExperienceToolCSO" },
  { "key": "data-driven-decision-making", "option": "data-driven-decision-making", "label": "Data Driven Decision Making", "value": "Data Driven Decision Making", "tools": "dataDrivenDecisionMakingToolCSO" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation", "tools": "innovationToolCSO" },
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen", "tools": "businessAcumenToolCSO" },
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership", "tools": "leadershipToolCSO" },
  { "key": "risk-management", "option": "risk-management", "label": "Risk Management", "value": "Risk Management", "tools": "riskManagementToolCSO" }
]
export const ChiefSalesOfficerSkills = [
  ...CSOSkills
]



export const CSTOSkills = [
  { "key": "governance", "option": "governance", "label": "Governance", "value": "Governance", "tools": "governanceToolCSTO" },
  { "key": "risk-management", "option": "risk-management", "label": "Risk Management", "value": "Risk Management", "tools": "riskManagementToolCSTO" },
  { "key": "financial-acumen", "option": "financial-acumen", "label": "Financial Acumen", "value": "Financial Acumen", "tools": "financialAcumenToolCSTO" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation", "tools": "innovationToolCSTO" },
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership", "tools": "leadershipToolCSTO" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy", "tools": "strategyToolCSTO" }
]

export const CTOSkills = [
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen", "tools": "businessAcumenToolCTO" },
  { "key": "financial-acumen", "option": "financial-acumen", "label": "Financial Acumen", "value": "Financial Acumen", "tools": "financialAcumenToolCTO" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation", "tools": "innovationToolCTO" },
  { "key": "it-operations", "option": "it-operations", "label": "IT Operations", "value": "IT Operations", "tools": "itOperationsToolCTO" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management", "tools": "projectManagementToolCTO" },
  { "key": "technical-expertise", "option": "technical-expertise", "label": "Technical Expertise", "value": "Technical Expertise", "tools": "technicalExpertiseToolCTO" },
  { "key": "technology-strategy", "option": "technology-strategy", "label": "Technology Strategy", "value": "Technology Strategy", "tools": "technologyStrategyToolCTO" },
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership", "tools": "leadershipToolCTO" }
]


export const COFOUNDERSkills = [
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen", "tools": "businessAcumenToolCofounder" },
  { "key": "financial-acumen", "option": "financial-acumen", "label": "Financial Acumen", "value": "Financial Acumen", "tools": "financialAcumenToolCofounder" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation", "tools": "innovationToolCofounder" },
  { "key": "operational-excellence", "option": "operational-excellence", "label": "Operational Excellence", "value": "Operational Excellence", "tools": "operationalExcellenceToolCofounder" },
  { "key": "product-strategy", "option": "product-strategy", "label": "Product Strategy", "value": "Product Strategy", "tools": "productStrategyToolCofounder" },
  { "key": "regulatory-compliance", "option": "regulatory-compliance", "label": "Regulatory & Compliance", "value": "Regulatory & Compliance", "tools": "regulatoryComplianceToolCofounder" },
  { "key": "sales-and-marketing", "option": "sales-and-marketing", "label": "Sales and Marketing", "value": "Sales and Marketing", "tools": "salesAndMarketingToolCofounder" },
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership", "tools": "leadershipToolCofounder" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy", "tools": "strategyToolCofounder" }
]


export const CommunicationSpecialistSkills = [
  { "key": "communication-strategy", "option": "communication-strategy", "label": "Communication Strategy", "value": "Communication Strategy", "tools": "communicationStrategyToolCommSpecailist" },
  { "key": "crisis-communication", "option": "crisis-communication", "label": "Crisis Communication", "value": "Crisis Communication", "tools": "crisisCommunicationToolCommSpecailist" },
  { "key": "digital-and-social-media", "option": "digital-and-social-media", "label": "Digital and Social Media", "value": "Digital and Social Media", "tools": "digitalAndSocialMediaToolCommSpecailist" },
  { "key": "market-research", "option": "market-research", "label": "Market Research", "value": "Market Research", "tools": "marketResearchToolCommSpecailist" },
  { "key": "media-relations", "option": "media-relations", "label": "Media Relations", "value": "Media Relations", "tools": "mediaRelationsToolCommSpecailist" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management", "tools": "projectManagementToolCommSpecailist" }
]

export const DIRECTOROFOPERATIONSSkills = [
  { "key": "budget-management", "option": "budget-management", "label": "Budget Management", "value": "Budget Management", "tools": "budgetManagementToolDO" },
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen", "tools": "businessAcumenToolDO" },
  { "key": "customer-focus", "option": "customer-focus", "label": "Customer Focus", "value": "Customer Focus", "tools": "customerFocusToolDO" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation", "tools": "innovationToolDO" },
  { "key": "operational-technologies", "option": "operational-technologies", "label": "Operational Technologies", "value": "Operational Technologies", "tools": "operationalTechnologiesToolDO" },
  { "key": "process-improvement", "option": "process-improvement", "label": "Process Improvement", "value": "Process Improvement", "tools": "processImprovementToolDO" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management", "tools": "projectManagementToolDO" },
  { "key": "quality-control", "option": "quality-control", "label": "Quality Control", "value": "Quality Control", "tools": "qualityControlToolDO" },
  { "key": "safety-standards", "option": "safety-standards", "label": "Safety Standards", "value": "Safety Standards", "tools": "safetyStandardToolDO" },
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership", "tools": "leadershipToolDO" }
]
export const DirectorOperationsSkills
  = [
    ...DIRECTOROFOPERATIONSSkills
  ]

export const FINANCEDIRECTORSkills = [
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership", "tools": "leadershipToolFD" },
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen", "tools": "businessAcumenToolFD" },
  { "key": "change-management", "option": "change-management", "label": "Change Management", "value": "Change Management", "tools": "changeManagementToolFD" },
  { "key": "corporate-governance", "option": "corporate-governance", "label": "Corporate Governance", "value": "Corporate Governance", "tools": "corporateGovernanceToolFD" },
  { "key": "financial-reporting-and-compliance", "option": "financial-reporting-and-compliance", "label": "Financial Reporting and Compliance", "value": "Financial Reporting and Compliance", "tools": "financialReportingAndComplianceToolFD" },
  { "key": "financial-management", "option": "financial-management", "label": "Financial Management", "value": "Financial Management", "tools": "financialManagementToolFD" },
  { "key": "financial-planning-and-analysis", "option": "financial-planning-and-analysis", "label": "Financial Planning and Analysis", "value": "Financial Planning and Analysis", "tools": "financialPlanningAndAnalysisToolFD" },
  { "key": "risk-management", "option": "risk-management", "label": "Risk Management", "value": "Risk Management", "tools": "riskManagementToolFD" }
]


export const FINANCEACCOUNTINGSkills = [
  { "key": "accounting-principles", "option": "accounting-principles", "label": "Accounting Principles", "value": "Accounting Principles", "tools": "accountingPrinciplesToolFA" },
  { "key": "budgeting-and-forecasting", "option": "budgeting-and-forecasting", "label": "Budgeting and Forecasting", "value": "Budgeting and Forecasting", "tools": "budgetingAndForecastingToolFA" },
  { "key": "financial-analysis", "option": "financial-analysis", "label": "Financial Analysis", "value": "Financial Analysis", "tools": "financialAnalysisToolFA" },
  { "key": "financial-reporting", "option": "financial-reporting", "label": "Financial Reporting", "value": "Financial Reporting", "tools": "financialReportingToolFA" },
  { "key": "internal-controls", "option": "internal-controls", "label": "Internal Controls", "value": "Internal Controls", "tools": "internalControlsToolFA" },
  { "key": "tax-compliance", "option": "tax-compliance", "label": "Tax Compliance", "value": "Tax Compliance", "tools": "taxComplianceToolFA" }
]


export const FINANCIALANALYSTSkills = [
  { "key": "financial-modeling", "option": "financial-modeling", "label": "Financial Modeling", "value": "Financial Modeling", "tools": "financialModelingToolFAYS" },
  { "key": "gaap", "option": "gaap", "label": "GAAP", "value": "GAAP", "tools": "gaapToolFAYS" },
  { "key": "ifrs", "option": "ifrs", "label": "IFRS", "value": "IFRS", "tools": "ifrsToolFAYS" },
  { "key": "internal-controls", "option": "internal-controls", "label": "Internal Controls", "value": "Internal Controls", "tools": "internalControlToolFAYS" },
  { "key": "reporting", "option": "reporting", "label": "Reporting", "value": "Reporting", "tools": "reportingToolFAYS" },
  { "key": "risk-management", "option": "risk-management", "label": "Risk Management", "value": "Risk Management", "tools": "riskManagementToolFAYS" },
  { "key": "variance-analysis", "option": "variance-analysis", "label": "Variance Analysis", "value": "Variance Analysis", "tools": "varianceAnalysisToolFAYS" }
]

export const FinancialBookkeeperSkills = [
  { "key": "accounting-principles", "option": "accounting-principles", "label": "Accounting Principles", "value": "Accounting Principles", "tools": "accountingPrinciplesToolFB" },
  { "key": "audit-support", "option": "audit-support", "label": "Audit Support", "value": "Audit Support", "tools": "auditSupportToolFB" },
  { "key": "financial-record-keeping", "option": "financial-record-keeping", "label": "Financial Record-Keeping", "value": "Financial Record-Keeping", "tools": "financialRecordKeepingToolFB" },
  { "key": "financial-statements", "option": "financial-statements", "label": "Financial Statements", "value": "Financial Statements", "tools": "financialStatementsToolFB" },
  { "key": "reporting", "option": "reporting", "label": "Reporting", "value": "Reporting", "tools": "reportingToolFB" }
]


export const FinancialControllerSkills = [
  { "key": "accounting-principles", "option": "accounting-principles", "label": "Accounting Principles", "value": "Accounting Principles", "tools": "accountingPrinciplesToolFC" },
  { "key": "accounting-software", "option": "accounting-software", "label": "Accounting Software", "value": "Accounting Software", "tools": "accountingSoftwareToolFC" },
  { "key": "budgeting-and-forecasting", "option": "budgeting-and-forecasting", "label": "Budgeting and Forecasting", "value": "Budgeting and Forecasting", "tools": "budgetingAndForecastingToolFC" },
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen", "tools": "businessAcumenToolFC" },
  { "key": "cash-flow-management", "option": "cash-flow-management", "label": "Cash Flow Management", "value": "Cash Flow Management", "tools": "cashFlowManagementToolFC" },
  { "key": "financial-analysis", "option": "financial-analysis", "label": "Financial Analysis", "value": "Financial Analysis", "tools": "financialAnalysisToolFC" },
  { "key": "financial-reporting", "option": "financial-reporting", "label": "Financial Reporting", "value": "Financial Reporting", "tools": "financialReportingToolFC" },
  { "key": "internal-controls", "option": "internal-controls", "label": "Internal Controls", "value": "Internal Controls", "tools": "internalControlsToolFC" },
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership", "tools": "leadershipToolFC" }
]

export const FinanceGeneralSkills = [
  { "key": "budgeting", "option": "budgeting", "label": "Budgeting", "value": "Budgeting", "tools": "budgetingToolFG" },
  { "key": "accounting-principles", "option": "accounting-principles", "label": "Accounting Principles", "value": "Accounting Principles", "tools": "accountingPrinciplesToolFG" },
  { "key": "cost-management", "option": "cost-management", "label": "Cost Management", "value": "Cost Management", "tools": "costManagementToolFG" },
  { "key": "data-analytics", "option": "data-analytics", "label": "Data Analytics", "value": "Data Analytics", "tools": "dataAnalyticsToolFG" },
  { "key": "financial-modeling", "option": "financial-modeling", "label": "Financial Modeling", "value": "Financial Modeling", "tools": "financialModelingToolFG" },
  { "key": "financial-reporting", "option": "financial-reporting", "label": "Financial Reporting", "value": "Financial Reporting", "tools": "financialReportingToolFG" },
  { "key": "financial-software", "option": "financial-software", "label": "Financial Software", "value": "Financial Software", "tools": "financialSoftwareToolFG" },
  { "key": "forecasting", "option": "forecasting", "label": "Forecasting", "value": "Forecasting", "tools": "forecastingToolFG" },
  { "key": "internal-controls", "option": "internal-controls", "label": "Internal Controls", "value": "Internal Controls", "tools": "internalControlsToolFG" },
  { "key": "risk-assessment", "option": "risk-assessment", "label": "Risk Assessment", "value": "Risk Assessment", "tools": "riskAssessmentToolFG" },
  { "key": "tax", "option": "tax", "label": "Tax", "value": "Tax", "tools": "taxToolFG" }
]

export const MarketingSkills = [
  { "key": "branding", "option": "branding", "label": "Branding", "value": "Branding", "tools": "brandingToolMr" },
  { "key": "campaign-management", "option": "campaign-management", "label": "Campaign Management", "value": "Campaign Management", "tools": "campaignManagementToolMr" },
  { "key": "content-strategy", "option": "content-strategy", "label": "Content Strategy", "value": "Content Strategy", "tools": "contentStrategyToolMr" },
  { "key": "crm-systems", "option": "crm-systems", "label": "CRM Systems", "value": "CRM Systems", "tools": "crmSystemsToolMr" },
  { "key": "data-analysis", "option": "data-analysis", "label": "Data Analysis", "value": "Data Analysis", "tools": "dataAnalysisToolMr" },
  { "key": "digital-marketing", "option": "digital-marketing", "label": "Digital Marketing", "value": "Digital Marketing", "tools": "digitalMarketingToolMr" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation", "tools": "innovationToolMr" },
  { "key": "market-research", "option": "market-research", "label": "Market Research", "value": "Market Research", "tools": "marketResearchToolMr" },
  { "key": "marketing-automation", "option": "marketing-automation", "label": "Marketing Automation", "value": "Marketing Automation", "tools": "marketingAutomationToolMr" },
  { "key": "marketing-strategy", "option": "marketing-strategy", "label": "Marketing Strategy", "value": "Marketing Strategy", "tools": "marketingStrategyToolMr" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management", "tools": "projectManagementToolMr" },
  { "key": "stakeholder-engagement", "option": "stakeholder-engagement", "label": "Stakeholder Engagement", "value": "Stakeholder Engagement", "tools": "stakeholderEngagementToolMr" }
]

export const MarketingSpecialistSkills = [
  { "key": "brand-positioning", "option": "brand-positioning", "label": "Brand Positioning", "value": "Brand Positioning", "tools": "brandPositioningToolMrSp" },
  { "key": "budget-management", "option": "budget-management", "label": "Budget Management", "value": "Budget Management", "tools": "budgetManagementToolMrSp" },
  { "key": "campaign-management", "option": "campaign-management", "label": "Campaign Management", "value": "Campaign Management", "tools": "campaignManagementToolMrSp" },
  { "key": "content-creation", "option": "content-creation", "label": "Content Creation", "value": "Content Creation", "tools": "contentCreationToolMrSp" },
  { "key": "crm-systems", "option": "crm-systems", "label": "CRM Systems", "value": "CRM Systems", "tools": "crmSystemsToolMrSp" },
  { "key": "digital-marketing", "option": "digital-marketing", "label": "Digital Marketing", "value": "Digital Marketing", "tools": "digitalMarketingToolMrSp" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation", "tools": "innovationToolMrSp" },
  { "key": "market-research", "option": "market-research", "label": "Market Research", "value": "Market Research", "tools": "marketResearchToolMrSp" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management", "tools": "projectManagementToolMrSp" }
]

export const ProcurementSpecialistSkills = [
  { "key": "financial-acumen", "option": "financial-acumen", "label": "Financial Acumen", "value": "Financial Acumen", "tools": "financial-acumen-tool" },
  { "key": "budget-management", "option": "budget-management", "label": "Budget Management", "value": "Budget Management", "tools": "budget-management-tool" },
  { "key": "category-management", "option": "category-management", "label": "Category Management", "value": "Category Management", "tools": "category-management-tool" },
  { "key": "cost-management", "option": "cost-management", "label": "Cost Management", "value": "Cost Management", "tools": "cost-management-tool" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation", "tools": "innovation-tool" },
  { "key": "inventory-management", "option": "inventory-management", "label": "Inventory Management", "value": "Inventory Management", "tools": "inventory-management-tool" },
  { "key": "negotiation", "option": "negotiation", "label": "Negotiation", "value": "Negotiation", "tools": "negotiation-tool" },
  { "key": "process-optimization", "option": "process-optimization", "label": "Process Optimization", "value": "Process Optimization", "tools": "process-optimization-tool" },
  { "key": "procurement-software", "option": "procurement-software", "label": "Procurement Software", "value": "Procurement Software", "tools": "procurement-software-tool" },
  { "key": "strategic-sourcing-and-procurement", "option": "strategic-sourcing-and-procurement", "label": "Strategic Sourcing and Procurement", "value": "Strategic Sourcing and Procurement", "tools": "strategic-sourcing-and-procurement-tool" },
  { "key": "supplier-coordination", "option": "supplier-coordination", "label": "Supplier Coordination", "value": "Supplier Coordination", "tools": "supplier-coordination-tool" }
]

export const ProjectManagerITSkills = [
  { "key": "agile-methodologies", "option": "agile-methodologies", "label": "Agile Methodologies", "value": "Agile Methodologies", "tools": "agile-methodologies-tool" },
  { "key": "budget-management", "option": "budget-management", "label": "Budget Management", "value": "Budget Management", "tools": "budget-management-tool" },
  { "key": "change-management", "option": "change-management", "label": "Change Management", "value": "Change Management", "tools": "change-management-tool" },
  { "key": "negotiation", "option": "negotiation", "label": "Negotiation", "value": "Negotiation", "tools": "negotiation-tool" },
  { "key": "project-planning", "option": "project-planning", "label": "Project Planning", "value": "Project Planning", "tools": "project-planning-tool" },
  { "key": "software-development-life-cycle", "option": "software-development-life-cycle", "label": "Software Development Life Cycle", "value": "Software Development Life Cycle", "tools": "software-development-life-cycle-tool" },
  { "key": "stakeholder-communication", "option": "stakeholder-communication", "label": "Stakeholder Communication", "value": "Stakeholder Communication", "tools": "stakeholder-communication-tool" },
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership", "tools": "leadership-tool" },
  { "key": "technical-problem-solving", "option": "technical-problem-solving", "label": "Technical Problem Solving", "value": "Technical Problem Solving", "tools": "technical-problem-solving-tool" }
]

export const SalesSkills = [
  { "key": "budget-management", "option": "budget-management", "label": "Budget Management", "value": "Budget Management", "tools": "budget-management-tool" },
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM", "tools": "crm-tool" },
  { "key": "customer-engagement", "option": "customer-engagement", "label": "Customer Engagement", "value": "Customer Engagement", "tools": "customer-engagement-tool" },
  { "key": "digital-sales", "option": "digital-sales", "label": "Digital Sales", "value": "Digital Sales", "tools": "digital-sales-tool" },
  { "key": "market-analysis", "option": "market-analysis", "label": "Market Analysis", "value": "Market Analysis", "tools": "market-analysis-tool" },
  { "key": "negotiation", "option": "negotiation", "label": "Negotiation", "value": "Negotiation", "tools": "negotiation-tool" },
  { "key": "pricing-strategy", "option": "pricing-strategy", "label": "Pricing Strategy", "value": "Pricing Strategy", "tools": "pricing-strategy-tool" },
  { "key": "product-knowledge", "option": "product-knowledge", "label": "Product Knowledge", "value": "Product Knowledge", "tools": "product-knowledge-tool" },
  { "key": "prospecting", "option": "prospecting", "label": "Prospecting", "value": "Prospecting", "tools": "prospecting-tool" },
  { "key": "sales-planning", "option": "sales-planning", "label": "Sales Planning", "value": "Sales Planning", "tools": "sales-planning-tool" },
  { "key": "sales-technology", "option": "sales-technology", "label": "Sales Technology", "value": "Sales Technology", "tools": "sales-technology-tool" },
  { "key": "target-setting", "option": "target-setting", "label": "Target Setting", "value": "Target Setting", "tools": "target-setting-tool" }
]
export const salesaeskills = [
  { "key": "account-management", "option": "account-management", "label": "Account Management", "value": "Account Management", "tools": "account-management-tool" },
  { "key": "budget-management", "option": "budget-management", "label": "Budget Management", "value": "Budget Management", "tools": "budget-management-tool" },
  { "key": "closing-skills", "option": "closing-skills", "label": "Closing Skills", "value": "Closing Skills", "tools": "closing-skills-tool" },
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM", "tools": "crm-tool" },
  { "key": "customer-engagement", "option": "customer-engagement", "label": "Customer Engagement", "value": "Customer Engagement", "tools": "customer-engagement-tool" },
  { "key": "market-analysis", "option": "market-analysis", "label": "Market Analysis", "value": "Market Analysis", "tools": "market-analysis-tool" },
  { "key": "negotiation", "option": "negotiation", "label": "Negotiation", "value": "Negotiation", "tools": "negotiation-tool" },
  { "key": "pricing-strategy", "option": "pricing-strategy", "label": "Pricing Strategy", "value": "Pricing Strategy", "tools": "pricing-strategy-tool" },
  { "key": "product-knowledge", "option": "product-knowledge", "label": "Product Knowledge", "value": "Product Knowledge", "tools": "product-knowledge-tool" },
  { "key": "prospecting", "option": "prospecting", "label": "Prospecting", "value": "Prospecting", "tools": "prospecting-tool" },
  { "key": "sales-technology", "option": "sales-technology", "label": "Sales Technology", "value": "Sales Technology", "tools": "sales-technology-tool" }
]

export const salesbdskills = [
  { "key": "closing-skills", "option": "closing-skills", "label": "Closing Skills", "value": "Closing Skills", "tools": "closing-skills-tool" },
  { "key": "account-management", "option": "account-management", "label": "Account Management", "value": "Account Management", "tools": "account-management-tool" },
  { "key": "budget-management", "option": "budget-management", "label": "Budget Management", "value": "Budget Management", "tools": "budget-management-tool" },
  { "key": "deal-structuring", "option": "deal-structuring", "label": "Deal Structuring", "value": "Deal Structuring", "tools": "deal-structuring-tool" },
  { "key": "digital-sales", "option": "digital-sales", "label": "Digital Sales", "value": "Digital Sales", "tools": "digital-sales-tool" },
  { "key": "lead-generation", "option": "lead-generation", "label": "Lead Generation", "value": "Lead Generation", "tools": "lead-generation-tool" },
  { "key": "market-analysis", "option": "market-analysis", "label": "Market Analysis", "value": "Market Analysis", "tools": "market-analysis-tool" },
  { "key": "sales-technology", "option": "sales-technology", "label": "Sales Technology", "value": "Sales Technology", "tools": "sales-technology-tool" },
  { "key": "negotiation", "option": "negotiation", "label": "Negotiation", "value": "Negotiation", "tools": "negotiation-tool" }
]

export const salesengineerskills = [
  { "key": "customer-engagement", "option": "customer-engagement", "label": "Customer Engagement", "value": "Customer Engagement", "tools": "customer-engagement-tool" },
  { "key": "customer-retention", "option": "customer-retention", "label": "Customer Retention", "value": "Customer Retention", "tools": "customer-retention-tool" },
  { "key": "needs-analysis", "option": "needs-analysis", "label": "Needs Analysis", "value": "Needs Analysis", "tools": "needs-analysis-tool" },
  { "key": "product-demonstrations", "option": "product-demonstrations", "label": "Product Demonstrations", "value": "Product Demonstrations", "tools": "product-demonstrations-tool" },
  { "key": "product-knowledge", "option": "product-knowledge", "label": "Product Knowledge", "value": "Product Knowledge", "tools": "product-knowledge-tool" },
  { "key": "prospecting", "option": "prospecting", "label": "Prospecting", "value": "Prospecting", "tools": "prospecting-tool" },
  { "key": "resource-allocation", "option": "resource-allocation", "label": "Resource Allocation", "value": "Resource Allocation", "tools": "resource-allocation-tool" },
  { "key": "sales-technology", "option": "sales-technology", "label": "Sales Technology", "value": "Sales Technology", "tools": "sales-technology-tool" },
  { "key": "technical-problem-solving", "option": "technical-problem-solving", "label": "Technical Problem Solving", "value": "Technical Problem Solving", "tools": "technical-problem-solving-tool" }
]



export const socialmediaspecialistskills = [
  { "key": "advertising", "option": "advertising", "label": "Advertising", "value": "Advertising", "tools": "advertisingToolSMS" },
  { "key": "audience-engagement", "option": "audience-engagement", "label": "Audience Engagement", "value": "Audience Engagement", "tools": "audienceEngagementToolSMS" },
  { "key": "campaign-management", "option": "campaign-management", "label": "Campaign Management", "value": "Campaign Management", "tools": "campaignManagementToolSMS" },
  { "key": "content-calendar-management", "option": "content-calendar-management", "label": "Content Calendar Management", "value": "Content Calendar Management", "tools": "contentCalendarManagementToolSMS" },
  { "key": "copywriting", "option": "copywriting", "label": "Copywriting", "value": "Copywriting", "tools": "copywritingToolSMS" },
  { "key": "creativity", "option": "creativity", "label": "Creativity", "value": "Creativity", "tools": "creativityToolSMS" },
  { "key": "emerging-platforms", "option": "emerging-platforms", "label": "Emerging Platforms", "value": "Emerging Platforms", "tools": "emergingPlatformsToolSMS" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation", "tools": "innovationToolSMS" },
  { "key": "platform-knowledge", "option": "platform-knowledge", "label": "Platform Knowledge", "value": "Platform Knowledge", "tools": "platformKnowledgeToolSMS" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management", "tools": "projectManagementToolSMS" },
  { "key": "social-media-analytics", "option": "social-media-analytics", "label": "Social Media Analytics", "value": "Social Media Analytics", "tools": "socialMediaAnalyticsToolSMS" },
  { "key": "social-media-strategy", "option": "social-media-strategy", "label": "Social Media Strategy", "value": "Social Media Strategy", "tools": "socialMediaStrategyToolSMS" }
]

export const advertisingToolSMS = [
  { "key": "Content Writing", "option": "Content Writing", "label": "Content Writing", "value": "Content Writing" },
  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" },
  { "key": "Email Marketing", "option": "Email Marketing", "label": "Email Marketing", "value": "Email Marketing" },
  { "key": "Paid Ads", "option": "Paid Ads", "label": "Paid Ads", "value": "Paid Ads" },
  { "key": "Social Media", "option": "Social Media", "label": "Social Media", "value": "Social Media" },
  { "key": "Paid Ads", "option": "Paid Ads", "label": "Paid Ads", "value": "Paid Ads" }

]
export const audienceEngagementToolSMS = [
  { "key": "Social Media", "option": "Social Media", "label": "Social Media", "value": "Social Media" },
  { "key": "SEO", "option": "SEO", "label": "SEO", "value": "SEO" },
  { "key": "Customer Adoption", "option": "Customer Adoption", "label": "Customer Adoption", "value": "Customer Adoption" }
]
export const campaignManagementToolSMS = [
  { "key": "Social Media", "option": "Social Media", "label": "Social Media", "value": "Social Media" },
  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" }
]

export const contentCalendarManagementToolSMS = [
  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" },
  { "key": "Social Media", "option": "Social Media", "label": "Social Media", "value": "Social Media" }
]
export const copywritingToolSMS = [
  { "key": "Copy writing", "option": "Copy writing", "label": "Copy writing", "value": "Copy writing" }

]
export const creativityToolSMS = [
  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" },
  { "key": "Research", "option": "Research", "label": "Research", "value": "Research" }

]
export const emergingPlatformsToolSMS = [
  { "key": "Strategy", "option": "Strategy", "label": "Strategy", "value": "Strategy" },

  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" },
  { "key": "Social Media", "option": "Social Media", "label": "Social Media", "value": "Social Media" }
]
export const platformKnowledgeToolSMS = [
  { "key": "Social Media", "option": "Social Media", "label": "Social Media", "value": "Social Media" },
  { "key": "Research", "option": "Research", "label": "Research", "value": "Research" }
]
export const projectManagementToolSMS = [
  { "key": "Project Management", "option": "Project Management", "label": "Project Management", "value": "Project Management" }
]

export const socialMediaAnalyticsToolSMS = [
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "Data", "option": "Data", "label": "Data", "value": "Data" }
]

export const socialMediaStrategyToolSMS = [
  { "key": "Strategy", "option": "Strategy", "label": "Strategy", "value": "Strategy" }

]


export const softwaredesignuiuxskills = [
  { "key": "user-research-and-analysis", "option": "user-research-and-analysis", "label": "User Research and Analysis", "value": "User Research and Analysis", "tools": "userResearchAndAnalysisToolSDUS" },
  { "key": "interaction-design", "option": "interaction-design", "label": "Interaction Design", "value": "Interaction Design", "tools": "interactionDesignToolSDUS" },
  { "key": "usability-testing", "option": "usability-testing", "label": "Usability Testing", "value": "Usability Testing", "tools": "usabilityTestingToolSDUS" },
  { "key": "information-architecture", "option": "information-architecture", "label": "Information Architecture", "value": "Information Architecture", "tools": "informationArchitectureToolSDUS" },
  { "key": "stakeholder-communication", "option": "stakeholder-communication", "label": "Stakeholder Communication", "value": "Stakeholder Communication", "tools": "stakeholderCommunicationToolSDUS" },
  { "key": "front-end-development", "option": "front-end-development", "label": "Front End Development", "value": "Front End Development", "tools": "frontEndDevelopmentToolSDUS" },
  { "key": "design-handoff", "option": "design-handoff", "label": "Design Handoff", "value": "Design Handoff", "tools": "designHandoffToolSDUS" },
  { "key": "prototyping-tools", "option": "prototyping-tools", "label": "Prototyping Tools", "value": "Prototyping Tools", "tools": "prototypingToolsToolSDUS" }
]


export const serResearchAndAnalysisToolSDUS = [
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "Research", "option": "Research", "label": "Research", "value": "Research" },
  { "key": "User Testing", "option": "User Testing", "label": "User Testing", "value": "User Testing" },
  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" }
]

export const interactionDesignToolSDUS = [
  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" },
  { "key": "User Testing", "option": "User Testing", "label": "User Testing", "value": "User Testing" },
  { "key": "Customer Adoption", "option": "Customer Adoption", "label": "Customer Adoption", "value": "Customer Adoption" }

]
export const usabilityTestingToolSDUS = [
  { "key": "User Testing", "option": "User Testing", "label": "User Testing", "value": "User Testing" },
  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" }
]

export const informationArchitectureToolSDUS = [
  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" },
  { "key": "Customer Adoption", "option": "Customer Adoption", "label": "Customer Adoption", "value": "Customer Adoption" }
]
export const stakeholderCommunicationToolSDUS = [
  { "key": "Project Management", "option": "Project Management", "label": "Project Management", "value": "Project Management" },
  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" }
]

export const frontEndDevelopmentToolSDUS = [
  { "key": "Frontend Development", "option": "Frontend Development", "label": "Frontend Development", "value": "Frontend Development" },
  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" }
]

export const designHandoffToolSDUS = [
  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" },
  { "key": "Customer Adoption", "option": "Customer Adoption", "label": "Customer Adoption", "value": "Customer Adoption" },
  { "key": "User Testing", "option": "User Testing", "label": "User Testing", "value": "User Testing" },
  { "key": "Project Management", "option": "Project Management", "label": "Project Management", "value": "Project Management" }

]

export const prototypingToolsToolSDUS = [
  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" },
  { "key": "User Testing", "option": "User Testing", "label": "User Testing", "value": "User Testing" }
]


export const softwaredevelopmentskills = [
  { "key": "code-reviews", "option": "code-reviews", "label": "Code Reviews", "value": "Code Reviews", "tools": "codeReviewsToolSDS" },
  { "key": "debugging", "option": "debugging", "label": "Debugging", "value": "Debugging", "tools": "debuggingToolsSDS" },
  { "key": "interaction-design", "option": "interaction-design", "label": "Interaction Design", "value": "Interaction Design", "tools": "interactionDesignToolSDS" },
  { "key": "performance-optimization", "option": "performance-optimization", "label": "Performance Optimization", "value": "Performance Optimization", "tools": "performanceOptimizationToolSDS" },
  { "key": "prototyping-tools", "option": "prototyping-tools", "label": "Prototyping Tools", "value": "Prototyping Tools", "tools": "prototypingToolsToolSDS" },
  { "key": "stakeholder-communication", "option": "stakeholder-communication", "label": "Stakeholder Communication", "value": "Stakeholder Communication", "tools": "stakeholderCommunicationToolSDS" },
  { "key": "usability-testing", "option": "usability-testing", "label": "Usability Testing", "value": "Usability Testing", "tools": "usabilityTestingToolSDS" },
  { "key": "user-research-and-analysis", "option": "user-research-and-analysis", "label": "User Research and Analysis", "value": "User Research and Analysis", "tools": "userResearchAndAnalysisToolSDS" },
  { "key": "visual-design", "option": "visual-design", "label": "Visual Design", "value": "Visual Design", "tools": "visualDesignToolSDS" }
]

export const codeReviewsToolSDS = [

  { "key": "AI Development", "option": "AI Development", "label": "AI Development", "value": "AI Development" },
  { "key": "Project Management", "option": "Project Management", "label": "Project Management", "value": "Project Management" },
  { "key": "Frontend Development", "option": "Frontend Development", "label": "Frontend Development", "value": "Frontend Development" },
  { "key": "Backend Development", "option": "Backend Development", "label": "Backend Development", "value": "Backend Development" },
  { "key": "Database Development", "option": "Database Development", "label": "Database Development", "value": "Database Development" },
  { "key": "DevOps", "option": "DevOps", "label": "DevOps", "value": "DevOps" },
  { "key": "Gaming Development", "option": "Gaming Development", "label": "Gaming Development", "value": "Gaming Development" },
  { "key": "ML Development", "option": "ML Development", "label": "ML Development", "value": "ML Development" },
  { "key": "Mobile App Development", "option": "Mobile App Development", "label": "Mobile App Development", "value": "Mobile App Development" },
  { "key": "Software Development", "option": "Software Development", "label": "Software Development", "value": "Software Development" },
  { "key": "VR Development", "option": "VR Development", "label": "VR Development", "value": "VR Development" }

]
export const debuggingToolsSDS = [
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "Quality Assurance", "option": "Quality Assurance", "label": "Quality Assurance", "value": "Quality Assurance" },
  { "key": "DevOps", "option": "DevOps", "label": "DevOps", "value": "DevOps" }
]

export const interactionDesignToolSDS = [

  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" },
  { "key": "Software Development", "option": "Software Development", "label": "Software Development", "value": "Software Development" },
  { "key": "Web Development", "option": "Web Development", "label": "Web Development", "value": "Web Development" }
]

export const prototypingToolsToolSDS = [
  { "key": "Quality Assurance", "option": "Quality Assurance", "label": "Quality Assurance", "value": "Quality Assurance" },
  { "key": "Project Management", "option": "Project Management", "label": "Project Management", "value": "Project Management" }
]

export const stakeholderCommunicationToolSDS = [
  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" },
  { "key": "User Testing", "option": "User Testing", "label": "User Testing", "value": "User Testing" }
]

export const usabilityTestingToolSDS = [
  { "key": "CRM", "option": "CRM", "label": "CRM", "value": "CRM" },
  { "key": "Project Management", "option": "Project Management", "label": "Project Management", "value": "Project Management" }
]

export const userResearchAndAnalysisToolSDS = [
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "Research", "option": "Research", "label": "Research", "value": "Research" },
  { "key": "User Testing", "option": "User Testing", "label": "User Testing", "value": "User Testing" }
]

export const visualDesignToolSDS = [
  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" }
]

export const softwareengineeringskills = [
  { "key": "agile-methodologies", "option": "agile-methodologies", "label": "Agile Methodologies", "value": "Agile Methodologies", "tools": "agileMethodologiesToolSES" },
  { "key": "api-design", "option": "api-design", "label": "API Design", "value": "API Design", "tools": "apiDesignToolSES" },
  { "key": "database-management", "option": "database-management", "label": "Database Management", "value": "Database Management", "tools": "databaseManagementToolSES" },
  { "key": "debugging", "option": "debugging", "label": "Debugging", "value": "Debugging", "tools": "debuggingToolSES" },
  { "key": "programming-languages", "option": "programming-languages", "label": "Programming Languages", "value": "Programming Languages", "tools": "programmingLanguagesToolSES" },
  { "key": "quality-assurance", "option": "quality-assurance", "label": "Quality Assurance", "value": "Quality Assurance", "tools": "qualityAssuranceToolSES" },
  { "key": "system-design", "option": "system-design", "label": "System Design", "value": "System Design", "tools": "systemDesignToolSES" },
  { "key": "version-controls", "option": "version-controls", "label": "Version Controls", "value": "Version Controls", "tools": "versionControlsToolSES" }
]

export const agileMethodologiesToolSES = [

  { "key": "Software Development", "option": "Software Development", "label": "Software Development", "value": "Software Development" },
  { "key": "Project Management", "option": "Project Management", "label": "Project Management", "value": "Project Management" }
]

export const apiDesignToolSES = [
  { "key": "CRM", "option": "CRM", "label": "CRM", "value": "CRM" },
  { "key": "Database Development", "option": "Database Development", "label": "Database Development", "value": "Database Development" },
  { "key": "Backend Development", "option": "Backend Development", "label": "Backend Development", "value": "Backend Development" }
]

export const databaseManagementToolSES = [
  { "key": "Data", "option": "Data", "label": "Data", "value": "Data" },
  { "key": "Database Development", "option": "Database Development", "label": "Database Development", "value": "Database Development" },
  { "key": "Backend Development", "option": "Backend Development", "label": "Backend Development", "value": "Backend Development" }
]

export const debuggingToolSES = [
  { "key": "DevOps", "option": "DevOps", "label": "DevOps", "value": "DevOps" },
  { "key": "Quality Assurance", "option": "Quality Assurance", "label": "Quality Assurance", "value": "Quality Assurance" }
]

export const programmingLanguagesToolSES = [
  { "key": "Software Development", "option": "Software Development", "label": "Software Development", "value": "Software Development" },
  { "key": "Backend Development", "option": "Backend Development", "label": "Backend Development", "value": "Backend Development" },
  { "key": "Frontend Development", "option": "Frontend Development", "label": "Frontend Development", "value": "Frontend Development" }
]

export const qualityAssuranceToolSES = [
  { "key": "Quality Assurance", "option": "Quality Assurance", "label": "Quality Assurance", "value": "Quality Assurance" },
  { "key": "User Testing", "option": "User Testing", "label": "User Testing", "value": "User Testing" }
]

export const systemDesignToolSES = [
  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" }
]
export const versionControlsToolSES = [

  { "key": "DevOps", "option": "DevOps", "label": "DevOps", "value": "DevOps" },
  { "key": "Software Development", "option": "Software Development", "label": "Software Development", "value": "Software Development" }
]

export const softwareproductskills = [
  { "key": "ab-testing", "option": "ab-testing", "label": "A/B Testing", "value": "A/B Testing", "tools": "abTestingToolSPS" },
  { "key": "api-knowledge", "option": "api-knowledge", "label": "API Knowledge", "value": "API Knowledge", "tools": "apiknowledgeToolSPS" },
  { "key": "budget-management", "option": "budget-management", "label": "Budget Management", "value": "Budget Management", "tools": "budgetManagementToolSPS" },
  { "key": "go-to-market-planning", "option": "go-to-market-planning", "label": "Go-to-Market Planning", "value": "Go-to-Market Planning", "tools": "goToMarketPlanningToolSPS" },
  { "key": "market-research", "option": "market-research", "label": "Market Research", "value": "Market Research", "tools": "marketResearchToolSPS" },
  { "key": "persona-development", "option": "persona-development", "label": "Persona Development", "value": "Persona Development", "tools": "personaDevelopmentToolSPS" },
  { "key": "product-design-development", "option": "product-design-development", "label": "Product Design & Development", "value": "Product Design & Development", "tools": "productDsignDevelopmentToolSPS" },
  { "key": "project-planning", "option": "project-planning", "label": "Project Planning", "value": "Project Planning", "tools": "projectPlanningToolSPS" },
  { "key": "prototyping-tools", "option": "prototyping-tools", "label": "Prototyping Tools", "value": "Prototyping Tools", "tools": "prototypingToolsToolSPS" },
  { "key": "roadmap-development", "option": "roadmap-development", "label": "Roadmap Development", "value": "Roadmap Development", "tools": "roadmapDevelopmentToolSPS" },
  { "key": "stakeholder-management", "option": "stakeholder-management", "label": "Stakeholder Management", "value": "Stakeholder Management", "tools": "stakeholderManagementToolSPS" },
  { "key": "user-research", "option": "user-research", "label": "User Research", "value": "User Research", "tools": "userResearchToolSPS" }
]

export const abTestingToolSPS = [
  { "key": "User Testing", "option": "User Testing", "label": "User Testing", "value": "User Testing" },
  { "key": "Product Management", "option": "Product Management", "label": "Product Management", "value": "Product Management" },
  { "key": "Marketing", "option": "Marketing", "label": "Marketing", "value": "Marketing" },
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" }
]

export const apiknowledgeToolSPS = [
  { "key": "CRM", "option": "CRM", "label": "CRM", "value": "CRM" },
  { "key": "Backend Development", "option": "Backend Development", "label": "Backend Development", "value": "Backend Development" },
  { "key": "DevOps", "option": "DevOps", "label": "DevOps", "value": "DevOps" }
]

export const budgetManagementToolSPS = [
  { "key": "Financials", "option": "Financials", "label": "Financials", "value": "Financials" },
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "Business Planning", "option": "Business Planning", "label": "Business Planning", "value": "Business Planning" }
]
export const goToMarketPlanningToolSPS = [
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "Research", "option": "Research", "label": "Research", "value": "Research" },
  { "key": "Strategy", "option": "Strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "Marketing Strategy", "option": "Marketing Strategy", "label": "Marketing Strategy", "value": "Marketing Strategy" }
]
export const marketResearchToolSPS = [
  { "key": "Research", "option": "Research", "label": "Research", "value": "Research" },
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "Email Marketing", "option": "Email Marketing", "label": "Email Marketing", "value": "Email Marketing" }
]
export const personaDevelopmentToolSPS = [
  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" },
  { "key": "Product Management", "option": "Product Management", "label": "Product Management", "value": "Product Management" },
  { "key": "Research", "option": "Research", "label": "Research", "value": "Research" }
]
export const productDsignDevelopmentToolSPS = [
  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" },
  { "key": "DevOps", "option": "DevOps", "label": "DevOps", "value": "DevOps" },
  { "key": "Software Development", "option": "Software Development", "label": "Software Development", "value": "Software Development" }
]
export const projectPlanningToolSPS = [
  { "key": "Project Management", "option": "Project Management", "label": "Project Management", "value": "Project Management" },
  { "key": "Business Planning", "option": "Business Planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "Product Management", "option": "Product Management", "label": "Product Management", "value": "Product Management" }
]
export const prototypingToolsToolSPS = [
  { "key": "Quality Assurance", "option": "Quality Assurance", "label": "Quality Assurance", "value": "Quality Assurance" },
  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" },
  { "key": "User Testing", "option": "User Testing", "label": "User Testing", "value": "User Testing" }
]
export const roadmapDevelopmentToolSPS = [
  { "key": "Product Management", "option": "Product Management", "label": "Product Management", "value": "Product Management" },
  { "key": "Business Planning", "option": "Business Planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "Strategy", "option": "Strategy", "label": "Strategy", "value": "Strategy" }
]
export const stakeholderManagementToolSPS = [
  { "key": "Product Management", "option": "Product Management", "label": "Product Management", "value": "Product Management" },
  { "key": "Business Planning", "option": "Business Planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "Strategy", "option": "Strategy", "label": "Strategy", "value": "Strategy" }
]


export const softwareqaskills = [
  { "key": "agile-methodologies", "option": "agile-methodologies", "label": "Agile Methodologies", "value": "Agile Methodologies", "tools": "agileMethodologiesToolSQS" },
  { "key": "automation-tools", "option": "automation-tools", "label": "Automation Tools", "value": "Automation Tools", "tools": "automationToolsToolSQS" },
  { "key": "bug-tracking", "option": "bug-tracking", "label": "Bug Tracking", "value": "Bug Tracking", "tools": "bugTrackingToolSQS" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance", "tools": "complianceToolSQS" },
  { "key": "quality-metrics", "option": "quality-metrics", "label": "Quality Metrics", "value": "Quality Metrics", "tools": "qualityMetricsToolSQS" },
  { "key": "reporting", "option": "reporting", "label": "Reporting", "value": "Reporting", "tools": "reportingToolSQS" },
  { "key": "root-cause-analysis", "option": "root-cause-analysis", "label": "Root Cause Analysis", "value": "Root Cause Analysis", "tools": "rootCauseAnalysisToolSQS" },
  { "key": "security-testing", "option": "security-testing", "label": "Security Testing", "value": "Security Testing", "tools": "securityTestingToolSQS" },
  { "key": "software-testing", "option": "software-testing", "label": "Software Testing", "value": "Software Testing", "tools": "softwareTestingToolSQS" },
  { "key": "stakeholder-management", "option": "stakeholder-management", "label": "Stakeholder Management", "value": "Stakeholder Management", "tools": "stakeholderManagementToolSQS" },
  { "key": "test-documentation", "option": "test-documentation", "label": "Test Documentation", "value": "Test Documentation", "tools": "testDocumentationToolSQS" },
  { "key": "test-planning-and-execution", "option": "test-planning-and-execution", "label": "Test Planning and Execution", "value": "Test Planning and Execution", "tools": "testPlanningAndExecutionToolSQS" }
]

export const agileMethodologiesToolSQS = [
  { "key": "Software Development", "option": "Software Development", "label": "Software Development", "value": "Software Development" },
  { "key": "Project Management", "option": "Project Management", "label": "Project Management", "value": "Project Management" }

]

export const automationToolsToolSQS = [
  { "key": "Quality Assurance", "option": "Quality Assurance", "label": "Quality Assurance", "value": "Quality Assurance" },
  { "key": "Project Management", "option": "Project Management", "label": "Project Management", "value": "Project Management" },
  { "key": "Software Development", "option": "Software Development", "label": "Software Development", "value": "Software Development" }

]

export const bugTrackingToolSQS = [
  { "key": "User Testing", "option": "User Testing", "label": "User Testing", "value": "User Testing" },
  { "key": "Project Management", "option": "Project Management", "label": "Project Management", "value": "Project Management" },
  { "key": "Software Development", "option": "Software Development", "label": "Software Development", "value": "Software Development" }
]


export const complianceToolSQS = [
  { "key": "Compliance", "option": "Compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "Project Management", "option": "Project Management", "label": "Project Management", "value": "Project Management" }

]

export const qualityMetricsToolSQS = [
  { "key": "Quality Assurance", "option": "Quality Assurance", "label": "Quality Assurance", "value": "Quality Assurance" },
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "Data", "option": "Data", "label": "Data", "value": "Data" }

]

export const reportingToolSQS = [
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "Quality Assurance", "option": "Quality Assurance", "label": "Quality Assurance", "value": "Quality Assurance" }

]
export const rootCauseAnalysisToolSQS = [
  { "key": "User Testing", "option": "User Testing", "label": "User Testing", "value": "User Testing" },
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "Design", "option": "Design", "label": "Design", "value": "Design" }
]

export const securityTestingToolSQS = [
  { "key": "User Testing", "option": "User Testing", "label": "User Testing", "value": "User Testing" },
  { "key": "DevOps", "option": "DevOps", "label": "DevOps", "value": "DevOps" }
]

export const softwareTestingToolSQS = [
  { "key": "User Testing", "option": "User Testing", "label": "User Testing", "value": "User Testing" },
  { "key": "Quality Assurance", "option": "Quality Assurance", "label": "Quality Assurance", "value": "Quality Assurance" }
]

export const stakeholderManagementToolSQS = [
  { "key": "Project Management", "option": "Project Management", "label": "Project Management", "value": "Project Management" },
  { "key": "Business Planning", "option": "Business Planning", "label": "Business Planning", "value": "Business Planning" }

]

export const testDocumentationToolSQS = [
  { "key": "User Testing", "option": "User Testing", "label": "User Testing", "value": "User Testing" }
]

export const testPlanningAndExecutionToolSQS = [
  { "key": "User Testing", "option": "User Testing", "label": "User Testing", "value": "User Testing" }
]

export const vpoffinanceskills = [
  { "key": "regulatory-compliance", "option": "regulatory-compliance", "label": "Regulatory Compliance", "value": "Regulatory Compliance", "tools": "regulatoryComplianceToolVFS" },
  { "key": "auditing", "option": "auditing", "label": "Auditing", "value": "Auditing", "tools": "auditingToolVFS" },
  { "key": "investment-strategy", "option": "investment-strategy", "label": "Investment Strategy", "value": "Investment Strategy", "tools": "investmentStrategyToolVFS" },
  { "key": "strategic-planning", "option": "strategic-planning", "label": "Strategic Planning", "value": "Strategic Planning", "tools": "strategicPlanningToolVFS" },
  { "key": "budgeting-and-forecasting", "option": "budgeting-and-forecasting", "label": "Budgeting and Forecasting", "value": "Budgeting and Forecasting", "tools": "budgetingAndForecastingToolVFS" },
  { "key": "internal-controls-and-compliance", "option": "internal-controls-and-compliance", "label": "Internal Controls and Compliance", "value": "Internal Controls and Compliance", "tools": "internalControlsAndComplianceToolVFS" },
  { "key": "financial-planning-and-analysis", "option": "financial-planning-and-analysis", "label": "Financial Planning and Analysis", "value": "Financial Planning and Analysis", "tools": "financialPlanningAndAnalysisToolVFS" },
  { "key": "capital-structure-and-financing", "option": "capital-structure-and-financing", "label": "Capital Structure and Financing", "value": "Capital Structure and Financing", "tools": "capitalStructureAndFinancingToolVFC" },
  { "key": "cash-flow-management", "option": "cash-flow-management", "label": "Cash Flow Management", "value": "Cash Flow Management", "tools": "cashFlowManagementToolVFC" },
  { "key": "cost-management", "option": "cost-management", "label": "Cost Management", "value": "Cost Management", "tools": "costManagementToolVFC" },
  { "key": "risk-management", "option": "risk-management", "label": "Risk Management", "value": "Risk Management", "tools": "riskManagementToolVFC" },
  { "key": "financial-modeling", "option": "financial-modeling", "label": "Financial Modeling", "value": "Financial Modeling", "tools": "financialModelingToolVFC" },
  { "key": "financial-reporting", "option": "financial-reporting", "label": "Financial Reporting", "value": "Financial Reporting", "tools": "financialReportingToolVFC" },
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership", "tools": "leadershipToolVFC" }
]

export const regulatoryComplianceToolVFS = [
  { "key": "Compliance", "option": "Compliance", "label": "Compliance", "value": "Compliance" }
]

export const auditingToolVFS = [
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "Compliance", "option": "Compliance", "label": "Compliance", "value": "Compliance" }
]
export const investmentStrategyToolVFS = [
  { "key": "Financials", "option": "Financials", "label": "Financials", "value": "Financials" }
]

export const strategicPlanningToolVFS = [


  { "key": "Business Planning", "option": "Business Planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "Strategy", "option": "Strategy", "label": "Strategy", "value": "Strategy" }
]
export const budgetingAndForecastingToolVFS = [
  { "key": "Accounting", "option": "Accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "Financials", "option": "Financials", "label": "Financials", "value": "Financials" },
  { "key": "Data", "option": "Data", "label": "Data", "value": "Data" }
]
export const internalControlsAndComplianceToolVFS = [
  { "key": "Compliance", "option": "Compliance", "label": "Compliance", "value": "Compliance" }

]

export const financialPlanningAndAnalysisToolVFS = [
  { "key": "Financials", "option": "Financials", "label": "Financials", "value": "Financials" },
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" }
]

export const capitalStructureAndFinancingToolVFC = [
  { "key": "Accounting", "option": "Accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "Business Planning", "option": "Business Planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "Financials", "option": "Financials", "label": "Financials", "value": "Financials" }

]

export const cashFlowManagementToolVFC = [
  { "key": "Accounting", "option": "Accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "Financials", "option": "Financials", "label": "Financials", "value": "Financials" }
]

export const costManagementToolVFC = [
  { "key": "Accounting", "option": "Accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "Financials", "option": "Financials", "label": "Financials", "value": "Financials" }

]
export const riskManagementToolVFC = [
  { "key": "Compliance", "option": "Compliance", "label": "Compliance", "value": "Compliance" }
]

export const financialModelingToolVFC = [
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "Compliance", "option": "Compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "Data", "option": "Data", "label": "Data", "value": "Data" }
]

export const financialReportingToolVFC = [
  { "key": "Financials", "option": "Financials", "label": "Financials", "value": "Financials" },
  { "key": "Accounting", "option": "Accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "Data", "option": "Data", "label": "Data", "value": "Data" }
]
export const leadershipToolVFC = [
  { "key": "People Management", "option": "People Management", "label": "People Management", "value": "People Management" }

]


export const vpoflegalskills = [
  { "key": "contract-management", "option": "contract-management", "label": "Contract Management", "value": "Contract Management", "tools": "contractManagementToolVLS" },
  { "key": "risk-assessment", "option": "risk-assessment", "label": "Risk Assessment", "value": "Risk Assessment", "tools": "riskAssessmentToolVLS" },
  { "key": "legal-advisory", "option": "legal-advisory", "label": "Legal Advisory", "value": "Legal Advisory", "tools": "legalAdvisoryToolVLS" },
  { "key": "corporate-law", "option": "corporate-law", "label": "Corporate Law", "value": "Corporate Law", "tools": "corporateLawToolVLS" },
  { "key": "regulatory-compliance", "option": "regulatory-compliance", "label": "Regulatory Compliance", "value": "Regulatory Compliance", "tools": "regulatoryComplianceToolVLS" },
  { "key": "corporate-governance", "option": "corporate-governance", "label": "Corporate Governance", "value": "Corporate Governance", "tools": "corporateGovernanceToolVLS" },
  { "key": "intellectual-property", "option": "intellectual-property", "label": "Intellectual Property", "value": "Intellectual Property", "tools": "intellectualPropertyToolVLS" },
  { "key": "mergers-and-acquisitions", "option": "mergers-and-acquisitions", "label": "Mergers and Acquisitions", "value": "Mergers and Acquisitions", "tools": "mergersAndAcquisitionsToolVLS" },
  { "key": "policy-development", "option": "policy-development", "label": "Policy Development", "value": "Policy Development", "tools": "policyDevelopmentToolVLS" },
  { "key": "litigation-management", "option": "litigation-management", "label": "Litigation Management", "value": "Litigation Management", "tools": "litigationManagementToolVLS" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy", "tools": "strategyToolVLS" },
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen", "tools": "businessAcumenToolVLS" }
]

export const contractManagementToolVLS = [
  { "key": "CRM", "option": "CRM", "label": "CRM", "value": "CRM" },
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" }

]

export const riskAssessmentToolVLS = [
  { "key": "Compliance", "option": "Compliance", "label": "Compliance", "value": "Compliance" }
]

export const legalAdvisoryToolVLS = [
  { "key": "CRM", "option": "CRM", "label": "CRM", "value": "CRM" }
]
export const corporateLawToolVLS = [
  { "key": "Compliance", "option": "Compliance", "label": "Compliance", "value": "Compliance" }
]
export const regulatoryComplianceToolVLS = [
  { "key": "Compliance", "option": "Compliance", "label": "Compliance", "value": "Compliance" }
]
export const corporateGovernanceToolVLS = [
  { "key": "Compliance", "option": "Compliance", "label": "Compliance", "value": "Compliance" }

]
export const intellectualPropertyToolVLS = [
  { "key": "Compliance", "option": "Compliance", "label": "Compliance", "value": "Compliance" }

]
export const mergersAndAcquisitionsToolVLS = [
  { "key": "Compliance", "option": "Compliance", "label": "Compliance", "value": "Compliance" }

]
export const policyDevelopmentToolVLS = [
  { "key": "Analytics", "option": "Analytics", "label": "Analytics", "value": "Analytics" }
]
export const litigationManagementToolVLS = [
  { "key": "Compliance", "option": "Compliance", "label": "Compliance", "value": "Compliance" }
]
export const strategyToolVLS = [
  { "key": "Strategy", "option": "Strategy", "label": "Strategy", "value": "Strategy" }

]
export const businessAcumenToolVLS = [
  { "key": "Business Planning", "option": "Business Planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "Financials", "option": "Financials", "label": "Financials", "value": "Financials" },
  { "key": "Strategy", "option": "Strategy", "label": "Strategy", "value": "Strategy" }
]
export const vpofoperationsskills = [
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen", "tools": "businessAcumenToolVOS" },
  { "key": "financial-acumen", "option": "financial-acumen", "label": "Financial Acumen", "value": "Financial Acumen", "tools": "financialAcumenToolVOS" },
  { "key": "technological-proficiency", "option": "technological-proficiency", "label": "Technological Proficiency", "value": "Technological Proficiency", "tools": "technologicalProficiencyToolVOS" },
  { "key": "operations-strategy", "option": "operations-strategy", "label": "Operations Strategy", "value": "Operations Strategy", "tools": "operationsStrategyToolVOS" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation", "tools": "innovationToolVOS" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management", "tools": "projectManagementToolVOS" },
  { "key": "operational-excellence", "option": "operational-excellence", "label": "Operational Excellence", "value": "Operational Excellence", "tools": "operationalExcellenceToolVOS" },
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership", "tools": "leadershipToolVOS" },
  { "key": "risk-management", "option": "risk-management", "label": "Risk Management", "value": "Risk Management", "tools": "riskManagementToolVOS" }
]
export const businessAcumenToolVOS = [
  { "key": "Business Planning", "option": "Business Planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "Financials", "option": "Financials", "label": "Financials", "value": "Financials" },
  { "key": "Strategy", "option": "Strategy", "label": "Strategy", "value": "Strategy" }
]
export const financialAcumenToolVOS = [
  { "key": "Financials", "option": "Financials", "label": "Financials", "value": "Financials" },
  { "key": "Accounting", "option": "Accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "Business Planning", "option": "Business Planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "Investment Management", "option": "Investment Management", "label": "Investment Management", "value": "Investment Management" }
]
export const technologicalProficiencyToolVOS = [
  { "key": "Project Management", "option": "Project Management", "label": "Project Management", "value": "Project Management" },
  { "key": "Backend Development", "option": "Backend Development", "label": "Backend Development", "value": "Backend Development" },
  { "key": "Software Development", "option": "Software Development", "label": "Software Development", "value": "Software Development" }
]
export const operationsStrategyToolVOS = [
  { "key": "Strategy", "option": "Strategy", "label": "Strategy", "value": "Strategy" }
]
export const innovationToolVOS = [
  { "key": "Strategy", "option": "Strategy", "label": "Strategy", "value": "Strategy" }
]
export const projectManagementToolVOS = [
  { "key": "Project Management", "option": "Project Management", "label": "Project Management", "value": "Project Management" },
  { "key": "People Management", "option": "People Management", "label": "People Management", "value": "People Management" }
]
export const operationalExcellenceToolVOS = [
  { "key": "Project Management", "option": "Project Management", "label": "Project Management", "value": "Project Management" },
  { "key": "People Management", "option": "People Management", "label": "People Management", "value": "People Management" }
]
export const leadershipToolVOS = [
  { "key": "People Management", "option": "People Management", "label": "People Management", "value": "People Management" }
]
export const riskManagementToolVOS = [
  { "key": "Compliance", "option": "Compliance", "label": "Compliance", "value": "Compliance" }
]
export const vpofsalesskills = [
  { "key": "sales-strategy", "option": "sales-strategy", "label": "Sales Strategy", "value": "Sales Strategy", "tools": "sales-strategy-tool" },
  { "key": "budgeting", "option": "budgeting", "label": "Budgeting", "value": "Budgeting", "tools": "budgeting-tool" },
  { "key": "sales-execution", "option": "sales-execution", "label": "Sales Execution", "value": "Sales Execution", "tools": "sales-execution-tool" },
  { "key": "digital-sales-strategy", "option": "digital-sales-strategy", "label": "Digital Sales Strategy", "value": "Digital Sales Strategy", "tools": "digital-sales-strategy-tool" },
  { "key": "customer-experience", "option": "customer-experience", "label": "Customer Experience", "value": "Customer Experience", "tools": "customer-experience-tool" },
  { "key": "data-driven-decision-making", "option": "data-driven-decision-making", "label": "Data Driven Decision Making", "value": "Data Driven Decision Making", "tools": "data-driven-decision-making-tool" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation", "tools": "innovation-tool" },
  { "key": "business-acumen", "option": "business-acumen", "label": "Business Acumen", "value": "Business Acumen", "tools": "business-acumen-tool" },
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership", "tools": "leadership-tool" },
  { "key": "risk-management", "option": "risk-management", "label": "Risk Management", "value": "Risk Management", "tools": "risk-management-tool" }
]

export const vpoftechnologyskills = [
  { "key": "governance", "option": "governance", "label": "Governance", "value": "Governance", "tools": "governanceTool" },
  { "key": "risk-management", "option": "risk-management", "label": "Risk Management", "value": "Risk Management", "tools": "riskManagementTool" },
  { "key": "financial-acumen", "option": "financial-acumen", "label": "Financial Acumen", "value": "Financial Acumen", "tools": "financialAcumenTool" },
  { "key": "innovation", "option": "innovation", "label": "Innovation", "value": "Innovation", "tools": "innovationTool" },
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership", "tools": "leadershipTool" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy", "tools": "strategyTool" }
]




export const operationalOversightTool = [
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" }
]
export const stakeholderManagementTool = [
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" }
]
export const innovationTool = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "marketing-strategy", "option": "marketing-strategy", "label": "Marketing Strategy", "value": "Marketing Strategy" },
  { "key": "seo", "option": "seo", "label": "SEO", "value": "SEO" }
]
export const leadershipTool = [
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" }
]
export const changeManagementTool = [
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" }
]

export const businessDevelopmentTool = [
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
  { "key": "sales", "option": "sales", "label": "Sales", "value": "Sales" },
  { "key": "marketing", "option": "marketing", "label": "Marketing", "value": "Marketing" }
]
export const financialAcumenTool = [
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" }
]

export const corporateGovernanceTool = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const riskManagementTool = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" }
]
export const marketAnalysisTool = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "marketing-strategy", "option": "marketing-strategy", "label": "Marketing Strategy", "value": "Marketing Strategy" },
  { "key": "seo", "option": "seo", "label": "SEO", "value": "SEO" }
]
export const strategicPlanningTool = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
]

export const regulatoryComplianceTool = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" }
]
export const auditingTool = [
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" }
]
export const investmentStrategyTool = [
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const strategicPlanningToolCFO = [
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const budgetingAndForecastingTool = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "data", "option": "data", "label": "Data", "value": "Data" }
]
export const internalControlsAndComplianceTool = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" }
]
export const financialPlanningAndAnalysisTool = [
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
]
export const capitalStructureAndFinancingTool = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" }
]
export const cashFlowManagementTool = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" }
]
export const costManagementTool = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" }
]
export const riskManagementToolCFO = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const financialModelingTool = [
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "data", "option": "data", "label": "Data", "value": "Data" }
]
export const financialReportingTool = [
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "data", "option": "data", "label": "Data", "value": "Data" }
]
export const leadershipToolCFO = [
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]

export const enterpriseArchitectureToolCIO = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]
export const itStrategyAndPlanningToolCIO = [
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const digitalTransformationToolCIO = [
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const businessAcumenToolCIO = [
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" }
]
export const cybersecurityRiskManagementToolCIO = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "backend-development", "option": "backend-development", "label": "Backend Development", "value": "Backend Development" },
  { "key": "database-development", "option": "database-development", "label": "Database Development", "value": "Database Development" }
]
export const vendorManagementToolCIO = [
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "account-ownership", "option": "account-ownership", "label": "Account Ownership", "value": "Account Ownership" }
]
export const technologyInnovationToolCIO = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "software-development", "option": "software-development", "label": "Software Development", "value": "Software Development" }
]
export const infrastructureManagementToolCIO = [
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" }
]
export const softwareDevelopmentLifecycleToolCIO = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
]
export const dataManagementToolCIO = [
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "data", "option": "data", "label": "Data", "value": "Data" }
]
export const innovationToolCIO = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const itGovernanceToolCIO = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" }
]
export const leadershipToolCIO = [
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" }
]


export const contractManagementToolCLO = [
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
]
export const riskAssessmentToolCLO = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "account-ownership", "option": "account-ownership", "label": "Account Ownership", "value": "Account Ownership" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]
export const legalAvisoryToolCLO = [
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const corporateLawToolCLO = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const regulatoryComplianceToolCLO = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const corporateGovernanceToolCLO = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const intellectualPropertyToolCLO = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const mergersAndAcquisitionsToolCLO = [
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const policyDevelopmentToolCLO = [
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const litigationManagementToolCLO = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const strategyToolCLO = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]
export const businessAcumenToolCLO = [
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]


export const leadershipToolCMO = [
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "marketing", "option": "marketing", "label": "Marketing", "value": "Marketing" },
  { "key": "marketing-strategy", "option": "marketing-strategy", "label": "Marketing Strategy", "value": "Marketing Strategy" }
]
export const digitalMarketingStrategyToolCMO = [
  { "key": "copy-writing", "option": "copy-writing", "label": "Copy Writing", "value": "Copy Writing" },
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
  { "key": "customer-adoption", "option": "customer-adoption", "label": "Customer Adoption", "value": "Customer Adoption" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "lead-generation", "option": "lead-generation", "label": "Lead Generation", "value": "Lead Generation" },
  { "key": "e-commerce", "option": "e-commerce", "label": "e Commerce", "value": "e Commerce" },
  { "key": "email-marketing", "option": "email-marketing", "label": "Email Marketing", "value": "Email Marketing" },
  { "key": "marketing-strategy", "option": "marketing-strategy", "label": "Marketing Strategy", "value": "Marketing Strategy" },
  { "key": "social-media", "option": "social-media", "label": "Social Media", "value": "Social Media" },
  { "key": "marketing", "option": "marketing", "label": "Marketing", "value": "Marketing" }
]
export const brandStrategyToolCMO = [
  { "key": "design", "option": "design", "label": "Design", "value": "Design" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "research", "option": "research", "label": "Research", "value": "Research" }
]
export const marketingStrategyToolCMO = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "marketing-strategy", "option": "marketing-strategy", "label": "Marketing Strategy", "value": "Marketing Strategy" },
  { "key": "marketing", "option": "marketing", "label": "Marketing", "value": "Marketing" }
]
export const technicalProficiencyToolCMO = [
  { "key": "seo", "option": "seo", "label": "SEO", "value": "SEO" },
  { "key": "software-development", "option": "software-development", "label": "Software Development", "value": "Software Development" },
  { "key": "research", "option": "research", "label": "Research", "value": "Research" }
]
export const businessAcumenToolCMO = [
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "research", "option": "research", "label": "Research", "value": "Research" }
]
export const budgetingToolCMO = [
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]
export const innovationToolCMO = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "research", "option": "research", "label": "Research", "value": "Research" }
]
export const strategyToolCMO = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "research", "option": "research", "label": "Research", "value": "Research" }
]


export const businessAcumenToolCOO = [
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const financialAcumenToolCOO = [
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "account-ownership", "option": "account-ownership", "label": "Account Ownership", "value": "Account Ownership" },
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" }
]
export const technologicalProficiencyToolCOO = [
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
  { "key": "software-development", "option": "software-development", "label": "Software Development", "value": "Software Development" },
  { "key": "research", "option": "research", "label": "Research", "value": "Research" }
]
export const operationsStrategyToolCOO = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "account-ownership", "option": "account-ownership", "label": "Account Ownership", "value": "Account Ownership" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]
export const innovationToolCOO = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "research", "option": "research", "label": "Research", "value": "Research" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]
export const projectManagementToolCOO = [
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" }
]
export const operationalExcellenceToolCOO = [
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "research", "option": "research", "label": "Research", "value": "Research" }
]
export const leadershipToolCOO = [
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" }
]
export const riskManagementToolCOO = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]

export const salesStrategyToolCSO = [
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "customer-adoption", "option": "customer-adoption", "label": "Customer Adoption", "value": "Customer Adoption" }
]
export const budgetingToolCSO = [
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]
export const salesExecutionToolCSO = [
  { "key": "customer-adoption", "option": "customer-adoption", "label": "Customer Adoption", "value": "Customer Adoption" },
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
  { "key": "lead-generation", "option": "lead-generation", "label": "Lead Generation", "value": "Lead Generation" },
  { "key": "sales", "option": "sales", "label": "Sales", "value": "Sales" }
]
export const digitalSalesStrategyToolCSO = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
  { "key": "email-marketing", "option": "email-marketing", "label": "Email Marketing", "value": "Email Marketing" }
]
export const customerExperienceToolCSO = [
  { "key": "customer-adoption", "option": "customer-adoption", "label": "Customer Adoption", "value": "Customer Adoption" },
  { "key": "design", "option": "design", "label": "Design", "value": "Design" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const dataDrivenDecisionMakingToolCSO = [
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "data", "option": "data", "label": "Data", "value": "Data" }
]
export const innovationToolCSO = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "research", "option": "research", "label": "Research", "value": "Research" }
]
export const businessAcumenToolCSO = [
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]
export const leadershipToolCSO = [
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" }
]
export const riskManagementToolCSO = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" }
]

export const governanceToolCSTO = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]
export const riskManagementToolCSTO = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]
export const financialAcumenToolCSTO = [
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" }
]
export const innovationToolCSTO = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "research", "option": "research", "label": "Research", "value": "Research" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]
export const leadershipToolCSTO = [
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" }
]
export const strategyToolCSTO = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]

export const businessAcumenToolCTO = [
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const financialAcumenToolCTO = [
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "account-ownership", "option": "account-ownership", "label": "Account Ownership", "value": "Account Ownership" },
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" }
]
export const innovationToolCTO = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "research", "option": "research", "label": "Research", "value": "Research" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "software-development", "option": "software-development", "label": "Software Development", "value": "Software Development" }
]
export const itOperationsToolCTO = [
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
  { "key": "product-management", "option": "product-management", "label": "Product Management", "value": "Product Management" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]
export const projectManagementToolCTO = [
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
  { "key": "user-testing", "option": "user-testing", "label": "User Testing", "value": "User Testing" },
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
  { "key": "quality-assurance", "option": "quality-assurance", "label": "Quality Assurance", "value": "Quality Assurance" }
]
export const technicalExpertiseToolCTO = [
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "research", "option": "research", "label": "Research", "value": "Research" },
  { "key": "software-development", "option": "software-development", "label": "Software Development", "value": "Software Development" },
  { "key": "ai-development", "option": "ai-development", "label": "AI Development", "value": "AI Development" }
]
export const technologyStrategyToolCTO = [
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "devops", "option": "devops", "label": "DevOps", "value": "DevOps" },
  { "key": "product-management", "option": "product-management", "label": "Product Management", "value": "Product Management" }
]
export const leadershipToolCTO = [
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" }
]


export const businessAcumenToolCofounder = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]
export const financialAcumenToolCofounder = [
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
]
export const innovationToolCofounder = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "research", "option": "research", "label": "Research", "value": "Research" }
]
export const operationalExcellenceToolCofounder = [
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "research", "option": "research", "label": "Research", "value": "Research" }
]
export const productStrategyToolCofounder = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "product-management", "option": "product-management", "label": "Product Management", "value": "Product Management" }
]
export const regulatoryComplianceToolCofounder = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" }
]
export const salesAndMarketingToolCofounder = [
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
  { "key": "customer-adoption", "option": "customer-adoption", "label": "Customer Adoption", "value": "Customer Adoption" }
]
export const leadershipToolCofounder = [
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" }
]
export const strategyToolCofounder = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]


export const communicationStrategyToolCommSpecailist = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "research", "option": "research", "label": "Research", "value": "Research" }
]
export const crisisCommunicationToolCommSpecailist = [
  { "key": "content-writing", "option": "content-writing", "label": "Content Writing", "value": "Content Writing" },
  { "key": "research", "option": "research", "label": "Research", "value": "Research" }
]
export const digitalAndSocialMediaToolCommSpecailist = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "content-writing", "option": "content-writing", "label": "Content Writing", "value": "Content Writing" }
]
export const marketResearchToolCommSpecailist = [
  { "key": "research", "option": "research", "label": "Research", "value": "Research" }
]
export const mediaRelationsToolCommSpecailist = [
  { "key": "account-ownership", "option": "account-ownership", "label": "Account Ownership", "value": "Account Ownership" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]
export const projectManagementToolCommSpecailist = [
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]



export const budgetManagementToolDO = [
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const businessAcumenToolDO = [
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" }
]
export const customerFocusToolDO = [
  { "key": "customer-adoption", "option": "customer-adoption", "label": "Customer Adoption", "value": "Customer Adoption" },
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" }
]
export const innovationToolDO = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const operationalTechnologiesToolDO = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const processImprovementToolDO = [
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" }
]
export const projectManagementToolDO = [
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" }
]
export const qualityControlToolDO = [
  { "key": "quality-assurance", "option": "quality-assurance", "label": "Quality Assurance", "value": "Quality Assurance" }
]
export const safetyStandardToolDO = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" }
]
export const leadershipToolDO = [
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" }
]


export const leadershipToolFD = [
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]
export const businessAcumenToolFD = [
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const changeManagementToolFD = [
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]
export const corporateGovernanceToolFD = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]
export const financialReportingAndComplianceToolFD = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" }
]
export const financialManagementToolFD = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" }
]
export const financialPlanningAndAnalysisToolFD = [
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "customer-adoption", "option": "customer-adoption", "label": "Customer Adoption", "value": "Customer Adoption" }
]
export const riskManagementToolFD = [
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "research", "option": "research", "label": "Research", "value": "Research" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]

[
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" }
]
export const accountingPrinciplesToolFA = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "data", "option": "data", "label": "Data", "value": "Data" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" }
]
export const budgetingAndForecastingToolFA = [
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" }
]
export const financialAnalysisToolFA = [
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" }
]
export const financialReportingToolFA = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" }
]
export const internalControlsToolFA = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" }
]

export const financialModelingToolFAYS = [
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" }
]
export const gaapToolFAYS = [
  { "key": "research", "option": "research", "label": "Research", "value": "Research" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
]
export const ifrsToolFAYS = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
]
export const internalControlToolFAYS = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
]
export const reportingToolFAYS = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
]
export const riskManagementToolFAYS = [
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
]
export const varianceAnalysisToolFAYS = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" }
]


export const accountingPrinciplesToolFB = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" }
]
export const auditSupportToolFB = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" }
]
export const financialRecordKeepingToolFB = [
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" }
]
export const financialStatementsToolFB = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" }
]
export const reportingToolFB = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" }
]

export const accountingPrinciplesToolFC = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" }
]
export const accountingSoftwareToolFC = [
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "account-ownership", "option": "account-ownership", "label": "Account Ownership", "value": "Account Ownership" }
]
export const budgetingAndForecastingToolFC = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "data", "option": "data", "label": "Data", "value": "Data" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" }
]
export const businessAcumenToolFC = [
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const cashFlowManagementToolFC = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" }
]
export const financialAnalysisToolFC = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
]
export const financialReportingToolFC = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
]
export const internalControlsToolFC = [
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" }
]
export const leadershipToolFC = [
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" }
]

export const budgetingToolFG = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const accountingPrinciplesToolFG = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" }
]
export const costManagementToolFG = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const dataAnalyticsToolFG = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
]
export const financialModelingToolFG = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
]
export const financialReportingToolFG = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "account-ownership", "option": "account-ownership", "label": "Account Ownership", "value": "Account Ownership" }
]
export const financialSoftwareToolFG = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" }
]
export const forecastingToolFG = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
]
export const internalControlsToolFG = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" }
]
export const riskAssessmentToolFG = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" }
]
export const taxToolFG = [
  { "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" }
]

export const brandingToolMr = [
  { "key": "design", "option": "design", "label": "Design", "value": "Design" }
]
export const campaignManagementToolMr = [
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "marketing-strategy", "option": "marketing-strategy", "label": "Marketing Strategy", "value": "Marketing Strategy" }
]
export const contentStrategyToolMr = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const crmSystemsToolMr = [
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" }
]
export const dataAnalysisToolMr = [
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "data", "option": "data", "label": "Data", "value": "Data" }
]
export const digitalMarketingToolMr = [
  { "key": "paid-ads", "option": "paid-ads", "label": "Paid Ads", "value": "Paid Ads" },
  { "key": "email-marketing", "option": "email-marketing", "label": "Email Marketing", "value": "Email Marketing" },
  { "key": "marketing", "option": "marketing", "label": "Marketing", "value": "Marketing" },
  { "key": "seo", "option": "seo", "label": "SEO", "value": "SEO" }
]
export const innovationToolMr = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const marketResearchToolMr = [
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "research", "option": "research", "label": "Research", "value": "Research" }
]
export const marketingAutomationToolMr = [
  { "key": "marketing-strategy", "option": "marketing-strategy", "label": "Marketing Strategy", "value": "Marketing Strategy" }
]
export const marketingStrategyToolMr = [
  { "key": "marketing-strategy", "option": "marketing-strategy", "label": "Marketing Strategy", "value": "Marketing Strategy" }
]
export const projectManagementToolMr = [
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" }
]
export const stakeholderEngagementToolMr = [
  { "key": "customer-adoption", "option": "customer-adoption", "label": "Customer Adoption", "value": "Customer Adoption" },
  { "key": "lead-generation", "option": "lead-generation", "label": "Lead Generation", "value": "Lead Generation" }
]

export const brandPositioningToolMrSp = [
  { "key": "content-writing", "option": "content-writing", "label": "Content Writing", "value": "Content Writing" },
  { "key": "copy-writing", "option": "copy-writing", "label": "Copy Writing", "value": "Copy Writing" },
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
  { "key": "marketing-strategy", "option": "marketing-strategy", "label": "Marketing Strategy", "value": "Marketing Strategy" }
]
export const budgetManagementToolMrSp = [
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" }
]
export const campaignManagementToolMrSp = [
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "marketing-strategy", "option": "marketing-strategy", "label": "Marketing Strategy", "value": "Marketing Strategy" }
]
export const contentCreationToolMrSp = [
  { "key": "design", "option": "design", "label": "Design", "value": "Design" },
  { "key": "content-writing", "option": "content-writing", "label": "Content Writing", "value": "Content Writing" },
  { "key": "copy-writing", "option": "copy-writing", "label": "Copy Writing", "value": "Copy Writing" },
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" }
]
export const crmSystemsToolMrSp = [
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" }
]
export const digitalMarketingToolMrSp = [
  { "key": "content-writing", "option": "content-writing", "label": "Content Writing", "value": "Content Writing" },
  { "key": "design", "option": "design", "label": "Design", "value": "Design" },
  { "key": "marketing-strategy", "option": "marketing-strategy", "label": "Marketing Strategy", "value": "Marketing Strategy" },
  { "key": "social-media", "option": "social-media", "label": "Social Media", "value": "Social Media" },
  { "key": "seo", "option": "seo", "label": "SEO", "value": "SEO" }
]
export const innovationToolMrSp = [
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
]
export const marketResearchToolMrSp = [
  { "key": "research", "option": "research", "label": "Research", "value": "Research" }
]
export const projectManagementToolMrSp = [
  { "key": "product-management", "option": "product-management", "label": "Product Management", "value": "Product Management" }
]


export const accountingTool = [
  { "key": "accounting", "parentId": "Accounting", "value": "Accounting", "label": "Accounting" },
  { "key": "quickbooks", "parentId": "Accounting", "value": "QuickBooks", "label": "QuickBooks" },
  { "key": "xero", "parentId": "Accounting", "value": "Xero", "label": "Xero" },
  { "key": "sage", "parentId": "Accounting", "value": "Sage", "label": "Sage" },
  { "key": "adp", "parentId": "Accounting", "value": "ADP", "label": "ADP" },
  { "key": "accountedge", "parentId": "Accounting", "value": "AccountEdge", "label": "AccountEdge" },
  { "key": "freshbooks", "parentId": "Accounting", "value": "FreshBooks", "label": "FreshBooks" },
  { "key": "zoho_books", "parentId": "Accounting", "value": "Zoho Books", "label": "Zoho Books" },
  { "key": "oneup", "parentId": "Accounting", "value": "OneUp", "label": "OneUp" },
  { "key": "kashoo", "parentId": "Accounting", "value": "Kashoo", "label": "Kashoo" },
  { "key": "zipbooks", "parentId": "Accounting", "value": "ZipBooks", "label": "ZipBooks" },
  { "key": "wave", "parentId": "Accounting", "value": "Wave", "label": "Wave" },
  { "key": "netsuite", "parentId": "Accounting", "value": "NetSuite", "label": "NetSuite" },
  { "key": "avidxchange", "parentId": "Accounting", "value": "AvidXchange", "label": "AvidXchange" },
  { "key": "rossum", "parentId": "Accounting", "value": "Rossum", "label": "Rossum" },
  { "key": "docuphase", "parentId": "Accounting", "value": "DocuPhase", "label": "DocuPhase" },
  { "key": "payem", "parentId": "Accounting", "value": "PayEm", "label": "PayEm" },
  { "key": "trolley", "parentId": "Accounting", "value": "Trolley", "label": "Trolley" },
  { "key": "sighted", "parentId": "Accounting", "value": "Sighted", "label": "Sighted" },
  { "key": "wagepoint", "parentId": "Accounting", "value": "WagePoint", "label": "WagePoint" },
  { "key": "gusto", "parentId": "Accounting", "value": "Gusto", "label": "Gusto" },
  { "key": "surepayroll", "parentId": "Accounting", "value": "SurePayroll", "label": "SurePayroll" },
  { "key": "expensify", "parentId": "Accounting", "value": "Expensify", "label": "Expensify" },
  { "key": "neat", "parentId": "Accounting", "value": "Neat", "label": "Neat" },
  { "key": "fusebill", "parentId": "Accounting", "value": "Fusebill", "label": "Fusebill" },
  { "key": "intacct", "parentId": "Accounting", "value": "Intacct", "label": "Intacct" },
  { "key": "bill_com", "parentId": "Accounting", "value": "Bill.com", "label": "Bill.com" },
  { "key": "kashflow", "parentId": "Accounting", "value": "KashFlow", "label": "KashFlow" },
  { "key": "freeagent", "parentId": "Accounting", "value": "FreeAgent", "label": "FreeAgent" },
  { "key": "sap_concur", "parentId": "Accounting", "value": "SAP Concur", "label": "SAP Concur" },
  { "key": "patriot_software", "parentId": "Accounting", "value": "Patriot Software", "label": "Patriot Software" },
  { "key": "invoicera", "parentId": "Accounting", "value": "Invoicera", "label": "Invoicera" },
  { "key": "tipalti", "parentId": "Accounting", "value": "Tipalti", "label": "Tipalti" },
  { "key": "bench", "parentId": "Accounting", "value": "Bench", "label": "Bench" },
  { "key": "zoho_expense", "parentId": "Accounting", "value": "Zoho Expense", "label": "Zoho Expense" },
  { "key": "xpenditure", "parentId": "Accounting", "value": "Xpenditure", "label": "Xpenditure" },
  { "key": "clear_books", "parentId": "Accounting", "value": "Clear Books", "label": "Clear Books" },
  { "key": "abila_mip_fund_accounting", "parentId": "Accounting", "value": "Abila MIP Fund Accounting", "label": "Abila MIP Fund Accounting" },
  { "key": "microsoft_dynamics_gp", "parentId": "Accounting", "value": "Microsoft Dynamics GP", "label": "Microsoft Dynamics GP" },
  { "key": "jd_edwards_enterpriseone", "parentId": "Accounting", "value": "JD Edwards EnterpriseOne", "label": "JD Edwards EnterpriseOne" },
  { "key": "deltek_vision", "parentId": "Accounting", "value": "Deltek Vision", "label": "Deltek Vision" }
]

export const accountownershipTool = [
  { "key": "account_ownership", "parentId": "accountownershipTool", "value": "Account Ownership", "label": "Account Ownership" },
  { "key": "salesforce", "parentId": "accountownershipTool", "value": "Salesforce", "label": "Salesforce" },
  { "key": "hubspot", "parentId": "accountownershipTool", "value": "HubSpot", "label": "HubSpot" }
]

export const aidevelopmentTool = [
  { "key": "aidevelopment", "parentId": "aidevelopmentTool", "value": "AI Development", "label": "AI Development" },
  { "key": "tensorflow", "parentId": "aidevelopmentTool", "value": "TensorFlow", "label": "TensorFlow" },
  { "key": "pytorch", "parentId": "aidevelopmentTool", "value": "PyTorch", "label": "PyTorch" }
]

export const analyticsTool = [
  { "key": "analytics", "parentId": "analyticsTool", "value": "Analytics", "Analytics": "TensorFlow" },
  { "key": "google_analytics", "parentId": "analyticsTool", "value": "Google Analytics", "label": "Google Analytics" },
  { "key": "google_tag_manager", "parentId": "analyticsTool", "value": "Google Tag Manager", "label": "Google Tag Manager" },
  { "key": "firebase", "parentId": "analyticsTool", "value": "Firebase", "label": "Firebase" },
  { "key": "mixpanel", "parentId": "analyticsTool", "value": "Mixpanel", "label": "Mixpanel" },
  { "key": "amplitude", "parentId": "analyticsTool", "value": "Amplitude", "label": "Amplitude" },
  { "key": "pendo", "parentId": "analyticsTool", "value": "Pendo", "label": "Pendo" },
  { "key": "crazy_egg", "parentId": "analyticsTool", "value": "Crazy Egg", "label": "Crazy Egg" },
  { "key": "excel", "parentId": "analyticsTool", "value": "Excel", "label": "Excel" },
  { "key": "microsoft_power_bi", "parentId": "analyticsTool", "value": "Microsoft Power BI", "label": "Microsoft Power BI" },
  { "key": "screaming_frog_seo_spider", "parentId": "analyticsTool", "value": "Screaming Frog SEO Spider", "label": "Screaming Frog SEO Spider" },
  { "key": "kissmetrics", "parentId": "analyticsTool", "value": "Kissmetrics", "label": "Kissmetrics" },
  { "key": "heap_analytics", "parentId": "analyticsTool", "value": "Heap Analytics", "label": "Heap Analytics" },
  { "key": "chartbeat", "parentId": "analyticsTool", "value": "Chartbeat", "label": "Chartbeat" },
  { "key": "woopra", "parentId": "analyticsTool", "value": "Woopra", "label": "Woopra" },
  { "key": "segment", "parentId": "analyticsTool", "value": "Segment", "label": "Segment" },
  { "key": "looker", "parentId": "analyticsTool", "value": "Looker", "label": "Looker" },
  { "key": "tableau", "parentId": "analyticsTool", "value": "Tableau", "label": "Tableau" },
  { "key": "data_studio", "parentId": "analyticsTool", "value": "Data Studio", "label": "Data Studio" },
  { "key": "hotjar", "parentId": "analyticsTool", "value": "Hotjar", "label": "Hotjar" },
  { "key": "clicky", "parentId": "analyticsTool", "value": "Clicky", "label": "Clicky" },
  { "key": "matomo", "parentId": "analyticsTool", "value": "Matomo", "label": "Matomo" },
  { "key": "adobe_analytics", "parentId": "analyticsTool", "value": "Adobe Analytics", "label": "Adobe Analytics" },
  { "key": "flurry", "parentId": "analyticsTool", "value": "Flurry", "label": "Flurry" },
  { "key": "snowplow", "parentId": "analyticsTool", "value": "Snowplow", "label": "Snowplow" },
  { "key": "parse_ly", "parentId": "analyticsTool", "value": "Parse.ly", "label": "Parse.ly" },
  { "key": "chartmogul", "parentId": "analyticsTool", "value": "ChartMogul", "label": "ChartMogul" },
  { "key": "sas_analytics", "parentId": "analyticsTool", "value": "SAS Analytics", "label": "SAS Analytics" },
  { "key": "ibm_analytics", "parentId": "analyticsTool", "value": "IBM Analytics", "label": "IBM Analytics" },
  { "key": "qlikview", "parentId": "analyticsTool", "value": "QlikView", "label": "QlikView" },
  { "key": "rapidminer", "parentId": "analyticsTool", "value": "RapidMiner", "label": "RapidMiner" },
  { "key": "domo", "parentId": "analyticsTool", "value": "Domo", "label": "Domo" },
  { "key": "geckoboard", "parentId": "analyticsTool", "value": "Geckoboard", "label": "Geckoboard" },
  { "key": "sisense", "parentId": "analyticsTool", "value": "Sisense", "label": "Sisense" },
  { "key": "rjmetrics", "parentId": "analyticsTool", "value": "RJMetrics", "label": "RJMetrics" },
  { "key": "metabase", "parentId": "analyticsTool", "value": "Metabase", "label": "Metabase" },
  { "key": "sumo_logic", "parentId": "analyticsTool", "value": "Sumo Logic", "label": "Sumo Logic" },
  { "key": "loggly", "parentId": "analyticsTool", "value": "Loggly", "label": "Loggly" },
  { "key": "fullstory", "parentId": "analyticsTool", "value": "FullStory", "label": "FullStory" },
  { "key": "yandex_metrica", "parentId": "analyticsTool", "value": "Yandex Metrica", "label": "Yandex Metrica" },
  { "key": "clicktale", "parentId": "analyticsTool", "value": "Clicktale", "label": "Clicktale" },
  { "key": "brightedge", "parentId": "analyticsTool", "value": "BrightEdge", "label": "BrightEdge" }

]

export const backenddevelopmentTool = [
  { "key": "node_js", "parentId": "backenddevelopmentTool", "value": "Node.js", "label": "Node.js" },
  { "key": "django", "parentId": "backenddevelopmentTool", "value": "Django", "label": "Django" },
  { "key": "backend_development", "parentId": "backenddevelopmentTool", "value": "Backend Development", "label": "Backend Development" }
]

export const businessplanningTool =
  [
    { "key": "liveplan", "parentId": "businessplanningTool", "value": "LivePlan", "label": "LivePlan" },
    { "key": "enloop", "parentId": "businessplanningTool", "value": "Enloop", "label": "Enloop" },
    { "key": "cascade", "parentId": "businessplanningTool", "value": "Cascade", "label": "Cascade" },
    { "key": "envisio", "parentId": "businessplanningTool", "value": "Envisio", "label": "Envisio" },
    { "key": "business_planning", "parentId": "businessplanningTool", "value": "Business Planning", "label": "Business Planning" }
  ]


export const complianceTool = [
  { "key": "hyperproof", "parentId": "complianceTool", "value": "Hyperproof", "label": "Hyperproof" },
  { "key": "logicgate", "parentId": "complianceTool", "value": "LogicGate", "label": "LogicGate" },
  { "key": "compliance", "parentId": "complianceTool", "value": "Compliance", "label": "Compliance" }
]



export const contentwritingTool = [
  { "key": "grammarly", "parentId": "contentwritingTool", "value": "Grammarly", "label": "Grammarly" },
  { "key": "hemingway", "parentId": "contentwritingTool", "value": "Hemingway", "label": "Hemingway" },
  { "key": "content_writing", "parentId": "contentwritingTool", "value": "Content Writing", "label": "Content Writing" }
]

export const copywritingTool = [
  { "key": "copy_ai", "parentId": "copywritingTool", "value": "Copy.ai", "label": "Copy.ai" },
  { "key": "jarvis", "parentId": "copywritingTool", "value": "Jarvis", "label": "Jarvis" },
  { "key": "copy_writing", "parentId": "copywritingTool", "value": "Copy writing", "label": "Copy writing" }
]

export const crmTool = [
  { "key": "hubspot", "parentId": "crmTool", "value": "HubSpot", "label": "HubSpot" },
  { "key": "pipedrive", "parentId": "crmTool", "value": "Pipedrive", "label": "Pipedrive" },
  { "key": "less_annoying_crm", "parentId": "crmTool", "value": "Less Annoying CRM", "label": "Less Annoying CRM" },
  { "key": "salesforce", "parentId": "crmTool", "value": "Salesforce", "label": "Salesforce" },
  { "key": "clickup", "parentId": "crmTool", "value": "ClickUp", "label": "ClickUp" },
  { "key": "monday", "parentId": "crmTool", "value": "Monday", "label": "Monday" },
  { "key": "activecampaign", "parentId": "crmTool", "value": "ActiveCampaign", "label": "ActiveCampaign" },
  { "key": "close", "parentId": "crmTool", "value": "Close", "label": "Close" },
  { "key": "nextiva", "parentId": "crmTool", "value": "Nextiva", "label": "Nextiva" },
  { "key": "sap", "parentId": "crmTool", "value": "SAP", "label": "SAP" },
  { "key": "copper", "parentId": "crmTool", "value": "Copper", "label": "Copper" },
  { "key": "zoho_crm", "parentId": "crmTool", "value": "Zoho CRM", "label": "Zoho CRM" },
  { "key": "zendesk_sell", "parentId": "crmTool", "value": "Zendesk Sell", "label": "Zendesk Sell" },
  { "key": "leadsquared", "parentId": "crmTool", "value": "LeadSquared", "label": "LeadSquared" },
  { "key": "thryv", "parentId": "crmTool", "value": "Thryv", "label": "Thryv" },
  { "key": "netsuite_crm", "parentId": "crmTool", "value": "NetSuite CRM", "label": "NetSuite CRM" },
  { "key": "freshsales", "parentId": "crmTool", "value": "Freshsales", "label": "Freshsales" },
  { "key": "keap", "parentId": "crmTool", "value": "Keap", "label": "Keap" },
  { "key": "salesflare", "parentId": "crmTool", "value": "Salesflare", "label": "Salesflare" },
  { "key": "insightly", "parentId": "crmTool", "value": "Insightly", "label": "Insightly" },
  { "key": "nutshell", "parentId": "crmTool", "value": "Nutshell", "label": "Nutshell" },
  { "key": "constant_contact", "parentId": "crmTool", "value": "Constant Contact", "label": "Constant Contact" },
  { "key": "vendasta", "parentId": "crmTool", "value": "Vendasta", "label": "Vendasta" },
  { "key": "edrone", "parentId": "crmTool", "value": "edrone", "label": "edrone" },
  { "key": "pipeline", "parentId": "crmTool", "value": "Pipeline", "label": "Pipeline" },
  { "key": "nimble", "parentId": "crmTool", "value": "Nimble", "label": "Nimble" },
  { "key": "sugarcrm", "parentId": "crmTool", "value": "SugarCRM", "label": "SugarCRM" },
  { "key": "creatio_crm", "parentId": "crmTool", "value": "Creatio CRM", "label": "Creatio CRM" },
  { "key": "vtiger_crm", "parentId": "crmTool", "value": "Vtiger CRM", "label": "Vtiger CRM" },
  { "key": "streak", "parentId": "crmTool", "value": "Streak", "label": "Streak" },
  { "key": "agile_crm", "parentId": "crmTool", "value": "Agile CRM", "label": "Agile CRM" },
  { "key": "highrise", "parentId": "crmTool", "value": "Highrise", "label": "Highrise" },
  { "key": "bitrix24", "parentId": "crmTool", "value": "Bitrix24", "label": "Bitrix24" },
  { "key": "apptivo_crm", "parentId": "crmTool", "value": "Apptivo CRM", "label": "Apptivo CRM" },
  { "key": "really_simple_systems", "parentId": "crmTool", "value": "Really Simple Systems", "label": "Really Simple Systems" },
  { "key": "suitecrm", "parentId": "crmTool", "value": "SuiteCRM", "label": "SuiteCRM" },
  { "key": "bpm_online", "parentId": "crmTool", "value": "bpm’online", "label": "bpm’online" },
  { "key": "infoflo", "parentId": "crmTool", "value": "InfoFlo", "label": "InfoFlo" },
  { "key": "infor_crm", "parentId": "crmTool", "value": "Infor CRM", "label": "Infor CRM" },
  { "key": "greenrope", "parentId": "crmTool", "value": "GreenRope", "label": "GreenRope" },
  { "key": "engagebay", "parentId": "crmTool", "value": "EngageBay", "label": "EngageBay" },
  { "key": "crm", "parentId": "crmTool", "value": "CRM", "label": "CRM" }
]



export const customeradoptionTool = [
  { "key": "gainsight", "parentId": "customeradoptionTool", "value": "Gainsight", "label": "Gainsight" },
  { "key": "totango", "parentId": "customeradoptionTool", "value": "Totango", "label": "Totango" },
  { "key": "customer_io", "parentId": "customeradoptionTool", "value": "Customer.io", "label": "Customer.io" },
  { "key": "pendo", "parentId": "customeradoptionTool", "value": "Pendo", "label": "Pendo" },
  { "key": "mixpanel", "parentId": "customeradoptionTool", "value": "MixPanel", "label": "MixPanel" },
  { "key": "amplitude", "parentId": "customeradoptionTool", "value": "Amplitude", "label": "Amplitude" },
  { "key": "customer_adoption", "parentId": "customeradoptionTool", "value": "Customer Adoption", "label": "Customer Adoption" }
]


export const dataTool = [
  { "key": "snowflake", "parentId": "dataTool", "value": "Snowflake", "label": "Snowflake" },
  { "key": "bigquery", "parentId": "dataTool", "value": "BigQuery", "label": "BigQuery" },
  { "key": "redshift", "parentId": "dataTool", "value": "RedShift", "label": "RedShift" },
  { "key": "data", "parentId": "dataTool", "value": "Data", "label": "Data" }
]

export const databasedevelopmentTool = [
  { "key": "mysql", "parentId": "databasedevelopmentTool", "value": "MySQL", "label": "MySQL" },
  { "key": "postgresql", "parentId": "databasedevelopmentTool", "value": "PostgreSQL", "label": "PostgreSQL" },
  { "key": "database_development", "parentId": "databasedevelopmentTool", "value": "Database Development", "label": "Database Development" }
]


export const designTool = [
  { "key": "adobe_photoshop", "parentId": "designTool", "value": "Adobe Photoshop", "label": "Adobe Photoshop" },
  { "key": "canva", "parentId": "designTool", "value": "Canva", "label": "Canva" },
  { "key": "adobe_xd", "parentId": "designTool", "value": "Adobe XD", "label": "Adobe XD" },
  { "key": "figma", "parentId": "designTool", "value": "Figma", "label": "Figma" },
  { "key": "sketch", "parentId": "designTool", "value": "Sketch", "label": "Sketch" },
  { "key": "invision", "parentId": "designTool", "value": "InVision", "label": "InVision" },
  { "key": "affinity_designer", "parentId": "designTool", "value": "Affinity Designer", "label": "Affinity Designer" },
  { "key": "coreldraw", "parentId": "designTool", "value": "CorelDRAW", "label": "CorelDRAW" },
  { "key": "gravit_designer", "parentId": "designTool", "value": "Gravit Designer", "label": "Gravit Designer" },
  { "key": "gimp", "parentId": "designTool", "value": "GIMP", "label": "GIMP" },
  { "key": "adobe_illustrator", "parentId": "designTool", "value": "Adobe Illustrator", "label": "Adobe Illustrator" },
  { "key": "procreate", "parentId": "designTool", "value": "Procreate", "label": "Procreate" },
  { "key": "krita", "parentId": "designTool", "value": "Krita", "label": "Krita" },
  { "key": "vectr", "parentId": "designTool", "value": "Vectr", "label": "Vectr" },
  { "key": "pixelmator", "parentId": "designTool", "value": "Pixelmator", "label": "Pixelmator" },
  { "key": "lunacy", "parentId": "designTool", "value": "Lunacy", "label": "Lunacy" },
  { "key": "painttool_sai", "parentId": "designTool", "value": "PaintTool SAI", "label": "PaintTool SAI" },
  { "key": "clip_studio_paint", "parentId": "designTool", "value": "Clip Studio Paint", "label": "Clip Studio Paint" },
  { "key": "marvel_app", "parentId": "designTool", "value": "Marvel App", "label": "Marvel App" },
  { "key": "blender", "parentId": "designTool", "value": "Blender", "label": "Blender" },
  { "key": "autodesk_sketchbook", "parentId": "designTool", "value": "Autodesk SketchBook", "label": "Autodesk SketchBook" },
  { "key": "designevo", "parentId": "designTool", "value": "DesignEvo", "label": "DesignEvo" },
  { "key": "snappa", "parentId": "designTool", "value": "Snappa", "label": "Snappa" },
  { "key": "stencil", "parentId": "designTool", "value": "Stencil", "label": "Stencil" },
  { "key": "crello", "parentId": "designTool", "value": "Crello", "label": "Crello" },
  { "key": "befunky", "parentId": "designTool", "value": "BeFunky", "label": "BeFunky" },
  { "key": "user_interview", "parentId": "designTool", "value": "User Interview", "label": "User Interview" },
  { "key": "lucidchart", "parentId": "designTool", "value": "Lucidchart", "label": "Lucidchart" },
  { "key": "design", "parentId": "designTool", "value": "Design", "label": "Design" }
]

export const devopsTool = [
  { "key": "jenkins", "parentId": "devopsTool", "value": "Jenkins", "label": "Jenkins" },
  { "key": "docker", "parentId": "devopsTool", "value": "Docker", "label": "Docker" },
  { "key": "devops", "parentId": "devopsTool", "value": "DevOps", "label": "DevOps" }
]

export const ecommerceTool = [
  { "key": "shopify", "parentId": "ecommerceTool", "value": "Shopify", "label": "Shopify" },
  { "key": "bigcommerce", "parentId": "ecommerceTool", "value": "BigCommerce", "label": "BigCommerce" },
  { "key": "magento", "parentId": "ecommerceTool", "value": "Magento", "label": "Magento" },
  { "key": "amazon", "parentId": "ecommerceTool", "value": "Amazon", "label": "Amazon" },
  { "key": "squarespace", "parentId": "ecommerceTool", "value": "Squarespace", "label": "Squarespace" },
  { "key": "nautical", "parentId": "ecommerceTool", "value": "Nautical", "label": "Nautical" },
  { "key": "woocommerce", "parentId": "ecommerceTool", "value": "WooCommerce", "label": "WooCommerce" },
  { "key": "wix_ecommerce", "parentId": "ecommerceTool", "value": "Wix eCommerce", "label": "Wix eCommerce" },
  { "key": "volusion", "parentId": "ecommerceTool", "value": "Volusion", "label": "Volusion" },
  { "key": "prestashop", "parentId": "ecommerceTool", "value": "PrestaShop", "label": "PrestaShop" },
  { "key": "opencart", "parentId": "ecommerceTool", "value": "OpenCart", "label": "OpenCart" },
  { "key": "weebly", "parentId": "ecommerceTool", "value": "Weebly", "label": "Weebly" },
  { "key": "3dcart", "parentId": "ecommerceTool", "value": "3dcart", "label": "3dcart" },
  { "key": "ecwid", "parentId": "ecommerceTool", "value": "Ecwid", "label": "Ecwid" },
  { "key": "shift4shop", "parentId": "ecommerceTool", "value": "Shift4Shop", "label": "Shift4Shop" },
  { "key": "big_cartel", "parentId": "ecommerceTool", "value": "Big Cartel", "label": "Big Cartel" },
  { "key": "zen_cart", "parentId": "ecommerceTool", "value": "Zen Cart", "label": "Zen Cart" },
  { "key": "oscommerce", "parentId": "ecommerceTool", "value": "osCommerce", "label": "osCommerce" },
  { "key": "pinnaclecart", "parentId": "ecommerceTool", "value": "PinnacleCart", "label": "PinnacleCart" },
  { "key": "ekm", "parentId": "ecommerceTool", "value": "EKM", "label": "EKM" },
  { "key": "nopcommerce", "parentId": "ecommerceTool", "value": "nopCommerce", "label": "nopCommerce" },
  { "key": "spryker", "parentId": "ecommerceTool", "value": "Spryker", "label": "Spryker" },
  { "key": "salesforce_commerce_cloud", "parentId": "ecommerceTool", "value": "Salesforce Commerce Cloud", "label": "Salesforce Commerce Cloud" },
  { "key": "sap_hybris", "parentId": "ecommerceTool", "value": "SAP Hybris", "label": "SAP Hybris" },
  { "key": "vtex", "parentId": "ecommerceTool", "value": "VTEX", "label": "VTEX" },
  { "key": "elastic_path", "parentId": "ecommerceTool", "value": "Elastic Path", "label": "Elastic Path" },
  { "key": "miva_merchant", "parentId": "ecommerceTool", "value": "Miva Merchant", "label": "Miva Merchant" },
  { "key": "kibo_commerce", "parentId": "ecommerceTool", "value": "Kibo Commerce", "label": "Kibo Commerce" },
  { "key": "cloudcart", "parentId": "ecommerceTool", "value": "CloudCart", "label": "CloudCart" },
  { "key": "cart_com", "parentId": "ecommerceTool", "value": "Cart.com", "label": "Cart.com" },
  { "key": "e_commerce", "parentId": "ecommerceTool", "value": "e-Commerce", "label": "e-Commerce" }
]

export const emailmarketingTool = [
  { "key": "mailchimp", "parentId": "emailmarketingTool", "value": "Mailchimp", "label": "Mailchimp" },
  { "key": "constant_contact", "parentId": "emailmarketingTool", "value": "Constant Contact", "label": "Constant Contact" },
  { "key": "hubspot", "parentId": "emailmarketingTool", "value": "HubSpot", "label": "HubSpot" },
  { "key": "brevo", "parentId": "emailmarketingTool", "value": "Brevo", "label": "Brevo" },
  { "key": "sendgrid", "parentId": "emailmarketingTool", "value": "SendGrid", "label": "SendGrid" },
  { "key": "email_marketing", "parentId": "emailmarketingTool", "value": "Email Marketing", "label": "Email Marketing" }
]

export const financialsTool =
  [
    { "key": "quickbooks", "parentId": "financialsTool", "value": "QuickBooks", "label": "QuickBooks" },
    { "key": "sap", "parentId": "financialsTool", "value": "SAP", "label": "SAP" },
    { "key": "financials", "parentId": "financialsTool", "value": "Financials", "label": "Financials" }
  ]

export const frontenddevelopmentTool = [
  { "key": "react", "parentId": "frontenddevelopmentTool", "value": "React", "label": "React" },
  { "key": "angular", "parentId": "frontenddevelopmentTool", "value": "Angular", "label": "Angular" },
  { "key": "frontend_development", "parentId": "frontenddevelopmentTool", "value": "Frontend Development", "label": "Frontend Development" }
]

export const gamingdevelopmentTool = [
  { "key": "unity", "parentId": "gamingdevelopmentTool", "value": "Unity", "label": "Unity" },
  { "key": "unreal_engine", "parentId": "gamingdevelopmentTool", "value": "Unreal Engine", "label": "Unreal Engine" },
  { "key": "gaming_development", "parentId": "gamingdevelopmentTool", "value": "Gaming Development", "label": "Gaming Development" }
]

export const investmentmanagementTool = [
  { "key": "morningstar", "parentId": "investmentmanagementTool", "value": "Morningstar", "label": "Morningstar" },
  { "key": "emoney_advisor", "parentId": "investmentmanagementTool", "value": "eMoney Advisor", "label": "eMoney Advisor" },
  { "key": "investment_management", "parentId": "investmentmanagementTool", "value": "Investment Management", "label": "Investment Management" }
]

export const leadgenerationTool = [
  { "key": "linkedin_sales_navigator", "parentId": "leadgenerationTool", "value": "LinkedIn Sales Navigator", "label": "LinkedIn Sales Navigator" },
  { "key": "apollo", "parentId": "leadgenerationTool", "value": "Apollo", "label": "Apollo" },
  { "key": "lead_generation", "parentId": "leadgenerationTool", "value": "Lead Generation", "label": "Lead Generation" }
]

export const marketingTool = [
  { "key": "hubspot", "parentId": "marketingTool", "value": "HubSpot", "label": "HubSpot" },
  { "key": "marketo", "parentId": "marketingTool", "value": "Marketo", "label": "Marketo" },
  { "key": "marketing", "parentId": "marketingTool", "value": "Marketing", "label": "Marketing" }
]

export const marketingstrategyTool = [
  { "key": "semrush", "parentId": "marketingstrategyTool", "value": "SEMrush", "label": "SEMrush" },
  { "key": "ahrefs", "parentId": "marketingstrategyTool", "value": "Ahrefs", "label": "Ahrefs" },
  { "key": "marketing_strategy", "parentId": "marketingstrategyTool", "value": "Marketing Strategy", "label": "Marketing Strategy" }
]

export const mldevelopmentTool = [
  { "key": "tensorflow", "parentId": "mldevelopmentTool", "value": "TensorFlow", "label": "TensorFlow" },
  { "key": "keras", "parentId": "mldevelopmentTool", "value": "Keras", "label": "Keras" },
  { "key": "ml_development", "parentId": "mldevelopmentTool", "value": "ML Development", "label": "ML Development" }
]

export const mobileappdevelopmentTool = [
  { "key": "flutter", "parentId": "mobileappdevelopmentTool", "value": "Flutter", "label": "Flutter" },
  { "key": "react_native", "parentId": "mobileappdevelopmentTool", "value": "React Native", "label": "React Native" },
  { "key": "mobile_app_development", "parentId": "mobileappdevelopmentTool", "value": "Mobile App Development", "label": "Mobile App Development" }
]

export const paidadsTool = [
  { "key": "google_ads", "parentId": "paidadsTool", "value": "Google Ads", "label": "Google Ads" },
  { "key": "meta_ads", "parentId": "paidadsTool", "value": "Meta Ads", "label": "Meta Ads" },
  { "key": "linkedin_ads", "parentId": "paidadsTool", "value": "LinkedIn Ads", "label": "LinkedIn Ads" },
  { "key": "bing_ads", "parentId": "paidadsTool", "value": "Bing Ads", "label": "Bing Ads" },
  { "key": "traditional_ads", "parentId": "paidadsTool", "value": "Traditional Ads", "label": "Traditional Ads" },
  { "key": "paid_ads", "parentId": "paidadsTool", "value": "Paid Ads", "label": "Paid Ads" }
]

export const peoplemanagementTool = [
  { "key": "bamboohr", "parentId": "peoplemanagementTool", "value": "BambooHR", "label": "BambooHR" },
  { "key": "workday", "parentId": "peoplemanagementTool", "value": "Workday", "label": "Workday" },
  { "key": "lattice", "parentId": "peoplemanagementTool", "value": "Lattice", "label": "Lattice" },
  { "key": "15five", "parentId": "peoplemanagementTool", "value": "15Five", "label": "15Five" },
  { "key": "officevibe", "parentId": "peoplemanagementTool", "value": "Officevibe", "label": "Officevibe" },
  { "key": "glint", "parentId": "peoplemanagementTool", "value": "Glint", "label": "Glint" },
  { "key": "lever", "parentId": "peoplemanagementTool", "value": "Lever", "label": "Lever" },
  { "key": "kazoo", "parentId": "peoplemanagementTool", "value": "Kazoo", "label": "Kazoo" },
  { "key": "people_management", "parentId": "peoplemanagementTool", "value": "People Management", "label": "People Management" }
]

export const projectmanagementTool = [
  { "key": "userinterviews", "parentId": "projectmanagementTool", "value": "UserInterviews", "label": "UserInterviews" },
  { "key": "atlassian", "parentId": "projectmanagementTool", "value": "Atlassian", "label": "Atlassian" },
  { "key": "trello", "parentId": "projectmanagementTool", "value": "Trello", "label": "Trello" },
  { "key": "asana", "parentId": "projectmanagementTool", "value": "Asana", "label": "Asana" },
  { "key": "monday_com", "parentId": "projectmanagementTool", "value": "Monday.com", "label": "Monday.com" },
  { "key": "google_analytics", "parentId": "projectmanagementTool", "value": "Google Analytics", "label": "Google Analytics" },
  { "key": "pendo", "parentId": "projectmanagementTool", "value": "Pendo", "label": "Pendo" },
  { "key": "amplitude", "parentId": "projectmanagementTool", "value": "Amplitude", "label": "Amplitude" },
  { "key": "mixpanel", "parentId": "projectmanagementTool", "value": "MixPanel", "label": "MixPanel" },
  { "key": "segment", "parentId": "projectmanagementTool", "value": "Segment", "label": "Segment" },
  { "key": "power_bi", "parentId": "projectmanagementTool", "value": "Power BI", "label": "Power BI" },
  { "key": "basecamp", "parentId": "projectmanagementTool", "value": "Basecamp", "label": "Basecamp" },
  { "key": "atlassian_jira_confluence", "parentId": "projectmanagementTool", "value": "Atlassian Jira / Confluence", "label": "Atlassian Jira / Confluence" },
  { "key": "productboard", "parentId": "projectmanagementTool", "value": "ProductBoard", "label": "ProductBoard" },
  { "key": "redmine", "parentId": "projectmanagementTool", "value": "Redmine", "label": "Redmine" },
  { "key": "wrike", "parentId": "projectmanagementTool", "value": "Wrike", "label": "Wrike" },
  { "key": "case_tools", "parentId": "projectmanagementTool", "value": "CASE Tools", "label": "CASE Tools" },
  { "key": "excel", "parentId": "projectmanagementTool", "value": "Excel", "label": "Excel" },
  { "key": "proofhub", "parentId": "projectmanagementTool", "value": "ProofHub", "label": "ProofHub" },
  { "key": "todoist", "parentId": "projectmanagementTool", "value": "Todoist", "label": "Todoist" },
  { "key": "salesmate", "parentId": "projectmanagementTool", "value": "Salesmate", "label": "Salesmate" },
  { "key": "smartsheet", "parentId": "projectmanagementTool", "value": "Smartsheet", "label": "Smartsheet" },
  { "key": "microsoft_office", "parentId": "projectmanagementTool", "value": "Microsoft Office", "label": "Microsoft Office" },
  { "key": "clickup", "parentId": "projectmanagementTool", "value": "ClickUp", "label": "ClickUp" },
  { "key": "teamwork", "parentId": "projectmanagementTool", "value": "Teamwork", "label": "Teamwork" },
  { "key": "airtable", "parentId": "projectmanagementTool", "value": "Airtable", "label": "Airtable" },
  { "key": "notion", "parentId": "projectmanagementTool", "value": "Notion", "label": "Notion" },
  { "key": "backlog", "parentId": "projectmanagementTool", "value": "Backlog", "label": "Backlog" },
  { "key": "podio", "parentId": "projectmanagementTool", "value": "Podio", "label": "Podio" },
  { "key": "meistertask", "parentId": "projectmanagementTool", "value": "MeisterTask", "label": "MeisterTask" },
  { "key": "zoho_projects", "parentId": "projectmanagementTool", "value": "Zoho Projects", "label": "Zoho Projects" },
  { "key": "freedcamp", "parentId": "projectmanagementTool", "value": "Freedcamp", "label": "Freedcamp" },
  { "key": "ganttproject", "parentId": "projectmanagementTool", "value": "GanttProject", "label": "GanttProject" },
  { "key": "taiga", "parentId": "projectmanagementTool", "value": "Taiga", "label": "Taiga" },
  { "key": "quire", "parentId": "projectmanagementTool", "value": "Quire", "label": "Quire" },
  { "key": "kanbanflow", "parentId": "projectmanagementTool", "value": "KanbanFlow", "label": "KanbanFlow" },
  { "key": "float", "parentId": "projectmanagementTool", "value": "Float", "label": "Float" },
  { "key": "scoro", "parentId": "projectmanagementTool", "value": "Scoro", "label": "Scoro" },
  { "key": "liquidplanner", "parentId": "projectmanagementTool", "value": "LiquidPlanner", "label": "LiquidPlanner" },
  { "key": "workfront", "parentId": "projectmanagementTool", "value": "Workfront", "label": "Workfront" },
  { "key": "proworkflow", "parentId": "projectmanagementTool", "value": "ProWorkflow", "label": "ProWorkflow" },
  { "key": "clarizen", "parentId": "projectmanagementTool", "value": "Clarizen", "label": "Clarizen" },
  { "key": "mavenlink", "parentId": "projectmanagementTool", "value": "Mavenlink", "label": "Mavenlink" },
  { "key": "functionfox", "parentId": "projectmanagementTool", "value": "FunctionFox", "label": "FunctionFox" },
  { "key": "workzone", "parentId": "projectmanagementTool", "value": "Workzone", "label": "Workzone" },
  { "key": "zenkit", "parentId": "projectmanagementTool", "value": "Zenkit", "label": "Zenkit" },
  { "key": "redbooth", "parentId": "projectmanagementTool", "value": "Redbooth", "label": "Redbooth" },
  { "key": "projectlibre", "parentId": "projectmanagementTool", "value": "ProjectLibre", "label": "ProjectLibre" },
  { "key": "project_management", "parentId": "projectmanagementTool", "value": "Project Management", "label": "Project Management" }
]

export const qualityassuranceTool = [
  { "key": "selenium", "parentId": "qualityassuranceTool", "value": "Selenium", "label": "Selenium" },
  { "key": "qtp", "parentId": "qualityassuranceTool", "value": "QTP", "label": "QTP" },
  { "key": "testrail", "parentId": "qualityassuranceTool", "value": "TestRail", "label": "TestRail" },
  { "key": "zephyr_scale", "parentId": "qualityassuranceTool", "value": "Zephyr Scale", "label": "Zephyr Scale" },
  { "key": "practitest", "parentId": "qualityassuranceTool", "value": "PractiTest", "label": "PractiTest" },
  { "key": "testpad", "parentId": "qualityassuranceTool", "value": "Testpad", "label": "Testpad" },
  { "key": "spiratest", "parentId": "qualityassuranceTool", "value": "SpiraTest", "label": "SpiraTest" },
  { "key": "avo_assure", "parentId": "qualityassuranceTool", "value": "Avo Assure", "label": "Avo Assure" },
  { "key": "kobiton", "parentId": "qualityassuranceTool", "value": "Kobiton", "label": "Kobiton" },
  { "key": "accelq", "parentId": "qualityassuranceTool", "value": "ACCELQ", "label": "ACCELQ" },
  { "key": "lambdatest", "parentId": "qualityassuranceTool", "value": "LambdaTest", "label": "LambdaTest" },
  { "key": "testim", "parentId": "qualityassuranceTool", "value": "Testim", "label": "Testim" },
  { "key": "applitools", "parentId": "qualityassuranceTool", "value": "Applitools", "label": "Applitools" },
  { "key": "loadrunner", "parentId": "qualityassuranceTool", "value": "LoadRunner", "label": "LoadRunner" },
  { "key": "testcomplete", "parentId": "qualityassuranceTool", "value": "TestComplete", "label": "TestComplete" },
  { "key": "katalon_studio", "parentId": "qualityassuranceTool", "value": "Katalon Studio", "label": "Katalon Studio" },
  { "key": "ranorex", "parentId": "qualityassuranceTool", "value": "Ranorex", "label": "Ranorex" },
  { "key": "uft", "parentId": "qualityassuranceTool", "value": "UFT", "label": "UFT" },
  { "key": "silk_test", "parentId": "qualityassuranceTool", "value": "Silk Test", "label": "Silk Test" },
  { "key": "eggplant", "parentId": "qualityassuranceTool", "value": "Eggplant", "label": "Eggplant" },
  { "key": "bugzilla", "parentId": "qualityassuranceTool", "value": "Bugzilla", "label": "Bugzilla" },
  { "key": "qtest", "parentId": "qualityassuranceTool", "value": "qTest", "label": "qTest" },
  { "key": "appium", "parentId": "qualityassuranceTool", "value": "Appium", "label": "Appium" },
  { "key": "jira", "parentId": "qualityassuranceTool", "value": "JIRA", "label": "JIRA" },
  { "key": "browserstack", "parentId": "qualityassuranceTool", "value": "BrowserStack", "label": "BrowserStack" },
  { "key": "cucumber", "parentId": "qualityassuranceTool", "value": "Cucumber", "label": "Cucumber" },
  { "key": "testng", "parentId": "qualityassuranceTool", "value": "TestNG", "label": "TestNG" },
  { "key": "postman", "parentId": "qualityassuranceTool", "value": "Postman", "label": "Postman" },
  { "key": "soapui", "parentId": "qualityassuranceTool", "value": "SoapUI", "label": "SoapUI" },
  { "key": "hp_alm", "parentId": "qualityassuranceTool", "value": "HP ALM", "label": "HP ALM" },
  { "key": "xray", "parentId": "qualityassuranceTool", "value": "Xray", "label": "Xray" },
  { "key": "tosca", "parentId": "qualityassuranceTool", "value": "TOSCA", "label": "TOSCA" },
  { "key": "sahi_pro", "parentId": "qualityassuranceTool", "value": "Sahi Pro", "label": "Sahi Pro" },
  { "key": "watir", "parentId": "qualityassuranceTool", "value": "Watir", "label": "Watir" },
  { "key": "mabl", "parentId": "qualityassuranceTool", "value": "Mabl", "label": "Mabl" },
  { "key": "ghost_inspector", "parentId": "qualityassuranceTool", "value": "Ghost Inspector", "label": "Ghost Inspector" },
  { "key": "leapwork", "parentId": "qualityassuranceTool", "value": "Leapwork", "label": "Leapwork" },
  { "key": "parasoft", "parentId": "qualityassuranceTool", "value": "Parasoft", "label": "Parasoft" },
  { "key": "functionize", "parentId": "qualityassuranceTool", "value": "Functionize", "label": "Functionize" },
  { "key": "qacomplete", "parentId": "qualityassuranceTool", "value": "QAComplete", "label": "QAComplete" },
  { "key": "quality_assurance", "parentId": "qualityassuranceTool", "value": "Quality Assurance", "label": "Quality Assurance" }
]

export const researchTool = [
  { "key": "surveymonkey", "parentId": "researchTool", "value": "SurveyMonkey", "label": "SurveyMonkey" },
  { "key": "typeform", "parentId": "researchTool", "value": "Typeform", "label": "Typeform" },
  { "key": "research", "parentId": "researchTool", "value": "Research", "label": "Research" }
]

export const salesTool = [
  { "key": "zoominfo", "parentId": "salesTool", "value": "ZoomInfo", "label": "ZoomInfo" },
  { "key": "linkedin_sales_navigator", "parentId": "salesTool", "value": "LinkedIn Sales Navigator", "label": "LinkedIn Sales Navigator" },
  { "key": "apollo", "parentId": "salesTool", "value": "Apollo", "label": "Apollo" },
  { "key": "getemail_io", "parentId": "salesTool", "value": "GetEmail.io", "label": "GetEmail.io" },
  { "key": "cognism", "parentId": "salesTool", "value": "Cognism", "label": "Cognism" },
  { "key": "uplead", "parentId": "salesTool", "value": "UpLead", "label": "UpLead" },
  { "key": "bookyourdata", "parentId": "salesTool", "value": "BookYourData", "label": "BookYourData" },
  { "key": "crunchbase", "parentId": "salesTool", "value": "Crunchbase", "label": "Crunchbase" },
  { "key": "owler", "parentId": "salesTool", "value": "Owler", "label": "Owler" },
  { "key": "rocketreach", "parentId": "salesTool", "value": "RocketReach", "label": "RocketReach" },
  { "key": "kaspr", "parentId": "salesTool", "value": "Kaspr", "label": "Kaspr" },
  { "key": "collective_i", "parentId": "salesTool", "value": "Collective[i]", "label": "Collective[i]" },
  { "key": "hunter_io", "parentId": "salesTool", "value": "Hunter.io", "label": "Hunter.io" },
  { "key": "seamless_ai", "parentId": "salesTool", "value": "Seamless.ai", "label": "Seamless.ai" },
  { "key": "hg_insights", "parentId": "salesTool", "value": "HG Insights", "label": "HG Insights" },
  { "key": "easyleadz", "parentId": "salesTool", "value": "EasyLeadz", "label": "EasyLeadz" },
  { "key": "6sense", "parentId": "salesTool", "value": "6sense", "label": "6sense" },
  { "key": "foundry_intent", "parentId": "salesTool", "value": "Foundry Intent", "label": "Foundry Intent" },
  { "key": "crossbeam", "parentId": "salesTool", "value": "Crossbeam", "label": "Crossbeam" },
  { "key": "adapt_io", "parentId": "salesTool", "value": "Adapt.io", "label": "Adapt.io" },
  { "key": "db_hoovers", "parentId": "salesTool", "value": "D&B Hoovers", "label": "D&B Hoovers" },
  { "key": "salesforce", "parentId": "salesTool", "value": "Salesforce", "label": "Salesforce" },
  { "key": "buzzboard", "parentId": "salesTool", "value": "BuzzBoard", "label": "BuzzBoard" },
  { "key": "priority_engine", "parentId": "salesTool", "value": "Priority Engine", "label": "Priority Engine" },
  { "key": "cufinder", "parentId": "salesTool", "value": "CUFinder", "label": "CUFinder" },
  { "key": "similarweb_sales", "parentId": "salesTool", "value": "Similarweb Sales", "label": "Similarweb Sales" },
  { "key": "usergems", "parentId": "salesTool", "value": "UserGems", "label": "UserGems" },
  { "key": "wappalyzer", "parentId": "salesTool", "value": "Wappalyzer", "label": "Wappalyzer" },
  { "key": "reveal", "parentId": "salesTool", "value": "Reveal", "label": "Reveal" },
  { "key": "dealfront", "parentId": "salesTool", "value": "Dealfront", "label": "Dealfront" },
  { "key": "outreach", "parentId": "salesTool", "value": "Outreach", "label": "Outreach" },
  { "key": "sales", "parentId": "salesTool", "value": "Sales", "label": "Sales" }
]

export const seoTool = [
  { "key": "moz", "parentId": "seoTool", "value": "Moz", "label": "Moz" },
  { "key": "semrush", "parentId": "seoTool", "value": "SEMrush", "label": "SEMrush" },
  { "key": "google", "parentId": "seoTool", "value": "Google", "label": "Google" },
  { "key": "seo", "parentId": "seoTool", "value": "SEO", "label": "SEO" }
]

export const socialmediaTool = [
  { "key": "hootsuite", "parentId": "socialmediaTool", "value": "Hootsuite", "label": "Hootsuite" },
  { "key": "buffer", "parentId": "socialmediaTool", "value": "Buffer", "label": "Buffer" },
  { "key": "x", "parentId": "socialmediaTool", "value": "X", "label": "X" },
  { "key": "meta", "parentId": "socialmediaTool", "value": "Meta", "label": "Meta" },
  { "key": "tiktok", "parentId": "socialmediaTool", "value": "TikTok", "label": "TikTok" },
  { "key": "youtube", "parentId": "socialmediaTool", "value": "YouTube", "label": "YouTube" }
]

export const softwaredevelopmentTool = [
  { "key": "github", "parentId": "softwaredevelopmentTool", "value": "GitHub", "label": "GitHub" },
  { "key": "gitbucket", "parentId": "softwaredevelopmentTool", "value": "GitBucket", "label": "GitBucket" },
  { "key": "aws", "parentId": "softwaredevelopmentTool", "value": "AWS", "label": "AWS" },
  { "key": "azure", "parentId": "softwaredevelopmentTool", "value": "Azure", "label": "Azure" },
  { "key": "heroku", "parentId": "softwaredevelopmentTool", "value": "Heroku", "label": "Heroku" },
  { "key": "cloudinary", "parentId": "softwaredevelopmentTool", "value": "Cloudinary", "label": "Cloudinary" },
  { "key": "mongodb", "parentId": "softwaredevelopmentTool", "value": "MongoDB", "label": "MongoDB" },
  { "key": "proofhub", "parentId": "softwaredevelopmentTool", "value": "ProofHub", "label": "ProofHub" },
  { "key": "dreamweaver_cc", "parentId": "softwaredevelopmentTool", "value": "Dreamweaver CC", "label": "Dreamweaver CC" },
  { "key": "leankit", "parentId": "softwaredevelopmentTool", "value": "LeanKit", "label": "LeanKit" },
  { "key": "codenvy", "parentId": "softwaredevelopmentTool", "value": "Codenvy", "label": "Codenvy" },
  { "key": "crimson_editor", "parentId": "softwaredevelopmentTool", "value": "Crimson Editor", "label": "Crimson Editor" },
  { "key": "code_climate", "parentId": "softwaredevelopmentTool", "value": "Code Climate", "label": "Code Climate" },
  { "key": "telerik_teampulse", "parentId": "softwaredevelopmentTool", "value": "Telerik TeamPulse", "label": "Telerik TeamPulse" },
  { "key": "axosoft", "parentId": "softwaredevelopmentTool", "value": "Axosoft", "label": "Axosoft" },
  { "key": "eclipse", "parentId": "softwaredevelopmentTool", "value": "Eclipse", "label": "Eclipse" },
  { "key": "apache_ant", "parentId": "softwaredevelopmentTool", "value": "Apache Ant", "label": "Apache Ant" },
  { "key": "junit", "parentId": "softwaredevelopmentTool", "value": "JUnit", "label": "JUnit" },
  { "key": "docker", "parentId": "softwaredevelopmentTool", "value": "Docker", "label": "Docker" },
  { "key": "kubernetes", "parentId": "softwaredevelopmentTool", "value": "Kubernetes", "label": "Kubernetes" },
  { "key": "visual_studio_code", "parentId": "softwaredevelopmentTool", "value": "Visual Studio Code", "label": "Visual Studio Code" },
  { "key": "bitbucket", "parentId": "softwaredevelopmentTool", "value": "Bitbucket", "label": "Bitbucket" },
  { "key": "gitlab", "parentId": "softwaredevelopmentTool", "value": "GitLab", "label": "GitLab" },
  { "key": "jenkins", "parentId": "softwaredevelopmentTool", "value": "Jenkins", "label": "Jenkins" },
  { "key": "circleci", "parentId": "softwaredevelopmentTool", "value": "CircleCI", "label": "CircleCI" },
  { "key": "travis_ci", "parentId": "softwaredevelopmentTool", "value": "Travis CI", "label": "Travis CI" },
  { "key": "netlify", "parentId": "softwaredevelopmentTool", "value": "Netlify", "label": "Netlify" },
  { "key": "digitalocean", "parentId": "softwaredevelopmentTool", "value": "DigitalOcean", "label": "DigitalOcean" },
  { "key": "red_hat_openshift", "parentId": "softwaredevelopmentTool", "value": "Red Hat OpenShift", "label": "Red Hat OpenShift" },
  { "key": "terraform", "parentId": "softwaredevelopmentTool", "value": "Terraform", "label": "Terraform" },
  { "key": "ansible", "parentId": "softwaredevelopmentTool", "value": "Ansible", "label": "Ansible" },
  { "key": "puppet", "parentId": "softwaredevelopmentTool", "value": "Puppet", "label": "Puppet" },
  { "key": "chef", "parentId": "softwaredevelopmentTool", "value": "Chef", "label": "Chef" },
  { "key": "vagrant", "parentId": "softwaredevelopmentTool", "value": "Vagrant", "label": "Vagrant" },
  { "key": "jira", "parentId": "softwaredevelopmentTool", "value": "JIRA", "label": "JIRA" },
  { "key": "confluence", "parentId": "softwaredevelopmentTool", "value": "Confluence", "label": "Confluence" },
  { "key": "sublime_text", "parentId": "softwaredevelopmentTool", "value": "Sublime Text", "label": "Sublime Text" },
  { "key": "intellij_idea", "parentId": "softwaredevelopmentTool", "value": "IntelliJ IDEA", "label": "IntelliJ IDEA" },
  { "key": "pycharm", "parentId": "softwaredevelopmentTool", "value": "PyCharm", "label": "PyCharm" },
  { "key": "webstorm", "parentId": "softwaredevelopmentTool", "value": "WebStorm", "label": "WebStorm" },
  { "key": "netbeans", "parentId": "softwaredevelopmentTool", "value": "NetBeans", "label": "NetBeans" },
  { "key": "mysql", "parentId": "softwaredevelopmentTool", "value": "MySQL", "label": "MySQL" },
  { "key": "postgresql", "parentId": "softwaredevelopmentTool", "value": "PostgreSQL", "label": "PostgreSQL" },
  { "key": "sqlite", "parentId": "softwaredevelopmentTool", "value": "SQLite", "label": "SQLite" },
  { "key": "mariadb", "parentId": "softwaredevelopmentTool", "value": "MariaDB", "label": "MariaDB" },
  { "key": "redis", "parentId": "softwaredevelopmentTool", "value": "Redis", "label": "Redis" },
  { "key": "firebase", "parentId": "softwaredevelopmentTool", "value": "Firebase", "label": "Firebase" },
  { "key": "nginx", "parentId": "softwaredevelopmentTool", "value": "Nginx", "label": "Nginx" },
  { "key": "apache_http_server", "parentId": "softwaredevelopmentTool", "value": "Apache HTTP Server", "label": "Apache HTTP Server" },
  { "key": "jetbrains_phpstorm", "parentId": "softwaredevelopmentTool", "value": "JetBrains PhpStorm", "label": "JetBrains PhpStorm" },
  { "key": "atom", "parentId": "softwaredevelopmentTool", "value": "Atom", "label": "Atom" },
  { "key": "gradle", "parentId": "softwaredevelopmentTool", "value": "Gradle", "label": "Gradle" },
  { "key": "maven", "parentId": "softwaredevelopmentTool", "value": "Maven", "label": "Maven" },
  { "key": "helm", "parentId": "softwaredevelopmentTool", "value": "Helm", "label": "Helm" }
]

export const strategyTool =
  [
    { "key": "cascade", "parentId": "strategyTool", "value": "Cascade", "label": "Cascade" },
    { "key": "envisio", "parentId": "strategyTool", "value": "Envisio", "label": "Envisio" },
    { "key": "swot", "parentId": "strategyTool", "value": "SWOT", "label": "SWOT" },
    { "key": "pest_analysis", "parentId": "strategyTool", "value": "PEST Analysis", "label": "PEST Analysis" },
    { "key": "porters_five_forces", "parentId": "strategyTool", "value": "Porter's Five Forces", "label": "Porter's Five Forces" },
    { "key": "balanced_scorecard", "parentId": "strategyTool", "value": "Balanced Scorecard", "label": "Balanced Scorecard" },
    { "key": "scenario_planning", "parentId": "strategyTool", "value": "Scenario Planning", "label": "Scenario Planning" },
    { "key": "bcg_matrix", "parentId": "strategyTool", "value": "BCG Matrix", "label": "BCG Matrix" },
    { "key": "value_chain_analysis", "parentId": "strategyTool", "value": "Value Chain Analysis", "label": "Value Chain Analysis" },
    { "key": "okrs", "parentId": "strategyTool", "value": "OKRs", "label": "OKRs" },
    { "key": "vrio_framework", "parentId": "strategyTool", "value": "VRIO Framework", "label": "VRIO Framework" },
    { "key": "strategy", "parentId": "strategyTool", "value": "Strategy", "label": "Strategy" }
  ]

const usertestingTool = [
  { "key": "usertesting_com", "parentId": "usertestingTool", "value": "UserTesting.com", "label": "UserTesting.com" },
  { "key": "lookback", "parentId": "usertestingTool", "value": "Lookback", "label": "Lookback" },
  { "key": "user_testing", "parentId": "usertestingTool", "value": "User Testing", "label": "User Testing" }
]


const vrdevelopmentTool = [
  { "key": "oculus_sdk", "parentId": "vrdevelopmentTool", "value": "Oculus SDK", "label": "Oculus SDK" },
  { "key": "steamvr", "parentId": "vrdevelopmentTool", "value": "SteamVR", "label": "SteamVR" },
  { "key": "vr_development", "parentId": "vrdevelopmentTool", "value": "VR Development", "label": "VR Development" }
]

const webdevelopmentTool = [
  { "key": "wordpress", "parentId": "webdevelopmentTool", "value": "WordPress", "label": "WordPress" },
  { "key": "wix", "parentId": "webdevelopmentTool", "value": "Wix", "label": "Wix" },
  { "key": "shopify", "parentId": "webdevelopmentTool", "value": "Shopify", "label": "Shopify" },
  { "key": "web_development", "parentId": "webdevelopmentTool", "value": "Web Development", "label": "Web Development" }
]
