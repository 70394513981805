export const ChiefFinancialOfficerTools = [{ "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
{ "key": "business-plan", "option": "business-plan", "label": "Business Plan", "value": "Business Plan" },
{ "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
{ "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
{ "key": "data", "option": "data", "label": "Data", "value": "Data" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" },
{ "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership" },
{ "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
{ "key": "analysis", "option": "analysis", "label": "Analysis", "value": "Analysis" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
];
export const CommunicationSpecialistTools = [{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
{ "key": "content-writing", "option": "content-writing", "label": "Content Writing", "value": "Content Writing" },
{ "key": "copy-writing", "option": "copy-writing", "label": "Copy Writing", "value": "Copy Writing" },
{ "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
{ "key": "email-marketing", "option": "email-marketing", "label": "Email Marketing", "value": "Email Marketing" },
{ "key": "marketing", "option": "marketing", "label": "Marketing", "value": "Marketing" }
];

export const chroTools = [
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "business-plan", "option": "business-plan", "label": "Business Plan", "value": "Business Plan" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership" },
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
  { "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },


]
export const MarketingSpecialistTools = [{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
{ "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
{ "key": "marketing", "option": "marketing", "label": "Marketing", "value": "Marketing" },
{ "key": "sales", "option": "sales", "label": "Sales", "value": "Sales" },
{ "key": "e-commerce", "option": "e-commerce", "label": "e-Commerce", "value": "e-Commerce" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "business-plan", "option": "business-plan", "label": "Business Plan", "value": "Business Plan" },
{ "key": "email-marketing", "option": "email-marketing", "label": "Email Marketing", "value": "Email Marketing" },
{ "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership" },
{ "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
{ "key": "customer-adoption", "option": "customer-adoption", "label": "Customer Adoption", "value": "Customer Adoption" },
{ "key": "seo", "option": "seo", "label": "SEO", "value": "SEO" },
{ "key": "social-media", "option": "social-media", "label": "Social Media", "value": "Social Media" }
];
export const ProjectManagerITTools = [{ "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
{ "key": "qa", "option": "qa", "label": "QA", "value": "QA" },
{ "key": "software-design", "option": "software-design", "label": "Software Design", "value": "Software Design" },
{ "key": "software-development", "option": "software-development", "label": "Software Development", "value": "Software Development" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
{ "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" }
];
export const SalesTools = [{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
{ "key": "business-plan", "option": "business-plan", "label": "Business Plan", "value": "Business Plan" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "customer-life-cycles", "option": "customer-life-cycles", "label": "Customer Life Cycles", "value": "Customer Life Cycles" },
{ "key": "lead-generation", "option": "lead-generation", "label": "Lead Generation", "value": "Lead Generation" },
{ "key": "sales", "option": "sales", "label": "Sales", "value": "Sales" },
{ "key": "account-ownership", "option": "account-ownership", "label": "Account Ownership", "value": "Account Ownership" }
];
export const softwaredevelopmenttools = [{ "key": "ai-development", "option": "ai-development", "label": "AI Development", "value": "AI Development" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "backend-development", "option": "backend-development", "label": "Backend Development", "value": "Backend Development" },
{ "key": "database-development", "option": "database-development", "label": "Database Development", "value": "Database Development" },
{ "key": "devops", "option": "devops", "label": "DevOps", "value": "DevOps" },
{ "key": "frontend-development", "option": "frontend-development", "label": "Frontend Development", "value": "Frontend Development" },
{ "key": "ml-development", "option": "ml-development", "label": "ML Development", "value": "ML Development" },
{ "key": "mobile-app-development", "option": "mobile-app-development", "label": "Mobile App Development", "value": "Mobile App Development" },
{ "key": "qa", "option": "qa", "label": "QA", "value": "QA" },
{ "key": "research", "option": "research", "label": "Research", "value": "Research" },
{ "key": "software-design", "option": "software-design", "label": "Software Design", "value": "Software Design" },
{ "key": "software-development", "option": "software-development", "label": "Software Development", "value": "Software Development" },
{ "key": "user-testing", "option": "user-testing", "label": "User Testing", "value": "User Testing" },
{ "key": "vr-development", "option": "vr-development", "label": "VR Development", "value": "VR Development" },
{ "key": "web-development", "option": "web-development", "label": "Web Development", "value": "Web Development" }
];

export const COFOUNDERTools = [{ "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
{ "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "sales", "option": "sales", "label": "Sales", "value": "Sales" },
{ "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
{ "key": "marketing", "option": "marketing", "label": "Marketing", "value": "Marketing" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" }
];
export const FINANCEACCOUNTINGTools = [{ "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
{ "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
{ "key": "data", "option": "data", "label": "Data", "value": "Data" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" },
{ "key": "analysis", "option": "analysis", "label": "Analysis", "value": "Analysis" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
];
export const FINANCIALANALYSTTools = [{ "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
{ "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
{ "key": "data", "option": "data", "label": "Data", "value": "Data" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" },
{ "key": "analysis", "option": "analysis", "label": "Analysis", "value": "Analysis" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
];
export const FinancialBookkeeperTools = [{ "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
{ "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
{ "key": "data", "option": "data", "label": "Data", "value": "Data" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" },
{ "key": "analysis", "option": "analysis", "label": "Analysis", "value": "Analysis" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
];
export const FinanceGeneralTools = [{ "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
{ "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
{ "key": "data", "option": "data", "label": "Data", "value": "Data" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" },
{ "key": "analysis", "option": "analysis", "label": "Analysis", "value": "Analysis" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
];
export const FinancialControllerTools = [{ "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
{ "key": "business-plan", "option": "business-plan", "label": "Business Plan", "value": "Business Plan" },
{ "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
{ "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
{ "key": "data", "option": "data", "label": "Data", "value": "Data" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" },
{ "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership" },
{ "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
{ "key": "analysis", "option": "analysis", "label": "Analysis", "value": "Analysis" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
];
export const salesaetools = [{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
{ "key": "business-plan", "option": "business-plan", "label": "Business Plan", "value": "Business Plan" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "customer-life-cycles", "option": "customer-life-cycles", "label": "Customer Life Cycles", "value": "Customer Life Cycles" },
{ "key": "lead-generation", "option": "lead-generation", "label": "Lead Generation", "value": "Lead Generation" },
{ "key": "sales", "option": "sales", "label": "Sales", "value": "Sales" },
{ "key": "account-ownership", "option": "account-ownership", "label": "Account Ownership", "value": "Account Ownership" }
];
export const salesbdtools = [{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
{ "key": "business-plan", "option": "business-plan", "label": "Business Plan", "value": "Business Plan" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "customer-life-cycles", "option": "customer-life-cycles", "label": "Customer Life Cycles", "value": "Customer Life Cycles" },
{ "key": "lead-generation", "option": "lead-generation", "label": "Lead Generation", "value": "Lead Generation" },
{ "key": "sales", "option": "sales", "label": "Sales", "value": "Sales" },
{ "key": "account-ownership", "option": "account-ownership", "label": "Account Ownership", "value": "Account Ownership" }
];
export const salesengineertools = [
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "sales", "option": "sales", "label": "Sales", "value": "Sales" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "marketing", "option": "marketing", "label": "Marketing", "value": "Marketing" },
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" }

];
export const softwaredesignuiuxtools = [{ "key": "ai-development", "option": "ai-development", "label": "AI Development", "value": "AI Development" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "backend-development", "option": "backend-development", "label": "Backend Development", "value": "Backend Development" },
{ "key": "database-development", "option": "database-development", "label": "Database Development", "value": "Database Development" },
{ "key": "devops", "option": "devops", "label": "DevOps", "value": "DevOps" },
{ "key": "frontend-development", "option": "frontend-development", "label": "Frontend Development", "value": "Frontend Development" },
{ "key": "ml-development", "option": "ml-development", "label": "ML Development", "value": "ML Development" },
{ "key": "mobile-app-development", "option": "mobile-app-development", "label": "Mobile App Development", "value": "Mobile App Development" },
{ "key": "qa", "option": "qa", "label": "QA", "value": "QA" },
{ "key": "research", "option": "research", "label": "Research", "value": "Research" },
{ "key": "software-design", "option": "software-design", "label": "Software Design", "value": "Software Design" },
{ "key": "software-development", "option": "software-development", "label": "Software Development", "value": "Software Development" },
{ "key": "user-testing", "option": "user-testing", "label": "User Testing", "value": "User Testing" },
{ "key": "vr-development", "option": "vr-development", "label": "VR Development", "value": "VR Development" },
{ "key": "web-development", "option": "web-development", "label": "Web Development", "value": "Web Development" }
];
export const softwareengineeringtools = [{ "key": "ai-development", "option": "ai-development", "label": "AI Development", "value": "AI Development" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "backend-development", "option": "backend-development", "label": "Backend Development", "value": "Backend Development" },
{ "key": "database-development", "option": "database-development", "label": "Database Development", "value": "Database Development" },
{ "key": "frontend-development", "option": "frontend-development", "label": "Frontend Development", "value": "Frontend Development" },
{ "key": "ml-development", "option": "ml-development", "label": "ML Development", "value": "ML Development" },
{ "key": "mobile-app-development", "option": "mobile-app-development", "label": "Mobile App Development", "value": "Mobile App Development" },
{ "key": "research", "option": "research", "label": "Research", "value": "Research" },
{ "key": "software-design", "option": "software-design", "label": "Software Design", "value": "Software Design" },
{ "key": "software-development", "option": "software-development", "label": "Software Development", "value": "Software Development" },
{ "key": "user-testing", "option": "user-testing", "label": "User Testing", "value": "User Testing" },
{ "key": "vr-development", "option": "vr-development", "label": "VR Development", "value": "VR Development" },
{ "key": "web-development", "option": "web-development", "label": "Web Development", "value": "Web Development" }
];
export const softwareproducttools = [{ "key": "ai-development", "option": "ai-development", "label": "AI Development", "value": "AI Development" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "backend-development", "option": "backend-development", "label": "Backend Development", "value": "Backend Development" },
{ "key": "database-development", "option": "database-development", "label": "Database Development", "value": "Database Development" },
{ "key": "devops", "option": "devops", "label": "DevOps", "value": "DevOps" },
{ "key": "frontend-development", "option": "frontend-development", "label": "Frontend Development", "value": "Frontend Development" },
{ "key": "ml-development", "option": "ml-development", "label": "ML Development", "value": "ML Development" },
{ "key": "mobile-app-development", "option": "mobile-app-development", "label": "Mobile App Development", "value": "Mobile App Development" },
{ "key": "qa", "option": "qa", "label": "QA", "value": "QA" },
{ "key": "research", "option": "research", "label": "Research", "value": "Research" },
{ "key": "software-design", "option": "software-design", "label": "Software Design", "value": "Software Design" },
{ "key": "software-development", "option": "software-development", "label": "Software Development", "value": "Software Development" },
{ "key": "user-testing", "option": "user-testing", "label": "User Testing", "value": "User Testing" },
{ "key": "vr-development", "option": "vr-development", "label": "VR Development", "value": "VR Development" },
{ "key": "web-development", "option": "web-development", "label": "Web Development", "value": "Web Development" },
{ "key": "product-development", "option": "product-development", "label": "Product Development", "value": "Product Development" }
];
export const softwareqatools = [{ "key": "ai-development", "option": "ai-development", "label": "AI Development", "value": "AI Development" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "backend-development", "option": "backend-development", "label": "Backend Development", "value": "Backend Development" },
{ "key": "database-development", "option": "database-development", "label": "Database Development", "value": "Database Development" },
{ "key": "devops", "option": "devops", "label": "DevOps", "value": "DevOps" },
{ "key": "frontend-development", "option": "frontend-development", "label": "Frontend Development", "value": "Frontend Development" },
{ "key": "ml-development", "option": "ml-development", "label": "ML Development", "value": "ML Development" },
{ "key": "mobile-app-development", "option": "mobile-app-development", "label": "Mobile App Development", "value": "Mobile App Development" },
{ "key": "qa", "option": "qa", "label": "QA", "value": "QA" },
{ "key": "research", "option": "research", "label": "Research", "value": "Research" },
{ "key": "software-design", "option": "software-design", "label": "Software Design", "value": "Software Design" },
{ "key": "software-development", "option": "software-development", "label": "Software Development", "value": "Software Development" },
{ "key": "user-testing", "option": "user-testing", "label": "User Testing", "value": "User Testing" },
{ "key": "vr-development", "option": "vr-development", "label": "VR Development", "value": "VR Development" },
{ "key": "web-development", "option": "web-development", "label": "Web Development", "value": "Web Development" }
];
export const FINANCEDIRECTORTools = [{ "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
{ "key": "business-plan", "option": "business-plan", "label": "Business Plan", "value": "Business Plan" },
{ "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
{ "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
{ "key": "data", "option": "data", "label": "Data", "value": "Data" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" },
{ "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership" },
{ "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
{ "key": "analysis", "option": "analysis", "label": "Analysis", "value": "Analysis" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
];
export const socialmediaspecialisttools = [{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "content-writing", "option": "content-writing", "label": "Content Writing", "value": "Content Writing" },
{ "key": "design", "option": "design", "label": "Design", "value": "Design" },
{ "key": "paid-ads", "option": "paid-ads", "label": "Paid Ads", "value": "Paid Ads" },
{ "key": "social-media", "option": "social-media", "label": "Social Media", "value": "Social Media" },
{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
];
export const ceotools = [{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "business-plan", "option": "business-plan", "label": "Business Plan", "value": "Business Plan" },
{ "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
{ "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" },
{ "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership" },
{ "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
{ "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" }];
export const COOTools = [{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "project-development", "option": "project-development", "label": "Project Development", "value": "Project Development" },
{ "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
{ "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" }
];

export const CMOTools = [{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
{ "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
{ "key": "marketing", "option": "marketing", "label": "Marketing", "value": "Marketing" },
{ "key": "sales", "option": "sales", "label": "Sales", "value": "Sales" },
{ "key": "e-commerce", "option": "e-commerce", "label": "e-Commerce", "value": "e-Commerce" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "business-plan", "option": "business-plan", "label": "Business Plan", "value": "Business Plan" },
{ "key": "email-marketing", "option": "email-marketing", "label": "Email Marketing", "value": "Email Marketing" },
{ "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership" },
{ "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" }
];
export const CTOTools = [{ "key": "ai-development", "option": "ai-development", "label": "AI Development", "value": "AI Development" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "backend-development", "option": "backend-development", "label": "Backend Development", "value": "Backend Development" },
{ "key": "business-plan", "option": "business-plan", "label": "Business Plan", "value": "Business Plan" },
{ "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
{ "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
{ "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
{ "key": "data", "option": "data", "label": "Data", "value": "Data" },
{ "key": "database-development", "option": "database-development", "label": "Database Development", "value": "Database Development" },
{ "key": "devops", "option": "devops", "label": "DevOps", "value": "DevOps" },
{ "key": "frontend-development", "option": "frontend-development", "label": "Frontend Development", "value": "Frontend Development" },
{ "key": "gaming-development", "option": "gaming-development", "label": "Gaming Development", "value": "Gaming Development" },
{ "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership" },
{ "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
{ "key": "mobile-app-development", "option": "mobile-app-development", "label": "Mobile App Development", "value": "Mobile App Development" },
{ "key": "project-development", "option": "project-development", "label": "Project Development", "value": "Project Development" },
{ "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
{ "key": "qa", "option": "qa", "label": "QA", "value": "QA" },
{ "key": "research", "option": "research", "label": "Research", "value": "Research" },
{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" }
];
export const CIOTools = [{ "key": "ai-development", "option": "ai-development", "label": "AI Development", "value": "AI Development" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "backend-development", "option": "backend-development", "label": "Backend Development", "value": "Backend Development" },
{ "key": "business-plan", "option": "business-plan", "label": "Business Plan", "value": "Business Plan" },
{ "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
{ "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
{ "key": "data", "option": "data", "label": "Data", "value": "Data" },
{ "key": "database-development", "option": "database-development", "label": "Database Development", "value": "Database Development" },
{ "key": "devops", "option": "devops", "label": "DevOps", "value": "DevOps" },
{ "key": "frontend-development", "option": "frontend-development", "label": "Frontend Development", "value": "Frontend Development" },
{ "key": "gaming-development", "option": "gaming-development", "label": "Gaming Development", "value": "Gaming Development" },
{ "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership" },
{ "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
{ "key": "mobile-app-development", "option": "mobile-app-development", "label": "Mobile App Development", "value": "Mobile App Development" },
{ "key": "project-development", "option": "project-development", "label": "Project Development", "value": "Project Development" },
{ "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
{ "key": "qa", "option": "qa", "label": "QA", "value": "QA" },
{ "key": "research", "option": "research", "label": "Research", "value": "Research" },
{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" }
];
export const CLOTools = [{ "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
{ "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership" }
];
export const CSTOTools = [
  { "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
  { "key": "business-plan", "option": "business-plan", "label": "Business Plan", "value": "Business Plan" },
  { "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
  { "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
  { "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
  { "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" },
  { "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership" },
  { "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
  { "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }


];
export const CSOTools = [{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "project-development", "option": "project-development", "label": "Project Development", "value": "Project Development" },
{ "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
{ "key": "business-plan", "option": "business-plan", "label": "Business Plan", "value": "Business Plan" },
{ "key": "customer-adoption", "option": "customer-adoption", "label": "Customer Adoption", "value": "Customer Adoption" },
{ "key": "customer-life-cycles", "option": "customer-life-cycles", "label": "Customer Life Cycles", "value": "Customer Life Cycles" },
{ "key": "lead-generation", "option": "lead-generation", "label": "Lead Generation", "value": "Lead Generation" },
{ "key": "sales", "option": "sales", "label": "Sales", "value": "Sales" }
];
export const vpoffinancetools = [{ "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
{ "key": "business-plan", "option": "business-plan", "label": "Business Plan", "value": "Business Plan" },
{ "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
{ "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
{ "key": "data", "option": "data", "label": "Data", "value": "Data" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" },
{ "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership" },
{ "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
{ "key": "analysis", "option": "analysis", "label": "Analysis", "value": "Analysis" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" }
];
export const MarketingTools = [{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
{ "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
{ "key": "marketing", "option": "marketing", "label": "Marketing", "value": "Marketing" },
{ "key": "sales", "option": "sales", "label": "Sales", "value": "Sales" },
{ "key": "e-commerce", "option": "e-commerce", "label": "e-Commerce", "value": "e-Commerce" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "business-plan", "option": "business-plan", "label": "Business Plan", "value": "Business Plan" },
{ "key": "email-marketing", "option": "email-marketing", "label": "Email Marketing", "value": "Email Marketing" },
{ "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership" },
{ "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
{ "key": "customer-adoption", "option": "customer-adoption", "label": "Customer Adoption", "value": "Customer Adoption" },
{ "key": "seo", "option": "seo", "label": "SEO", "value": "SEO" },
{ "key": "social-media", "option": "social-media", "label": "Social Media", "value": "Social Media" }
];
export const vpoftechnologytools = [{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "business-plan", "option": "business-plan", "label": "Business Plan", "value": "Business Plan" },
{ "key": "business-planning", "option": "business-planning", "label": "Business Planning", "value": "Business Planning" },
{ "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "investment-management", "option": "investment-management", "label": "Investment Management", "value": "Investment Management" },
{ "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership" },
{ "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
];
export const vpoflegaltools = [{ "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
{ "key": "leadership", "option": "leadership", "label": "Leadership", "value": "Leadership" }
];
export const vpofsalestools = [{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "project-development", "option": "project-development", "label": "Project Development", "value": "Project Development" },
{ "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
{ "key": "business-plan", "option": "business-plan", "label": "Business Plan", "value": "Business Plan" },
{ "key": "customer-adoption", "option": "customer-adoption", "label": "Customer Adoption", "value": "Customer Adoption" },
{ "key": "customer-life-cycles", "option": "customer-life-cycles", "label": "Customer Life Cycles", "value": "Customer Life Cycles" },
{ "key": "lead-generation", "option": "lead-generation", "label": "Lead Generation", "value": "Lead Generation" },
{ "key": "sales", "option": "sales", "label": "Sales", "value": "Sales" }
];
export const vpofoperationstools = [{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "project-management", "option": "project-management", "label": "Project Management", "value": "Project Management" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "project-development", "option": "project-development", "label": "Project Development", "value": "Project Development" },
{ "key": "compliance", "option": "compliance", "label": "Compliance", "value": "Compliance" },
{ "key": "people-management", "option": "people-management", "label": "People Management", "value": "People Management" }
];
export const DIRECTOROFOPERATIONSTools = [{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" },
{ "key": "content-writing", "option": "content-writing", "label": "Content Writing", "value": "Content Writing" },
{ "key": "copy-writing", "option": "copy-writing", "label": "Copy Writing", "value": "Copy Writing" },
{ "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
{ "key": "email-marketing", "option": "email-marketing", "label": "Email Marketing", "value": "Email Marketing" },
{ "key": "marketing", "option": "marketing", "label": "Marketing", "value": "Marketing" }
];
export const ProcurementSpecialistTools = [{ "key": "financials", "option": "financials", "label": "Financials", "value": "Financials" },
{ "key": "accounting", "option": "accounting", "label": "Accounting", "value": "Accounting" },
{ "key": "crm", "option": "crm", "label": "CRM", "value": "CRM" },
{ "key": "analytics", "option": "analytics", "label": "Analytics", "value": "Analytics" },
{ "key": "strategy", "option": "strategy", "label": "Strategy", "value": "Strategy" }
];

export const operationalManagerTools = [];
export const CFOTools = [];
export const boardOfDirectorTools = [];