
import React, { useState } from 'react';
import moment from 'moment';
import Slider from 'react-slick';
import classNames from 'classnames';

import routeConfiguration from '../../routing/routeConfiguration';
import { priceData } from './ListingPage.shared';
import { ensureUser, stringFromLength } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import { types as sdkTypes } from '../../util/sdkLoader';

import { Avatar, Button, IconClose, IconDeetsLinkedin, IconProfileCard, Modal, NamedLink, ResponsiveImage, ReviewRating } from '../../components';
import SectionMapMaybe from './SectionMapMaybe';
import { Link } from '../PageBuilder/Primitives/Link';

import css from './ListingPage.module.css';

const { Money } = sdkTypes;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (<div
    className={className}
    style={{ ...style, display: "block" }}
    onClick={onClick}
  >
    <svg width="21" height="39" viewBox="0 0 21 39" fill="none">
      <path d="M2 36.7707L19 19.8553L2 2.93994" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </div>);
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (<div
    className={className}
    style={{ ...style, display: "block" }}
    onClick={onClick}
  >
    <svg width="21" height="39" viewBox="0 0 21 39" fill="none">
      <path d="M2 36.7707L19 19.8553L2 2.93994" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </div >);
}

const SectionPro = props => {
  const {
    isAuthenticated,
    intl,
    config,
    reviews,
    richTitle,
    share,
    listView,
    currentUser,
    currentListing,
    setShowModal,
    currentTab,
    setCurrentTab,
    filterTabs,
    listingConfig,
    currentUserListings,
    onManageDisableScrolling,
    showPortfolio = false,
    isOwnListing,
    onTotalTokensChange
  } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const portfolioSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: false,

    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const {
    description = '',
    geolocation = null,
    price = null,
    publicData = {},
  } = (currentListing.id && currentListing.attributes) || {};
  const {
    educations = [],
    experiences = [],
    people = [],
    portfolio = [],
    skills = [],
    listingType,
    location: listingLocation,
    q_as,
    addSelfie1,
    addSelfie2,
    uploadVideoInPhotoes,
    skillsInOne
  } = publicData;

  const { address = '' } = listingLocation || {};
  const currentAuthor = (currentListing && currentListing.id && currentListing.author) || {};
  const ensuredAuthor = ensureUser(currentAuthor);

  const companyIndustries = [], companyRoles = [], companySkills = [], companyFavTools = [];
  if (currentUserListings && currentUserListings?.length) {
    currentUserListings.map(cl => {
      if (cl.attributes.publicData.roles && cl.attributes.publicData.roles?.length) {
        companyRoles.push(...cl.attributes.publicData.roles.map(role => role.label));
        companyIndustries.push(...cl.attributes.publicData.roles.map(role => (role.relevantSkill && role?.relevantSkill.length ? role.relevantSkill.map(skill => skill.label) : [])).flat(1));
      }
      if (cl.attributes.publicData.skills && cl.attributes.publicData.skills?.length) {
        companySkills.push(...cl.attributes.publicData.skills.map(role => role.label));
        companyFavTools.push(...cl.attributes.publicData.skills.map(role => (role.favouriteTool && role?.favouriteTool?.length ? role.favouriteTool.map(skill => skill.label) : [])).flat(1));
      }
    });
  }
  let dataUniqueEducation = []
  const uniqueEducation = (educations && educations?.length) ? educations.map((all, i) => (
    <>
      {(all && all.completedDegree && all.completedDegree.label) ? dataUniqueEducation.push(all.completedDegree.label) : null}
    </>
  )) : [];
  dataUniqueEducation = [... new Set(dataUniqueEducation)]



  const filteredExperiences = experiences && experiences?.length
    ? experiences.filter(experience => experience.inputValues.availableHoursPerWeek && experience.inputValues.wantToHire)
    : [];
  const uniqueExperiences = experiences && experiences?.length
    ? [... new Set(experiences.map(({ inputValues: { industry = {} } }) => Array.isArray(industry) ? industry.map(i => i.label) : [industry.label]).flat(1))]
    : [];

  const uniqueFavTools = skills && skills?.length
    ? [... new Set(skills.map(({ inputValues: { favouriteTool = {} } }) => Array.isArray(favouriteTool) ? favouriteTool.map(i => i.label) : [favouriteTool.label]).flat(1))]
    : [];


  const inputValues = filteredExperiences && filteredExperiences?.length
    ? filteredExperiences.map(experience => experience.inputValues)
    : null;
  const {
    workType,
    location: authorLocation,
    mainProfession,
  } = ensuredAuthor.attributes.profile.publicData || {};
  const [showMore, setShowMore] = useState(false);
  const [seeAll, setSeeAll] = useState(false);
  const [openModal, setOpenModal] = useState({ show: false, data: {} });
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [selectedHours, setSelectedHours] = useState(((inputValues && inputValues.length) ? inputValues[0] : {}));
  const { formattedPrice } = priceData((selectedHours && selectedHours.price ? new Money(selectedHours.price, price.currency) : price), config.currency, intl);

  const scrollPage = (e, tab) => {
    e.preventDefault();
    if (e && e.currentTarget && e.currentTarget.href) {
      const element = document.getElementById(e.currentTarget.href.split('#')[1]);
      const offset = 55;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;
      try {
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      } catch (error) {

      }
    }
  }
  let combineSkill = [], combineSpecialist = [], techTools = []
  if (experiences && experiences.length) {
    skillsInOne.map(({ childSkills }) => {
      (childSkills && childSkills.length) ? childSkills.map(({ label, favouriteTools, specialties }
      ) => {
        combineSkill.push(label);
        favouriteTools && favouriteTools.map(({ label }) => {
          techTools.push(label)
        })
        specialties
          && specialties
            .map(({ label }) => {
              combineSpecialist.push(label)
            })
      }) : null
    })
  }

  console.log(combineSkill, 'combineSpecialist', combineSpecialist, 'techTools', techTools);

  return (
    <>
      <div className={css.contentWrapperForProductLayout} id={'divToPrint'}>
        <div className={css.listingGrid}>
          <div className={css.leftGird}>
            {currentUser && currentUser.id && isAuthenticated
              ? <div className={css.rotateHeading}>
                <h1> Hello {currentUser.attributes.profile.firstName}</h1>
              </div>
              : null}
            {uploadVideoInPhotoes
              ? <div className={classNames(!isAuthenticated && css.disableOverlayVideo)}>
                <video controls>
                  <source alt={'video'} src={uploadVideoInPhotoes} type="video/mp4" />
                </video>
              </div>
              : Array.isArray(currentListing.images)
                ? <div className={classNames(css.listingImageBox, !isAuthenticated && css.disableOverlay)}>
                  <ResponsiveImage
                    alt='display image'
                    image={currentListing.images[0]}
                    variants={['default']}
                  />
                </div>
                : null}
            <div className={css.mobileSpaceBox}>
              <div className={css.headingDescription}>
                <div className={css.mobileActionIcon} id={'Bio'}>
                  <div className={css.specialistTitle}>{listingType == 'fractionalPro' ? 'Fractional' : 'Company'} | {listingConfig.companyIndustry.find(ind => ind.option === mainProfession)?.label}</div>
                  <h2 className={css.mainHeadingName}> Meet {showPortfolio ? richTitle : 'Your Fractional PRO'}   </h2>
                  <p className={css.description}>{typeof description == 'string' ? description || description.length <= 277 ? description : stringFromLength(description, 274, true) : null}
                    {description.length > 274
                      ? <span
                        className={css.showmore}
                        onClick={() => setShowMore(showMore ? false : 'description')}
                      >
                        {showMore == 'description' ? ' READ LESS' : ' READ MORE'}
                      </span>
                      : null}
                  </p>
                </div>
              </div>
              {isAuthenticated ? <>
                {reviews && reviews.length && Array.isArray(reviews)
                  ? <div className={css.reviewContainer} id={'Reviews'}>
                    <div className={css.reviewHeader}>
                      <h4>Reviews</h4>
                      {reviews.length > 2
                        ? <div className={css.seeAllLink}>
                          <Button onClick={() => setSeeAll('REVIEWS')}>See All ({reviews.length})</Button>
                        </div>
                        : null}
                    </div>
                    <div className={css.reviewBox}>
                      {reviews.map((review, i) => {
                        const {
                          author: {
                            attributes: {
                              displayName,
                              firstName,
                              lastName
                            }
                          },
                          content } = review || {};

                        return i < 2 && (
                          <div className={css.reviewList} key={review.id.uuid}>
                            <div className={css.reviewLeftSide}>
                              <Avatar user={review.author} />
                            </div>
                            <div className={css.reviewRightSide}>
                              <div className={css.ratingStar}>
                                <ReviewRating rating={review.attributes.rating} />
                              </div>
                              <div className={css.reviewName}>
                                <span className={css.boldName}>{displayName}</span>
                                <span className={css.normalName}>| {`${firstName} ${lastName}`}</span>
                              </div>
                              <div className={css.reviewMessage}>
                                {content}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  : null}
                {experiences && experiences.length && isAuthenticated
                  ? <div className={css.careerHistory} id={'History'}>
                    <div className={css.careeerHeader}>
                      <h4>Currently</h4>
                      {experiences.length > 2
                        ? <div className={css.seeAllLink}>
                          <Button onClick={() => setSeeAll('EXPERIENCE')}>See All ({experiences.length})</Button>
                        </div>
                        : null}
                    </div>
                    <div className={css.careerGrid}>
                      {experiences.map((ex, i) => {
                        const {
                          key,
                          label,
                          highlights = [],
                          inputValues: {
                            industry = {},
                            yearOfExperience = {}
                          }
                        } = ex;

                        return i < 2 && (
                          <div className={css.careerList} key={key}>
                            <div className={css.companyHeld}>{(industry && industry.label) ? industry?.label + ' |' : null}  {label}</div>
                            <div className={css.careerDate}>{yearOfExperience.label}</div>

                          </div>
                        )
                      })}
                    </div>
                  </div>
                  : null}
                {(showPortfolio || isOwnListing) && portfolio && portfolio.length && isAuthenticated
                  ? <div className={css.careerHistory} id={'Portfolio'}>
                    <div className={css.careeerHeader}>
                      <h4>Notable Career History</h4>

                    </div>
                    <div className={css.portfolioSlider}>
                      {portfolio.map((p, i) => {
                        const { title, tellMore, imageURL, tellMore_1, tellMore_2, tellMore_3, tellMore_4, tellMore_5 = '', ...rest } = p;
                        return (
                          <div key={title} className={css.sliderBox}>
                            <div className={css.sliderData}>
                              <div className={css.sliderHeading}>
                                {title}
                              </div>

                              <div className={css.sliderSubHeading}>
                                <div className={css.moreSub}>
                                  {/* <span>{tellMore_1}</span>
                                  {tellMore_2 ? <p onClick={() => { setOpenModal({ show: true, data: { ...p } }) }}>See all</p> : null} */}
                                </div>

                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  : null}

                {educations && educations.length && isAuthenticated
                  ? <div className={css.careerHistory} id={'Education'}>
                    <div className={css.careeerHeader}>
                      <h4>Notable Education</h4>
                      {educations.length > 2
                        ? <div className={css.seeAllLink}>
                          <Button onClick={() => setSeeAll('EDUCATION')}>See All ({educations.length})</Button>
                        </div>
                        : null}
                    </div>
                    <div className={css.careerGrid}>
                      {educations.map((ed, i) => {
                        const { institution, completedDegree = {}, ...rest } = ed;
                        return i < 2 && (
                          <div className={css.careerList} key={institution}>
                            <div className={css.companyHeld}>{institution} | {completedDegree.label}</div>
                            {/* <div className={css.careerDate}>January 2012- June 2015</div> */}
                            {Object.values(rest).map((acheivement, index) => (
                              <div key={'acheivement-' + i + '-' + index} className={css.careerDescription}>
                                {typeof acheivement == 'string'
                                  ? (showMore == ('acheivement-' + i + '-' + index) || acheivement.length <= 141)
                                    ? acheivement
                                    : stringFromLength(acheivement, 138, true)
                                  : null}
                                {acheivement.length > 138
                                  ? <span
                                    className={css.showmore}
                                    onClick={() => setSeeAll('EDUCATION')} //setShowMore(showMore == ('acheivement-' + i + '-' + index) ? false : 'acheivement-' + i + '-' + index)
                                  >
                                    {showMore == ('acheivement-' + i + '-' + index) ? ' READ LESS' : ' READ MORE'}
                                  </span>
                                  : null}
                              </div>))}
                          </div>);
                      })}
                    </div>
                  </div>
                  : null}
              </>
                : null}
            </div>
            {experiences && experiences.length
              ? <div className={css.qualificationBox}>
                <div className={css.qualificationHeading}>Expertise</div>
                <div className={css.qualificationList}>
                  {experiences.map(({ key, label, inputValues }, i) => {
                    // const index = selectedSkills.findIndex(skill => skill.key == key);
                    return (
                      <span
                        key={key + '-' + label + '-' + i}
                        className={classNames(css.qualificationName, companyRoles.includes(label) && css.SelectedPills)}
                        onClick={() => {
                          // if (selectedSkills && selectedSkills.length > 1) {
                          //   if (index > -1) {
                          //     setSelectedSkills([...selectedSkills.filter(skill => skill.key != key)]);
                          //   } else {
                          //     setSelectedSkills([...selectedSkills, { key, ...inputValues }]);
                          //   }
                          // } else if (index == -1) {
                          //   setSelectedSkills([...selectedSkills, { key, ...inputValues }]);
                          // }
                        }}>
                        {companyRoles.includes(label) ? <IconProfileCard type="tick" /> : null}
                        {label}
                      </span>)
                  })}
                </div>
              </div>
              : null}
            {dataUniqueEducation && dataUniqueEducation.length
              ? <div className={css.qualificationBox}>
                <div className={css.qualificationHeading}>Qualifications</div>
                <div className={css.qualificationList}>

                  {(dataUniqueEducation.map((val, i) => {
                    return <span key={i} className={css.qualificationName}>{val}</span>
                  }))}


                </div>
              </div>
              : null}
            {uniqueExperiences && uniqueExperiences.length
              ? <div className={css.qualificationBox}>
                <div className={css.qualificationHeading}>Industries</div>
                <div className={css.qualificationList}>
                  {uniqueExperiences.map((experience, i) => (<span key={i + '-' + experience} className={classNames(css.qualificationName, companyIndustries.includes(experience) && css.SelectedPills)}>
                    {companyIndustries.includes(experience) ? <IconProfileCard type="tick" /> : null}
                    {experience}
                  </span>))}
                </div>
              </div>
              : null}

            {educations && educations.length && isAuthenticated ? null : <div>
              <br />
              <hr />
              <div className={css.notSignedInContent}>
                <h4>Join Forteh to view the whole Profile.</h4>
                <NamedLink name="SignupPage"><Button>Signup</Button></NamedLink>

              </div>
            </div>}
          </div>
          <div className={css.rightGrid}>
            <div className={css.availabilityWeek}>
              <div className={css.availabilityText}>
                {inputValues
                  ? inputValues.length == 1
                    ? <>
                      <span className={css.boldText}>{experiences[0].label}</span>
                    </>
                    : <select onChange={(e) => {
                      const experience = filteredExperiences.find(ex => ex.label == e.target.value);
                      setSelectedHours(experience.inputValues);
                    }}>
                      {filteredExperiences.map((experience, i) => <option key={experience.key + '-' + i} value={experience.label}>
                        {experience.label}
                      </option>)}
                    </select>
                  : null}
              </div>
              <div className={css.actionIcon}>
                {isAuthenticated
                  ? <div onClick={() => setShowModal('Notes')}>
                    <img src={listView} />
                  </div>
                  : null}
                <div onClick={() => setShowModal('Share')}>
                  <img src={share} />
                </div>
              </div>
            </div>
            {/* {experiences && experiences.length && isAuthenticated
              ? <div className={css.careerHistory} id={'History'}>
                <div className={css.careeerHeader}>
                  {experiences.length > 2
                    ? <div className={css.seeAllLink}>
                      <Button onClick={() => setSeeAll('EXPERIENCE')}>See All ({experiences.length})</Button>
                    </div>
                    : null}
                </div>
                <div className={css.careerGrid}>
                  {experiences.map((ex, i) => {
                    const {
                      key,
                      label,
                      highlights = [],
                      inputValues: {
                        industry = {},
                        yearOfExperience = {}
                      }
                    } = ex;

                    return i < 2 && (
                      <div className={css.careerList} key={key}>
                        <div className={css.companyHeld}>  {label}</div>
                      </div>
                    )
                  })}
                </div>
              </div>
              : null} */}
            <div className={css.mapBox}>
              {address && geolocation
                ? <div className={css.mapLocation} id={'mapbox'} >
                  <SectionMapMaybe
                    listingId={currentListing.id}
                    geolocation={geolocation}
                    publicData={publicData}
                    mapsConfig={config.maps}
                  />
                </div>
                : null}
              <div className={css.locationList}>
                {authorLocation && authorLocation.search
                  ? <div className={css.locationName}>
                    <IconProfileCard type="location" />
                    <span>{authorLocation.search}</span>
                  </div>
                  : null}
                {workType
                  ? <div className={css.locationName}>
                    <IconProfileCard type="location" />
                    <span>Location Preference: {workType == 'flexible' ? "Flexible to anything" : workType.charAt(0).toUpperCase() + workType.slice(1)}</span>
                  </div>
                  : null}
                <div className={css.locationName}>
                  <IconProfileCard type="time" />
                  <span>Timezone: {moment().tz(authorLocation && authorLocation.timezone ? authorLocation.timezone : moment.tz.guess()).format('z')}</span>
                </div>
                {/* <div className={css.moreCompanyInfo}>
                  <div>
                    <b>Company Size:</b>
                    <span>34</span>
                  </div>
                  <div>
                    <b>Founded in:</b>
                    <span>Australia</span>
                  </div>
                  <div>
                    <b>Company Industry:</b>
                    <span>Tech General</span>
                  </div>
                  <div>
                    <b>Company Headquarters:</b>
                    <span>Toronto</span>
                  </div>
                </div> */}
              </div>
            </div>
            {(experiences && experiences.length == 1) ? (skillsInOne && skillsInOne.length) ?
              <div className={css.qualificationBox} id={'Skills'}>
                <div className={css.qualificationHeading}>Skills</div>
                <div >
                  <span  >
                    {
                      (combineSkill && combineSkill.length) ? new Set([...combineSkill]).map((label) => {
                        return (
                          <div className={css.qualificationList}>
                            <div className={classNames(css.experiencePopup)}>
                              <div className={css.parentElement}>{label}</div>
                            </div>
                          </div>
                        )
                      }) : null
                    }
                    {(combineSpecialist && combineSpecialist.length) ? <div>
                      <div className={css.qualificationHeading}>Specialties</div>
                      <div className={css.qualificationList}>
                        {new Set([...combineSpecialist])?.map((label) => {
                          return <div className={css.qualificationName}> {(label && typeof label != 'object') ? label : ''}</div>
                        })}
                      </div>
                    </div> : null}
                    {(techTools && techTools.length) ?
                      <div>
                        <div className={css.qualificationHeading}>Tech Tools</div>
                        <div className={css.qualificationList}>

                          {new Set([...techTools])?.map((label) => {
                            return <div className={css.qualificationName}>{(label && typeof label != 'object') ? label : ''}</div>
                          })}
                        </div>
                      </div>
                      : null}
                  </span>
                </div>
              </div>
              : (skills && skills.length)
                ? <div className={css.qualificationBox} id={'Skills'}>
                  <div className={css.qualificationHeading}>Skills</div>ssss
                  <div className={css.qualificationList}>
                    {skills.map(({ key, label, inputValues }) => (
                      <span key={key} className={classNames(css.qualificationName, companySkills.includes(label) && css.SelectedPills, inputValues?.yearOfExperience?.label && css.experiencePopup)}>
                        {companySkills.includes(label) ? <IconProfileCard type="tick" /> : null}
                        {label}
                        {inputValues?.yearOfExperience?.label
                          ? <span className={css.Skillspopup}>
                            <span className={css.SkillspopupBorder}>
                              Since {inputValues?.yearOfExperience?.label}
                            </span>
                          </span>
                          : null}
                      </span>))}
                  </div>
                </div>
                : null :


              (skillsInOne && skillsInOne.length) ?
                <div className={css.qualificationBox} id={'Skills'}>
                  <div >
                    {skillsInOne?.map(({ key, label, inputValues, childSkills }) => {
                      let favouriteToolss = [], specialtiess = [];
                      if (childSkills && childSkills.length) {
                        childSkills?.map(skill => {
                          const { favouriteTools=[], specialties=[] } = skill;
                          if(favouriteTools){
                            favouriteToolss = [...new Set([...favouriteToolss, ...favouriteTools?.map(({ label }) => label)])];
                          }
                          if(specialties){
                          specialtiess = [...new Set([...specialtiess, ...specialties?.map(({ label }) => label)])];
                          }
                        });
                      }
                      return (
                        <span key={key}  >
                          {companySkills.includes(label) ? <IconProfileCard type="tick" /> : null}
                          <div className={css.qualificationHeading} >{(skillsInOne.length == 1) ? null : label}</div>
                          <div className={css.qualificationHeading}>Skills</div>
                          {
                            (childSkills && childSkills.length) ? childSkills?.map(({ favouriteTools, specialties, label, yearExperience }
                            ) => {
                              return <>
                                <div className={css.qualificationList}>
                                  <div className={classNames(css.experiencePopup)}>
                                    <div className={css.parentElement}>{label}</div>
                                    <div className={css.Skillspopup}>
                                      {(yearExperience && yearExperience.label) ? <span className={css.SkillspopupBorder}>
                                        Since {yearExperience.label}
                                      </span> : null}
                                    </div>
                                  </div>

                                </div>
                              </>
                            }
                            ) : null}

                          {specialtiess && specialtiess.length
                            ? <div>
                              <div className={css.qualificationHeading}>Specialties</div>
                              <div className={css.qualificationList}>
                                {specialtiess.map(specialty => <div className={css.qualificationName}>
                                  {specialty}
                                </div>)}
                              </div>
                            </div>
                            : null} 

                           {favouriteToolss && favouriteToolss.length
                            ? <div>
                              <div className={css.qualificationHeading}>Tech Tools</div>
                              <div className={css.qualificationList}>
                                {favouriteToolss.map(favouriteTool => (
                                  <div className={css.qualificationName} key={favouriteTool}>
                                    {favouriteTool}
                                  </div>
                                ))}
                              </div>
                            </div>
                            : null}

                          {/* {


                          (childSkills && childSkills.length) ? childSkills?.map(({ favouriteTools, specialties, label, yearExperience }
                          ) => {
                            return <>
                              dss
                              <div className={css.qualificationList}>

                                <div className={classNames(css.experiencePopup)}>
                                  <div className={css.parentElement}>{label}</div>
                                  <div className={css.Skillspopup}>
                                    {(yearExperience && yearExperience.label) ? <span className={css.SkillspopupBorder}>
                                      Since {yearExperience.label}
                                    </span> : null}
                                  </div>
                                </div>

                              </div>

                              {(specialties && specialties.length) ? <div>
                                <div className={css.qualificationHeading}>Specialties</div>
                                <div className={css.qualificationList}>
                                  {specialties?.map(({ label }) => {
                                    return <div className={css.qualificationName}> {(label && typeof label != 'object') ? label : ''}</div>
                                  })}
                                </div>
                              </div> : null}
                              {(favouriteTools && favouriteTools.length) ?
                                <div>
                                  <div className={css.qualificationHeading}>Tech Tools</div>
                                  <div className={css.qualificationList}>

                                    {favouriteTools?.map(({ label }) => {
                                      return <div className={css.qualificationName}>{(label && typeof label != 'object') ? label : ''}</div>
                                    })}
                                  </div>
                                </div>
                                : null}

                            </>
                          }) : null
                        } */}
                        </span>);
                    })}
                  </div>
                </div>
                : (skills && skills.length)
                  ? <div className={css.qualificationBox} id={'Skills'}>
                    <div className={css.qualificationHeading}>Skills</div>
                    <div className={css.qualificationList}>
                      {skills.map(({ key, label, inputValues }) => (
                        <span key={key} className={classNames(css.qualificationName, companySkills.includes(label) && css.SelectedPills, inputValues?.yearOfExperience?.label && css.experiencePopup)}>
                          {companySkills.includes(label) ? <IconProfileCard type="tick" /> : null}
                          {label}
                          {inputValues?.yearOfExperience?.label
                            ? <span className={css.Skillspopup}>
                              <span className={css.SkillspopupBorder}>
                                Since {inputValues?.yearOfExperience?.label}
                              </span>
                            </span>
                            : null}
                        </span>))}
                    </div>
                  </div> : null}

            {uniqueFavTools && uniqueFavTools.length && uniqueFavTools[0] != undefined
              ? <div className={css.qualificationBox} id={'Tools'}>
                <div className={css.qualificationHeading}>Familiar Tools</div>
                <div className={css.qualificationList}>
                  {uniqueFavTools.map((favTool, i) => (
                    <span key={favTool + '-' + i} className={classNames(css.qualificationName, companyFavTools.includes(favTool) && css.SelectedPills)}>
                      {companyFavTools.includes(favTool) ? <IconProfileCard type="tick" /> : null}
                      {favTool}
                    </span>
                  ))}
                </div>
              </div>
              : null}

            {(showPortfolio || isOwnListing) && portfolio && portfolio.length && isAuthenticated
              ? <div className={css.portfolioBox} id={'Portfolio'}>
                <div className={css.portfolioHeader}>
                  <h4>Portfolio ({portfolio.length})</h4>
                  {portfolio.length > 2
                    ? <div className={css.seeAllLink}>
                      <Button onClick={() => setSeeAll('PORTFOLIO')}>See All ({portfolio.length})</Button>
                    </div>
                    : null}
                </div>
                <div className={css.portfolioSlider}>
                  <Slider {...settings}>
                    {portfolio.map((p, i) => {
                      const { title, tellMore_1, tellMore_2, tellMore_3, tellMore_4, tellMore_5 = '', e, imageURL, ...rest } = p;
                      return (i < 2 &&
                        <div key={title} className={css.sliderBox}>
                          <div className={css.sliderImage}>
                            {Object.values(rest).length
                              ? <Slider {...portfolioSettings}>
                                {Object.values(rest).map((rost, i) => {
                                  if (rost && typeof rost == 'string') {
                                    return (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(rost)
                                      ? <img src={rost} key={i} onClick={() => setIsFullscreen(rost)} />
                                      : (/\.(mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg)$/i).test(rost)
                                        ? <video controls key={i} onClick={() => setIsFullscreen(rost)} >
                                          <source alt={'video'} src={rost} type="video/mp4" />
                                        </video>
                                        : (/\.(pdf|doc?x)$/i).test(rost)
                                          ? <div className={css.uploadDocument} key={i} onClick={() => setIsFullscreen(rost.replace(/\.(pdf|doc?x)$/i, '.png'))} >
                                            <img src={rost.replace(/\.(pdf|doc?x)$/i, '.png')} height={'250px'} width={'100%'} />
                                          </div>
                                          : null
                                  }
                                  return null;
                                })}
                              </Slider>
                              : null}
                          </div>
                          <div className={css.sliderData}>
                            <div className={css.sliderHeading}>
                              {title}
                            </div>
                            <div className={css.sliderSubHeading}>
                              <div className={css.moreSub}><span>{tellMore_1}</span>
                                {tellMore_2 ? <p onClick={() => { setOpenModal({ show: true, data: { ...p } }) }}>See all</p> : null}</div>

                            </div>
                            {imageURL
                              ? <a href={imageURL} target='_blank'>
                                {imageURL}
                              </a>
                              : null}
                            {/* <div className={css.sliderSubHeading}>
                              {tellMore}
                            </div> */}
                          </div>
                        </div>
                      )
                    })}
                  </Slider>
                </div>
              </div>
              : null}
            {(people && people.length && (showPortfolio || isOwnListing))
              ? <div className={classNames(css.qaBox, css.qaBoxSmallPadding)}>
                <div className={css.qaHeading}>My Recommendations</div>
                <div className={css.qaBoxWrapper}>
                  {people.map((p, i) => (
                    <div className={css.qaBoxList} key={p.name + '-' + i}>
                      <div className={css.iconWrapper}>
                        <a href={p.linkedinURL} target='_blank'>
                          <IconDeetsLinkedin />
                        </a>
                      </div>
                      <div className={css.deetsFrom}><span className={css.ProDeetsLabel}>From:</span> <span>{p.name}</span></div>

                      <div className={css.deetsHeading}>What they said:</div>
                      <div className={css.deetsDescription}>
                        {typeof p.deets == 'string' ? showMore == ('deets-' + i) || p.deets.length <= 141 ? p.deets : stringFromLength(p.deets, 138, true) : null}
                        {p?.deets?.length > 138
                          ? <span
                            className={css.showmore}
                            onClick={() => setSeeAll('PEOPLE')}
                          >
                            {showMore == ('deets-' + i) ? ' READ LESS' : ' READ MORE'}
                          </span>
                          : null}
                      </div>
                    </div>))}
                </div>
              </div>
              : null}
          </div>
        </div>
      </div>
      <div className={css.listingFixBottom}>
        <div className={css.listingBottom}>
          <div className={css.leftLinks}>
            {filterTabs && filterTabs.length
              ? filterTabs.map((tab, i) => (
                <Link
                  key={tab + i}
                  onClick={(e) => {
                    setCurrentTab(tab);
                    scrollPage(e, tab);
                  }}
                  to={`#${tab}`}
                  href={`#${tab}`}
                  className={currentTab == tab ? css.sectionTab : ''}
                >{tab}</Link>
              ))
              : null}
          </div>
          <div className={css.rightLinks}>
            <div className={css.availableData}>
              <div className={css.negotiableText}>
                <span>{formattedPrice}</span>/hour (negotiable)
              </div>
              {selectedHours && selectedHours.availableHoursPerWeek
                ? <div className={css.availableText}>
                  Available for {selectedHours.availableHoursPerWeek.label} per week
                </div>
                : null}
            </div>
            {!isAuthenticated || (currentUser && currentUser.id && currentUser.attributes.profile.publicData.accountType == 'company')
              ? <div className={css.requestButton}>
                <Button onClick={() => isAuthenticated ? setShowModal('Request') : history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}))}>{isAuthenticated ? onTotalTokensChange ? `Request Connection - ${onTotalTokensChange} Tokens  ` : 'Request Connection' : 'Signup'}</Button>
              </div>
              : null}
          </div>
        </div>
      </div>
      {isFullscreen
        ? <div className={css.fullScreenView}>
          <span
            className={css.closeFullScreen}
            onClick={() => setIsFullscreen(false)}
          >
            <IconClose />
          </span>
          <ResponsiveImage
            className={css.responsiveImage}
            alt={'PORTFLIO'}
            url={isFullscreen}
          />
        </div>
        : null}
      <Modal
        id="ListingPage.SeAllModalForPort"
        isOpen={openModal?.show}
        onClose={() => setOpenModal({ show: false, data: {} })}
        onManageDisableScrolling={onManageDisableScrolling}
        className={css.modalDetails}
      ><div className={classNames(css.leftGird, css.modalSlider, css.rightGrid)}><div className={css.portfolioBox}>
        <div className={css.portfolioSlider}>
          <div className={css.portfolioHeader}>
            {/* <h4>Portfolio </h4> */}
          </div>

          <div key={openModal?.data.title} className={css.sliderBox}>

            <div className={css.sliderData}>
              <div className={css.sliderHeading}>
                {openModal.data.title}
              </div>

              <ul className={css.sliderSubHeading}>
                <li>
                  {openModal?.data?.tellMore_1}
                </li>
                {openModal?.data?.tellMore_2 ? <li>
                  {openModal?.data?.tellMore_2}
                </li> : null}
                {openModal?.data?.tellMore_3 ? <li>
                  {openModal?.data?.tellMore_3}
                </li> : null}
                {openModal?.data?.tellMore_4 ? <li>
                  {openModal?.data?.tellMore_4}
                </li> : null}
                {openModal?.data?.tellMore_5 ? <li>
                  {openModal?.data?.tellMore_5}
                </li> : null}


              </ul>
            </div>
          </div>
        </div>
      </div>  </div></Modal>

      <Modal
        id="ListingPage.SeAllModal"
        isOpen={!!seeAll}
        onClose={() => setSeeAll(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        className={css.modalDetails}
      >
        <div className={classNames(css.leftGird, css.modalSlider, css.rightGrid)}>
          {seeAll == "PORTFOLIO"
            ? <div className={css.portfolioBox}>
              <div className={css.portfolioSlider}>
                <div className={css.portfolioHeader}>
                  <h4>Portfolio ({portfolio?.length})</h4>
                </div>
                <Slider {...settings} dots={false}>
                  {portfolio.map(p => {
                    const { title, tellMore, imageURL, ...rest } = p;
                    return (
                      <div key={title} className={css.sliderBox}>
                        <div className={css.sliderImage}>
                          {Object.values(rest)?.length
                            ? <Slider {...portfolioSettings}>
                              {Object.values(rest).map((rost, i) => {
                                const rostSplit = rost.split('/');
                                return (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(rost)
                                  ? <img src={rost} key={i} />
                                  : (/\.(mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg)$/i).test(rost)
                                    ? <video controls key={i} >
                                      <source alt={'video'} src={rost} type="video/mp4" />
                                    </video>
                                    : (/\.(pdf|doc?x)$/i).test(rost)
                                      ? <div className={css.uploadDocument} key={i} >
                                        <img src={rost.replace(/\.(pdf|doc?x)$/i, '.png')} height={'250px'} width={'100%'} />
                                      </div>
                                      : null
                              })}
                            </Slider>
                            : null}
                        </div>
                        <div className={css.sliderData}>
                          <div className={css.sliderHeading}>
                            {title}
                          </div>
                          {imageURL
                            ? <a href={imageURL} target='_blank'>
                              {imageURL}
                            </a>
                            : null}
                          <div className={css.sliderSubHeading}>
                            {tellMore}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </Slider>
              </div>
            </div>
            : seeAll == "EDUCATION"
              ? <div className={css.careerHistory} id={'Education'}>
                <div className={css.careeerHeader}>
                  <h4>Notable Education</h4>

                </div>
                <div className={css.careerGrid}>
                  {educations.map(ed => {
                    const { institution, completedDegree = {}, ...rest } = ed;
                    return (institution && completedDegree && completedDegree.label) ?
                      <div className={css.careerList} key={institution}>
                        <div className={css.companyHeld}>{institution} | {completedDegree.label}</div>
                        {/* {Object.values(rest).map((acheivement, i) => (
                          <div key={i} className={css.careerDescription}>
                            {acheivement}
                          </div>))} */}
                      </div> : null
                  })}
                </div>
              </div>
              : seeAll == "EXPERIENCE"
                ? <div className={css.careerHistory} id={'History'}>
                  <div className={css.careeerHeader}>
                    <h4>Notable Career History</h4>

                  </div>
                  <div className={css.careerGrid}>
                    {experiences.map(ex => {
                      const {
                        key,
                        label,
                        highlights = [],
                        inputValues: {
                          industry = {},
                          yearOfExperience = {}
                        }
                      } = ex;

                      return (
                        <div className={css.careerList} key={key}>
                          <div className={css.companyHeld}>{industry.label} | {label}</div>
                          <div className={css.careerDate}>{yearOfExperience.label}</div>
                          {highlights.map((hi, i) => (
                            <div key={key + i} className={css.careerDescription}>
                              {hi}
                            </div>
                          ))}
                        </div>
                      )
                    })}
                  </div>
                </div>
                : seeAll == "REVIEWS"
                  ? <div className={css.reviewContainer} id={'Reviews'}>
                    <div className={css.reviewHeader}>
                      <h4>Reviews</h4>

                    </div>
                    <div className={css.reviewBox}>
                      {reviews.map((review) => {
                        const {
                          author: {
                            attributes: {
                              displayName,
                              firstName,
                              lastName
                            }
                          },
                          content } = review || {};

                        return (
                          <div className={css.reviewList} key={review.id.uuid}>
                            <div className={css.reviewLeftSide}>
                              <Avatar user={review.author} />
                            </div>
                            <div className={css.reviewRightSide}>
                              <div className={css.ratingStar}>
                                <ReviewRating rating={review.attributes.rating} />
                              </div>
                              <div className={css.reviewName}>
                                <span className={css.boldName}>{displayName}</span>
                                <span className={css.normalName}>| {`${firstName} ${lastName}`}</span>
                              </div>
                              <div className={css.reviewMessage}>
                                {content}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  : seeAll == "PEOPLE"
                    ? <div className={classNames(css.qaBox, css.qaBoxModal)}>
                      <div className={css.qaHeading}>My Deets</div>
                      <div className={css.qaBoxWrapper}>
                        {people.map((p, i) => (
                          <div className={css.qaBoxList}>
                            <div className={css.iconWrapper}>
                              <a href={p.linkedinURL} target='_blank'>
                                <IconDeetsLinkedin />
                              </a>
                            </div>
                            <div className={css.deetsFrom}><span className={css.ProDeetsLabel}>From:</span> {p.name} </div>

                            <div className={css.deetsHeading}>What They Said:</div>

                            <div className={css.deetsDescription}>
                              {p.deets}
                            </div>
                          </div>))}
                      </div>
                    </div>
                    : null}
        </div>
      </Modal>
    </>
  );
};

export default SectionPro;
