import { compose } from 'redux';
import { connect } from 'react-redux';
import { shape, func } from 'prop-types';
import React, { Component } from 'react';
import { propTypes } from '../../util/types';
import css from './AdminPanelPage.module.css';
import { withRouter } from 'react-router-dom';
import defaultConfig from '../../config/configDefault';
import { approvedByAdmin } from './AdminPanelPage.duck';
import { createSlug, parse } from '../../util/urlHelpers';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { createResourceLocatorString } from '../../util/routes';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import routeConfiguration from '../../routing/routeConfiguration';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  Page,
  LayoutSingleColumn,
  NamedLink,
  Skeleton,
  NamedRedirect,
  IconClose,
  Button,
  IconSearch,
  PaginationLinks,
} from '../../components';

export class AdminPanelPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      searchValue: '',
    };
  }
  componentWillUnmount() {
    this.setState = () => { };
  }

  render() {
    const {
      intl,
      params,
      history,
      location,
      pagination,
      listings,
      transactions,
      fetchInProgress,
      listingUpdatedId,
      updateInProgress,
      scrollingDisabled,
      onApprovedByAdmin,
      isAuthenticated,
      currentUser
    } = this.props;

    const title = intl.formatMessage({ id: 'AdminPanelPage.title' });
    const filteredTransaction =
      transactions && transactions.length && params.id
        ? transactions.filter(tx => tx._id == params.id)
        : [];

    const searchApi = (isEmpty = '') => {
      let item = {};
      if (isEmpty == 'empty') {
        this.setState({ searchValue: '' });
      } else {
        if (this.state.searchValue.split('-').length > 2) {
          item = { ids: [this.state.searchValue] };
        } else {
          item = { keywords: this.state.searchValue };
        }
      }

      return history.push(createResourceLocatorString('AdminPanelPage', routeConfiguration(), {}, item));
    }

    if (!isAuthenticated || (currentUser && currentUser.id && currentUser.id.uuid && !defaultConfig.adminUserId.includes(currentUser.id.uuid) )) {
      // return <NamedRedirect name="LandingPage" />
    }

    const pageSearchParams = parse(location.search);
    const pagingLinks =
      pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="AdminPanelPage"
          pagePathParams={params}
          pagination={pagination}
          pageSearchParams={pageSearchParams}
        />
      ) : null;

    return ( 
      <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn
        topbar={ <TopbarContainer /> }footer={<FooterContainer />}>
            <div className={css.content}>
              <div className={css.headingContainer}>
                <h1 className={css.heading}>
                  <NamedLink name="AdminPanelPage" className={css.logoLink}>
                    <FormattedMessage id="AdminPanelPage.heading" />
                  </NamedLink>
                </h1>
                {fetchInProgress
                  ? (
                    <div className={css.skeleton}>
                      {Array(10).fill('').map(()=> <Skeleton />)}
                      
                    </div>
                  )
                  : params.id && filteredTransaction && filteredTransaction.length
                    ? null
                    : (
                      <div>
                        <div className={css.searchBar}>
                          <div className={css.searchInput}>
                            <input
                              id={'input-id'}
                              type={'search'}
                              placeholder="Search..."
                              value={this.state.searchValue}
                              onKeyPress={e => (e.key == 'Enter') && searchApi()}
                              onChange={(e) => this.setState({ searchValue: e.currentTarget.value })}
                            />
                            <span
                              className={css.clearSearch}
                              onClick={() => searchApi('empty')}
                            >
                              <IconClose />
                            </span>
                          </div>
                          <span className={css.searchButton} onClick={() => searchApi()}>Search</span>
                        </div>
                        <div className={css.mainWrapper}>
                          <table className={css.transactionsWrapper}>
                            <thead>
                              <tr className={css.headingTitle}>
                                <th className={css.idBoxHeading}>
                                  <FormattedMessage id="AdminPanelPage.uuid" />
                                </th>
                                <th className={css.titleBox}>
                                  <FormattedMessage id="AdminPanelPage.titles" />
                                </th>
                                <th className={css.sameBoxWidth}>
                                  <FormattedMessage id="AdminPanelPage.listingType" />
                                </th>
                                <th className={css.sameBoxWidth}>
                                  <FormattedMessage id="AdminPanelPage.listingStatus" />
                                </th>
                                <th className={css.sameBoxWidth}>
                                  <FormattedMessage id="AdminPanelPage.ViewlistingPage" />
                                </th>
                                <th className={css.sameBoxWidth}>
                                  <FormattedMessage id="AdminPanelPage.ChangeStatus" />
                                </th>
                              </tr>
                            </thead>


                            {listings && listings.length
                              ? (
                                <tbody>
                                  {listings.map(({ attributes: {
                                    title,
                                    publicData: {
                                      listingType,
                                    }
                                  }, id }, index) => (
                                    <tr key={id + '-' + index}>
                                      <td>{id.uuid}</td>
                                      <td>{title}</td> 
                                      <td>{listingType}</td> 
                                      <td>Pending</td>
                                      <td className={css.viewButton}>
                                        <NamedLink
                                          name={"ListingPage"}
                                          params={{
                                            id: id.uuid,
                                            slug: createSlug(title)
                                          }}
                                        >
                                          <span>
                                            {/* <ListingIconCard type="eye" />*/}
                                             eye
                                          </span>
                                        </NamedLink>
                                      </td> 
                                      { <td>
                                        <Button
                                          className={css.approvalButton}
                                          inProgress={listingUpdatedId == id.uuid && updateInProgress}
                                          onClick={() => {
                                            onApprovedByAdmin({ id: id.uuid, ...pageSearchParams }, listings.length > 1)
                                              .then(() => {
                                                if (listings.length == 1) {
                                                  setTimeout(() => {
                                                    return history.push(createResourceLocatorString('AdminPanelPage', routeConfiguration(), {}, { page: pageSearchParams.page > 1 ? --pageSearchParams.page : 1 }));
                                                  }, 1000);
                                                }
                                              })
                                          }}
                                        >
                                          Approve
                                        </Button>
                                      </td> }
                                    </tr>
                                  ))}
                                </tbody>
                              )
                              : (
                                <tfoot>
                                  <tr>
                                    <th colSpan={12}>No record found!!</th>
                                  </tr>
                                </tfoot>
                              )}
                          </table>
                          {pagingLinks}
                        </div>
                      </div>
                    )}
              </div>
            </div>
            </LayoutSingleColumn>
      </Page >
    );
  }
}

AdminPanelPageComponent.defaultProps = {
  currentUser: null,
};

AdminPanelPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const { currentUser } = state.user;
  const { getListingData, pagination } = state.AdminPanelPage;
  const {
    fetchInProgress,
    updateInProgress,
    listingUpdatedId,
    updateTransactionError,
  } = state.AdminPanelPage;
  const pageListings = getListingsById(state, getListingData);
  return {
    currentUser,
    getListingData,
    listings: pageListings,
    scrollingDisabled: isScrollingDisabled(state),
    pagination,
    isAuthenticated,
    fetchInProgress,
    updateInProgress,
    listingUpdatedId,
    updateTransactionError,
  };
};

const mapDispatchToProps = dispatch => ({
  onApprovedByAdmin: (data, pageNo) => dispatch(approvedByAdmin(data, pageNo)),
});

const AdminPanelPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl,

)(AdminPanelPageComponent);

export default AdminPanelPage;